import { useTheme } from '@mui/material';
import { useGetDisplayInfo } from 'hooks/useDisplayInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Events } from 'react-scroll';
import {
  makeSelectMenuGroups,
  selectMenuCatalogs,
} from 'redux/catalogs/catalogs.selectors';
import { selectLogo } from 'redux/config/config.selectors';
import useResizeObserver from 'use-resize-observer/polyfilled';

export const makeGroupTab = (group, groupDisplayInfo) => ({
  imageSrc: groupDisplayInfo.smallImage?.[0],
  label: groupDisplayInfo.title,
  groupId: group.groupId,
});

export const useGroupTabs = () => {
  const catalogsArray = useSelector(selectMenuCatalogs);
  const catalog = useMemo(() => catalogsArray[0], [catalogsArray]);
  const groups = useSelector(
    // eslint-disable-next-line
    useCallback(makeSelectMenuGroups(catalog), [catalog]),
  );
  const getDisplayInfo = useGetDisplayInfo();
  const [tabValue, setTabValue] = useState(groups?.[0]?.groupId);
  const fallbackImage = useSelector(selectLogo);
  const theme = useTheme();
  const [spy, setSpy] = useState(true);

  useEffect(() => {
    if (groups) setTabValue(groups?.[0]?.groupId);
  }, [groups]);

  const navOffset = useMemo(
    () => parseInt(theme.navHeight.replace('px', '')),
    [theme.navHeight],
  );

  const handleTabChange = useCallback((_event, groupId) => {
    setTabValue(groupId);
  }, []);

  const tabs = groups.map(group =>
    makeGroupTab(group, getDisplayInfo(group.displayInfo)),
  );

  const groupsHaveImages = useMemo(
    () => tabs.some(tab => !!tab.imageSrc),
    [tabs],
  );
  const { ref: tabListRef, height: tabsHeight = 1 } = useResizeObserver();

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {
      setSpy(false);
    });

    Events.scrollEvent.register('end', () => {
      setSpy(true);
      if (window.scrollY === 0) {
        setTabValue(tabs?.[0].groupId);
      }
    });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, [tabs]);

  const spyOffset = useMemo(
    () => -navOffset - tabsHeight + 8,
    [navOffset, tabsHeight],
  );

  return {
    tabs,
    groups,
    catalog,
    tabValue,
    fallbackImage,
    spyOffset,
    spy,
    groupsHaveImages,
    tabListRef,
    setTabValue,
    handleTabChange,
  };
};
