import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

export const Buttons = styled(Grid)`
  margin-top: 1.5em;
`;

export const EmptyContent = styled(Typography)`
  text-align: center;
`;
