import { Typography } from '@mui/material';
import styled from 'styled-components';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export const DeliveryRadiusError = styled(Typography)`
  flex: 1;
  margin-left: 10px;
`;

export const ErrorIcon = styled(ReportProblemOutlinedIcon)`
  align-items: center;
  margin: auto;
`;
