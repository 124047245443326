import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import * as S from './drawer-cart.styles';
import Drawer from '../drawer/drawer.component';
import DrawerCartItem from './drawer-cart-item/drawer-cart-item.component';
import {
  selectDisplayOrderSubTotal,
  selectIsDrawerOpen,
  selectItemsCount,
  selectOrderItems,
  selectCharges,
  selectTaxes,
  selectTip,
  selectSlug,
  selectDisplayTotalWithoutGratuityWithTip,
} from '../../redux/cart/cart.selectors';
import { closeCartDrawer, openCartDrawer } from '../../redux/cart/cart.actions';
import Typography from '@mui/material/Typography';
import OrderDiscounts from '../order-discounts/order-discounts.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';
import { getAnalytics } from 'util/analytics';
import { Box, Grid } from '@mui/material';
import { useValideMinMax } from 'hooks/useMinMaxValidation';
import HiddenTypography from 'components/hidden-typography/hidden-typography.component';

const DrawerCart = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const items = useSelector(selectOrderItems);
  const totalItemsCount = useSelector(selectItemsCount);
  const displaySubtotal = useSelector(selectDisplayOrderSubTotal);
  const displayTotalWithTip = useSelector(
    selectDisplayTotalWithoutGratuityWithTip,
  );
  const charges = useSelector(selectCharges);
  const tip = parseFloat(useSelector(selectTip));
  const taxes = useSelector(selectTaxes);
  const storeSlug = useSelector(selectSlug);

  const isMenu = useRouteMatch(['/locations/:storeTitle']);

  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const { isMinMaxError } = useValideMinMax();

  const handleCheckoutClick = () => {
    if (totalItemsCount > 0) {
      if (isMinMaxError()) return;

      dispatch(closeCartDrawer());
      history.push('/checkout');
    }
  };

  const handleContinueOrderClick = () => {
    dispatch(closeCartDrawer());
    history.push(`/locations/${storeSlug}/menu`);
  };

  const handleDrawerToggle = isOpen => () => {
    if (isOpen) {
      dispatch(openCartDrawer());
      getAnalytics().trackCartViewed(items);
    } else {
      dispatch(closeCartDrawer());
    }
  };

  return (
    <>
      <Drawer
        toggleText={t('drawer.cart.toggle.label')}
        isLight
        handleToggleDrawer={handleDrawerToggle}
        isDrawerOpen={isDrawerOpen}
        togglerButtonProps={{
          'data-testid': 'DrawerCart.Toggle',
          'aria-label': t('drawer.cart.toggle.ariaLabel', {
            count: totalItemsCount,
          }),
        }}
      >
        <S.Container
          className={
            !totalItemsCount || totalItemsCount === 0 ? 'emptyCart' : null
          }
          aria-label="You are currently located in your cart"
          data-testid="DrawerCart.Continer"
        >
          {!totalItemsCount || totalItemsCount === 0 ? (
            <h2>{t('drawer.cart.cartEmpty')}</h2>
          ) : (
            <>
              <S.Title>{t('drawer.cart.myCart')}</S.Title>
              <S.Body>
                {items &&
                  items.map(item => (
                    <DrawerCartItem
                      key={`drawer-${item.orderItemId}`}
                      item={item}
                    />
                  ))}
              </S.Body>
              <S.Footer>
                <OrderDiscounts />
                <S.SubtotalWrapper>
                  <Typography>{t('drawer.cart.subtotal')}</Typography>
                  <Typography>${displaySubtotal}</Typography>
                </S.SubtotalWrapper>
                {charges &&
                  charges.map(
                    ({ chargeDescription, displayChargeApplied }, index) => (
                      <S.SubtotalWrapper
                        key={index}
                        data-testid="DrawerCart.Charge"
                      >
                        <Typography>{chargeDescription}</Typography>
                        <Typography>${displayChargeApplied}</Typography>
                      </S.SubtotalWrapper>
                    ),
                  )}
                {tip ? (
                  <S.SubtotalWrapper data-testid="DrawerCart.Tip">
                    <Typography>{t('drawer.cart.tip')}</Typography>
                    <Typography>${tip.toFixed(2)}</Typography>
                  </S.SubtotalWrapper>
                ) : null}
                {taxes &&
                  taxes.map(
                    ({ taxDescription, displayTaxAmountApplied }, index) => (
                      <S.SubtotalWrapper
                        key={index}
                        data-testid="DrawerCart.Tax"
                      >
                        <Typography>{taxDescription}</Typography>
                        <Typography>${displayTaxAmountApplied}</Typography>
                      </S.SubtotalWrapper>
                    ),
                  )}
                <Box mt={1}>
                  <Grid container>
                    {!isMenu && storeSlug && (
                      <Grid item xs={12}>
                        <IncButtonDark
                          data-testid="DrawerCart.CustomButton.ContinueOrder"
                          onClick={handleContinueOrderClick}
                          fullWidth
                        >
                          {t('drawer.cart.continue')}
                        </IncButtonDark>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <IncButton
                        onClick={handleCheckoutClick}
                        data-testid="DrawerCart.CustomButton.Checkout"
                        fullWidth
                      >
                        ({totalItemsCount}) {t('drawer.cart.checkout')} - $
                        {displayTotalWithTip}
                      </IncButton>
                    </Grid>
                  </Grid>
                </Box>
              </S.Footer>
            </>
          )}
        </S.Container>
      </Drawer>
      <HiddenTypography role="alert">
        {t('drawer.cart.success.ariaAlert', { count: totalItemsCount })}
      </HiddenTypography>
    </>
  );
};

export default DrawerCart;
