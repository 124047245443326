import Grid from '@mui/material/Grid';
import AddressFields from 'components/address/address-fields/address-fields.component';
import { useAddressFields } from 'components/address/address-fields/address-fields.hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import CustomDialog from '../../../components/custom-dialog/custom-dialog.component';
import Form from '../../../components/form/form.component';
import {
  addNewAddress,
  deleteAddress,
  updateAddress,
} from '../../../redux/user/user.actions';
import * as S from './address-dialog.styles';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import IncButton from 'components/inc-button/inc-button.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const AddressDialog = ({ open, close, address, testId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit } = methods;

  const { addressInputProps, getAddress } = useAddressFields(
    'address',
    address,
    methods,
    { aliasEnabled: true },
  );

  const handleClose = () => {
    close();
  };

  const handleDelete = () => {
    dispatch(deleteAddress(address.addressId)).then(res => {
      if (res) {
        toast.success('Address deleted!');
        close();
      }
    });
    close();
  };

  const formSubmit = e => handleSubmit(addressSubmit)(e);

  const addressSubmit = () => {
    const newAddress = getAddress();
    if (!newAddress) return;

    if (!address) {
      dispatch(addNewAddress(newAddress)).then(res => {
        if (res) {
          toast.success('Address added!');
          close();
        }
      });
      return;
    }
    dispatch(
      updateAddress({ ...newAddress, addressId: address.addressId }),
    ).then(res => {
      if (res) {
        toast.success('Address updated!');
        close();
      }
    });
  };

  return (
    <CustomDialog
      open={open}
      close={handleClose}
      title={
        address ? t('dialogs.editAddress.title') : t('dialogs.addAddress.title')
      }
      testId={testId || 'AddressDialog.CustomDialog'}
    >
      <S.Wrapper>
        <FormProvider {...methods}>
          <Form>
            <MandatoryFieldsInstruction />
            <AddressFields {...addressInputProps} />

            <Grid container spacing={2} alignItems="center">
              {address && (
                <Grid item xs>
                  <IncButtonDark
                    onClick={handleDelete}
                    data-testid="AddressDialog.CustomButton.Delete"
                    fullWidth
                  >
                    {t('dialogs.addAddress.deleteAction')}
                  </IncButtonDark>
                </Grid>
              )}

              <Grid item xs>
                <IncButton
                  data-testid="AddressDialog.CustomButton.Save"
                  fullWidth
                  onClick={formSubmit}
                >
                  {t('dialogs.addAddress.saveAction')}
                </IncButton>
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </S.Wrapper>
    </CustomDialog>
  );
};

export default AddressDialog;
