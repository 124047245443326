import { Box } from '@mui/material';
import React from 'react';
import * as S from './address-autocomplete-option.styles';

const AddressAutocompleteOption = ({ option }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      data-testid="AddressAutocompleteOption.Box"
    >
      <Box>
        <S.LocationIcon />
      </Box>
      <Box ml="1rem">
        <Box fontSize="1rem">{option.structured_formatting.main_text}</Box>
        <Box fontSize="0.75rem">
          {option.structured_formatting.secondary_text}
        </Box>
      </Box>
    </Box>
  );
};

export default AddressAutocompleteOption;
