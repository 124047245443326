import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import React from 'react';
import { AttentionSeeker, Fade } from 'react-awesome-reveal';
import * as S from './error-fallback.styles';
import IncButton from 'components/inc-button/inc-button.component';

const ErrorFallback = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <S.Container data-testid="ErrorFallback.Container">
      <AttentionSeeker effect="headShake" duration={500}>
        <MoodBadIcon fontSize="large" />
        <S.Title variant="h4" component="h1">
          {t('commonErrors.ohNo')}
        </S.Title>
      </AttentionSeeker>
      <Fade delay={500} duration={500}>
        <Typography variant="subtitle1" component="p">
          {t('commonErrors.somethingWentWrong')}
        </Typography>
        <p>
          <IncButton
            data-testid="ErrorFallback.CustomButton.TryAgain"
            onClick={resetErrorBoundary}
          >
            {t('commonErrors.tryAgain')}
          </IncButton>
        </p>
      </Fade>
    </S.Container>
  );
};
export default ErrorFallback;
