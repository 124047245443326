import { Box, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DayTimeSelector from '../day-time-selector/day-time-selector.component';
import TodayOrScheduleSelector from '../today-or-schedule-selector/today-or-schedule-selector.component';
import { useFieldNames } from '../useFieldNames.hooks';
import { useScheduleOrder } from './schedule-order.hooks';
import FutureOrderBanner from '../future-order-banner/future-order-banner.component';

const ScheduleOrder = props => {
  const { orderTypeLabel, isSevenPlusDayOrderingEnabled, ...dayTimeProps } =
    useScheduleOrder(props);
  const { fieldNames } = useFieldNames();
  const { t } = useTranslation();

  return (
    <Box position="relative" minHeight="200px">
      <FutureOrderBanner
        isTodayOff={dayTimeProps.isTodayOff}
        storeId={props.store.storeId}
      />
      <InputLabel htmlFor={fieldNames.day}>
        <Box pb="1em">
          {t('locations.card.selectDayTime', {
            orderTypeLabel,
          })}
        </Box>

        {isSevenPlusDayOrderingEnabled ? (
          <TodayOrScheduleSelector {...dayTimeProps} {...props} />
        ) : (
          <Box maxHeight="450px" overflow="hidden">
            <DayTimeSelector {...dayTimeProps} {...props} />
          </Box>
        )}
      </InputLabel>
    </Box>
  );
};

export default ScheduleOrder;
