import { Box } from '@mui/material';
import { Alert } from '@mui/lab';

const DelayedMessage = ({ delayedMessage }) => {
  if (!delayedMessage) return null;

  return (
    <Box mb={1}>
      <Alert severity="info" data-testid="DayTimeSelector.DelayedMessage">
        {delayedMessage}
      </Alert>
    </Box>
  );
};

export default DelayedMessage;
