import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  InfoTitle,
  InfoBox,
  InfoText,
  Edit,
} from 'components/card-item/card-item.styles';
import { getFormattedAddress } from 'utils';

const AddressList = ({ address, handleCardClicked }) => {
  const { t } = useTranslation();
  const { addressId, addressAlias } = address;

  const handleAddressClicked = () => {
    handleCardClicked(address);
  };

  return (
    <Grid
      item
      key={addressId}
      xs={12}
      sm={6}
      md={4}
      data-testid="AccountSettings.Grid.AddressCard"
    >
      <InfoBox onClick={handleAddressClicked}>
        <InfoTitle>
          <span>{addressAlias}</span>
          <Edit data-testid="AccountSettings.AddressCard.Edit">
            {t('accountSettings.editAction')}
            <EditIcon />
          </Edit>
        </InfoTitle>
        <InfoText>{getFormattedAddress(address)}</InfoText>
      </InfoBox>
    </Grid>
  );
};

export default AddressList;
