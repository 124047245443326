import { Box, Typography } from '@mui/material';
import CustomPagination from 'components/custom-pagination/custom-pagination.component.jsx';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLoyaltyAccountsHistory } from 'redux/user/user.actions.js';
import {
  selectLoyaltyHistories,
  selectLoyaltyHistoryPaging,
} from '../../../../redux/user/user.selectors.js';
import * as S from './account-loyalty-activity.styles';

const AccountLoyaltyActivity = () => {
  const { t } = useTranslation();
  const loyaltyHistories = useSelector(selectLoyaltyHistories);
  const paging = useSelector(selectLoyaltyHistoryPaging);
  const dispatch = useDispatch();

  const fetchLoyaltyAccountHistories = useCallback(
    page => {
      dispatch(getLoyaltyAccountsHistory(page));
    },
    [dispatch],
  );

  useEffect(() => {
    fetchLoyaltyAccountHistories();
  }, [fetchLoyaltyAccountHistories]);

  const handlePaginationChange = (_event, page) => {
    fetchLoyaltyAccountHistories(page - 1);
  };

  return (
    <>
      <S.Subtitle>{t('accountLoyalty.loyaltyHistorySubTitle')}</S.Subtitle>
      <Box data-testId="AccountLoyaltyActivity.Box.LoyaltyHistories">
        {loyaltyHistories.length ? (
          loyaltyHistories.map(
            ({ description, originalPoints, transactionDate }, index) => (
              <S.ActivityCard
                data-testid="AccountLoyaltyActivity.ActivityItem"
                key={index}
              >
                <S.PointsContent>
                  <S.TitleContainer>
                    <S.Title variant="body2">{description}</S.Title>
                    <S.Date>
                      {DateTime.fromISO(transactionDate, {
                        zone: 'utc',
                      }).toLocaleString(DateTime.DATE_SHORT)}
                    </S.Date>
                  </S.TitleContainer>
                  <S.Points>{originalPoints}</S.Points>
                </S.PointsContent>
              </S.ActivityCard>
            ),
          )
        ) : (
          <S.EmptyActivity>
            <Typography>{t('accountLoyalty.emptyMessage')}</Typography>
          </S.EmptyActivity>
        )}
      </Box>
      <S.Pagination data-testid="AccountLoyalty.Box.CustomPagination">
        {paging.totalPages > 1 && (
          <CustomPagination
            defaultPage={paging.currentPage + 1}
            count={paging.totalPages}
            onChange={handlePaginationChange}
            data-testid="AccountLoyalty.CustomPagination"
          />
        )}
      </S.Pagination>
    </>
  );
};

export default AccountLoyaltyActivity;
