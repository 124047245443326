import React, { useState } from 'react';
import * as S from './scroll-to-top.styles';
import { animateScroll as scroll } from 'react-scroll';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from 'react-i18next';
import IncButton from 'components/inc-button/inc-button.component';

const ScrollToTop = () => {
  const { t } = useTranslation();
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleVisibilityChange = isVisible => {
    setShowScrollToTop(isVisible);
  };

  return (
    <S.BottomContainer>
      <S.FadeRevealTrigger onVisibilityChange={handleVisibilityChange}>
        <div></div>
      </S.FadeRevealTrigger>
      {showScrollToTop && (
        <S.FadeReveal>
          <IncButton
            onClick={scrollToTop}
            data-testid="ScrollToTop.CustomButton.ScrollToTop"
          >
            <ArrowUpwardIcon />
            {t('catalog.scrollToTop')}
          </IncButton>
        </S.FadeReveal>
      )}
    </S.BottomContainer>
  );
};

export default ScrollToTop;
