import { Input } from '@mui/material';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AddressAutoCompleteInput = ({
  name,
  inputProps,
  InputProps,
  formErrorProps,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <CustomInput>
      <Input
        name={`${name}.input`}
        data-testid="AddressAutoCompleteInput.Input"
        placeholder={t('address.placesAutocomplete.placeholder')}
        error={!!formErrorProps?.errorMessage}
        inputProps={{
          'aria-describedby': 'FormError',
          ...inputProps,
          autoComplete: 'off',
        }}
        {...InputProps}
        {...rest}
      />
      {!!formErrorProps?.errorMessage && <FormError {...formErrorProps} />}
    </CustomInput>
  );
};

export default AddressAutoCompleteInput;
