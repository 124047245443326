import { Button } from '@mui/material';
import styled from 'styled-components';

export const ButtonsGrid = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  gap: ${({ theme }) => theme.spacing(0.5)};
  margin: 0;
`;

export const OrderTypeButton = styled(Button)`
  box-shadow: none;

  &:hover {
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &:focus-visible {
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;
