import React from 'react';
import { useTheme } from '@mui/material/styles';

import * as S from './edit-icon.styles';

const EditIcon = props => {
  const theme = useTheme();
  return (
    <S.CustomButton {...props}>
      <svg
        width="23px"
        height="23px"
        viewBox="0 0 23 23"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Layout"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="My-Cart"
            transform="translate(-280.000000, -304.000000)"
            stroke={theme.palette.primary.main}
            strokeWidth="2"
          >
            <g id="edit" transform="translate(281.000000, 305.000000)">
              <path
                d="M9,3 L2,3 C0.8954305,3 0,3.8954305 0,5 L0,19 C0,20.1045695 0.8954305,21 2,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,12"
                id="edit-1"
              ></path>
              <path
                d="M16.5,1.5 C17.3284271,0.671572905 18.6715729,0.671572919 19.5,1.50000003 C20.3284271,2.32842714 20.3284271,3.67157287 19.5,4.5 L10,14 L6,15 L7,11 L16.5,1.5 Z"
                id="edit-2"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </S.CustomButton>
  );
};
export default EditIcon;
