import { Box, Grid, Typography } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectValidOfferByPromo } from 'redux/cart/cart.selectors';
import { useOffers } from 'redux/data/offers/offers.hooks';
import { selectIsLoggedIn } from 'redux/user/user.selectors';

const CheckoutPromocodeButtons = ({
  message,
  handleClose,
  setModalOpen,
  applyDiscount,
}) => {
  const { distributedOfferId, offerId, title } = message;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateOfferStatus } = useOffers();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isValidOffer = useSelector(selectValidOfferByPromo(distributedOfferId));

  const handleApplyPromo = async () => {
    const res = await dispatch(
      applyDiscount(distributedOfferId, offerId, undefined, title),
    );
    setModalOpen(false);

    if (res.data) {
      toast.success(t('payment.promoCode.promoApplied'));
      handleClose();
      return;
    }

    if (res === 'Internal Server Error') {
      toast.error(t('errors.offerNotIntegrated'));
      return;
    }

    toast.error(res);
  };

  const handleSaveForLater = async () => {
    await updateOfferStatus(distributedOfferId, 'SAVED');
    setModalOpen(false);
  };

  return isLoggedIn ? (
    <>
      {!isValidOffer && (
        <Box px={2} pt={1} textAlign="center">
          <Typography
            variant="body2"
            data-testid="AccountInboxMessage.CustomDialog.InvalidMessage"
            mt={2}
            ml={1.5}
          >
            {t('payment.promoCode.invalid')}
          </Typography>
        </Box>
      )}
      <Grid item xs>
        <IncButtonDark
          onClick={handleSaveForLater}
          data-testid="AccountInboxMessage.CustomButton.Save"
          fullWidth
        >
          {t('accountInbox.saveForLaterAction')}
        </IncButtonDark>
      </Grid>
      <Grid item xs>
        <IncButton
          onClick={handleApplyPromo}
          data-testid="AccountInboxMessage.CustomButton.UseNow"
          fullWidth
          disabled={!isValidOffer}
        >
          {t('accountInbox.useNowAction')}
        </IncButton>
      </Grid>
    </>
  ) : (
    <Grid item xs>
      <IncButton
        onClick={handleApplyPromo}
        data-testid="AccountInboxMessage.CustomButton.UseNow"
        fullWidth
      >
        {t('accountInbox.useNowAction')}
      </IncButton>
    </Grid>
  );
};

export default CheckoutPromocodeButtons;
