import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-weight: bold;
  font-size: 0.875rem;
`;

export const TimeSlotList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
