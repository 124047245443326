import { InputLabel } from '@mui/material';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TrimEmailField from './trim-email-field/trim-email-field.component';
import { useResetDefaultValue } from './useResetDefaultValue';

const Email = ({ errors, register, defaultValue, disabled }) => {
  const { t } = useTranslation();

  useResetDefaultValue('email', defaultValue);

  return (
    <CustomInput>
      <InputLabel htmlFor="email">{t('register.label.email')}*</InputLabel>
      <TrimEmailField
        fullWidth
        id="email"
        placeholder={t('register.placeholder.email')}
        error={!!errors.email}
        inputProps={{ autoComplete: 'email', 'aria-required': true }}
        {...register('email', {
          required: t('register.errors.requiredField'),
          pattern: {
            value:
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: t('forms.errors.email.invalid'),
          },
        })}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      {errors.email && (
        <FormError
          errorMessage={errors.email.message}
          data-testid="Email.FormError"
        />
      )}
    </CustomInput>
  );
};

export default Email;
