import * as S from './giftcard-details.styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DetailView from './detail-view/detail-view.component';

const GiftCardDetails = ({ giftcard }) => {
  const { t } = useTranslation();
  const { securityCode, cardAccountBalance, cardIdentifier } = giftcard;

  if (securityCode)
    return (
      <>
        <Box textAlign="center">
          <S.Amount
            variant="h3"
            component="p"
            data-testid="GiftcardView.Amount"
          >
            ${cardAccountBalance}
          </S.Amount>
        </Box>
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
        >
          <DetailView
            label={t('eGiftCard.view.cardNumber')}
            detail={cardIdentifier}
            testId="GiftcardView.CardIdentifier"
          />
          <S.VerticalDivider orientation="vertical" flexItem />
          <DetailView
            label={t('eGiftCard.view.securityCode')}
            detail={securityCode}
            testId="GiftcardView.SecurityCode"
            noBreak
          />
        </Box>
      </>
    );

  return (
    <Box
      mb={2}
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
    >
      <S.Amount variant="h3" component="p" data-testid="GiftcardView.Amount">
        ${cardAccountBalance}
      </S.Amount>
      <S.VerticalDivider orientation="vertical" flexItem />
      <DetailView
        label={t('eGiftCard.view.cardNumber')}
        detail={cardIdentifier}
        testId="GiftcardView.CardIdentifier"
      />
    </Box>
  );
};

export default GiftCardDetails;
