import React from 'react';

import * as S from './form-error.styles';

const FormError = ({
  errorMessage,
  'data-testid': dataTestId = 'FormError',
  ...rest
}) => (
  <S.ErrorMessage
    aria-label={errorMessage}
    className="error-display"
    data-testid={dataTestId}
    id="FormError"
    role="alert"
    {...rest}
  >
    {errorMessage}
  </S.ErrorMessage>
);

export default FormError;
