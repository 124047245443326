import * as S from './breadcrumbs.styles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({ children, isdark, maxWidth }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper sx={{ display: { xs: 'none', md: 'block' } }}>
      <Container maxWidth={maxWidth} data-testid="Breadcrumbs.Container">
        <S.Container isdark={isdark} aria-label={t('breadcrumbs.container')}>
          {children}
        </S.Container>
      </Container>
    </S.Wrapper>
  );
};

export default Breadcrumbs;
