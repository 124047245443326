import React from 'react';
import CustomInput from 'components/custom-input/custom-input.component';
import { InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import FormError from 'components/form-error/form-error.component';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectIsAptSuiteMandatory } from 'redux/config/config.selectors';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';

const AptSuite = ({ defaultValue, aptSuiteName }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const { t } = useTranslation();
  const aptSuiteMandatory = useSelector(selectIsAptSuiteMandatory);

  const rules = aptSuiteMandatory
    ? {
        required: t('address.aptSuite.errors.required'),
        validate: value =>
          !value.trim() ? t('address.aptSuite.errors.required') : true,
      }
    : { required: false };

  return (
    <CustomInput>
      <InputLabel htmlFor={aptSuiteName}>
        {`${t('address.aptSuite.label')}${aptSuiteMandatory ? '*' : ''}`}
      </InputLabel>
      <TrimTextField
        id={aptSuiteName}
        {...register(aptSuiteName, rules)}
        fullWidth
        defaultValue={defaultValue}
        inputProps={{
          'data-testid': 'AddressFields.TextField.AptSuite',
          'aria-required': aptSuiteMandatory,
        }}
      />
      <ErrorMessage
        errors={errors}
        name={aptSuiteName}
        render={({ message }) => <FormError errorMessage={message} />}
      />
    </CustomInput>
  );
};

export default AptSuite;
