import { Box } from '@mui/material';
import styled from 'styled-components';

export const NotAvailableBadge = styled.div`
  position: absolute;
  transform: rotate(45deg);
  top: 38px;
  z-index: 1;
  right: -40px;
  padding: 5px 50px;
  background-color: grey;
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
`;
