import { Box } from '@mui/material';
import PaymentErrorBoundary from 'components/payment/payment-error-boundary/payment-error-boundary.component';
import { useFetchAsapDuration } from 'hooks/useFetchAsapDuration';
import { useIsStoreDisabled } from 'hooks/useIsStoreDisabled';
import { makeUseLoading } from 'hooks/useLoader';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { getAnalytics } from 'util/analytics';
import Spinner from '../../components/spinner/spinner.component';
import { getOrderByIdStartAsync } from '../../redux/cart/cart.actions';
import {
  selectIsFetching,
  selectOrder,
  selectOrderId,
  selectSlug,
} from '../../redux/cart/cart.selectors';
import {
  selectIsLocationLoading,
  selectRequestedStoreDITitle,
} from '../../redux/locations/locations.selectors';
import { selectIsLoading } from '../../redux/user/user.selectors';
import CheckoutBreadcrumbs from './checkout-breadcrumbs/checkout-breadcrumbs.component';
import CheckoutPaymentWrapper from './checkout-payment-wrapper/checkout-payment-wrapper.component';
import CheckoutContext from './checkout.context';
import {
  useCheckout,
  useIsAdditionalLinkEnabled,
  useIsMenuBrowsingEnabled,
  useItemCountValidation,
  useMinMaxValidation,
} from './checkout.hooks';
import HiddenTypography from '../../components/hidden-typography/hidden-typography.component';

export const useCheckoutPaymentLoading = makeUseLoading();

export const CheckoutPage = () => {
  useIsStoreDisabled();
  useIsAdditionalLinkEnabled();
  useIsMenuBrowsingEnabled();
  useFetchAsapDuration();

  const dispatch = useDispatch();

  const history = useHistory();
  const storeSlug = useSelector(selectSlug);
  const userLoading = useSelector(selectIsLoading);
  const isFetching = useSelector(selectIsFetching);
  const storeTitle = useSelector(selectRequestedStoreDITitle);
  const locationLoading = useSelector(selectIsLocationLoading);
  const paymentLoading = useCheckoutPaymentLoading(state => state.loading);
  const { t } = useTranslation();
  const title = usePageTitle(`${storeTitle} - ${t('head.title.checkout')}`);

  const [isInitializing, setIsInitializing] = useState(true);
  const isLoading = useMemo(
    () =>
      userLoading ||
      isInitializing ||
      isFetching ||
      locationLoading ||
      paymentLoading,
    [isFetching, isInitializing, locationLoading, paymentLoading, userLoading],
  );
  const orderId = useSelector(selectOrderId);
  const order = useSelector(selectOrder);

  useItemCountValidation();
  useMinMaxValidation(isLoading);

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    getAnalytics().trackCheckoutStarted(order, storeTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getOrderDetails = async () => {
      if (orderId) {
        setIsInitializing(true);
        const response = await dispatch(getOrderByIdStartAsync());

        if (!response) {
          history.push('/');
          return;
        }
        setIsInitializing(false);
      } else {
        setIsInitializing(false);
        history.push('/');
      }
    };
    getOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, dispatch, storeSlug]);

  const checkoutContext = useCheckout();

  return (
    <>
      <Helmet title={title} />
      <HiddenTypography component="h1">{t('checkout.title')}</HiddenTypography>
      <CheckoutBreadcrumbs />
      {isLoading ? (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          zIndex="100"
        >
          <Spinner />
        </Box>
      ) : null}

      {!isInitializing && (
        <Box data-testid="Checkout.CustomContainer">
          <CheckoutContext.Provider value={checkoutContext}>
            <PaymentErrorBoundary name="payment-checkout">
              <CheckoutPaymentWrapper />
            </PaymentErrorBoundary>
          </CheckoutContext.Provider>
        </Box>
      )}
    </>
  );
};

export default CheckoutPage;
