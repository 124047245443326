import { Divider, Typography } from '@mui/material';
import ActionLink from 'components/action-link/action-link.component';
import IncButton from 'components/inc-button/inc-button.component';
import styled from 'styled-components';

export const VerificationMessage = styled(Typography)`
  display: block;
  color: green;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export const DrawerSignupButton = styled(IncButton)`
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid white;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ theme }) =>
    theme.palette.type === 'dark' &&
    `
    border-color: ${theme.palette.text.primary};
    background-color: ${theme.palette.text.primary};
    color: black;
  `}
`;

export const HorizontalLine = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledActionLink = styled(ActionLink)`
  font-size: inherit;
  line-height: inherit;

  && {
    margin: 0px 6px;
  }
`;
