import { Box, Typography } from '@mui/material';
import Spinner from 'components/spinner/spinner.component';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IncentivioPayments from './incentivio/incentivio-payments.component';
import { PaymentGatewayType } from './payment.constants';
import { NewPaymentContext, NewPaymentProvider } from './payment.context';
import { usePaymentGatewayMatches } from './payment.new.hooks';
import SpreedlyPayments from './spreedly/spreeedly-payments.component';
import SquarePayments from './square/square-payments.component';
import { usePaymentConnector } from './payment.hooks';

const Payment = ({ value, children }) => {
  const { t } = useTranslation();
  usePaymentConnector(value.initOrderResponse?.attributes, value.loading);

  if (value.loading) return <Spinner />;

  if (value.disabled)
    return (
      <Box>
        <Typography>{t('payment.disabled')}</Typography>
      </Box>
    );

  return (
    <NewPaymentProvider value={value}>
      <PaymentWrapped>{children}</PaymentWrapped>
    </NewPaymentProvider>
  );
};

const PaymentWrapped = ({ children }) => {
  const newPaymentContext = useContext(NewPaymentContext);
  const paymentGatewayMatches = usePaymentGatewayMatches();
  const { loading } = newPaymentContext;

  if (loading) return <Spinner />;

  return (
    <>
      {paymentGatewayMatches([PaymentGatewayType.SQUARE]) && (
        <SquarePayments>{children}</SquarePayments>
      )}
      {paymentGatewayMatches([PaymentGatewayType.SPREEDLY]) && (
        <SpreedlyPayments>{children}</SpreedlyPayments>
      )}
      {paymentGatewayMatches([PaymentGatewayType.INCENTIVIO_PAYMENTS]) && (
        <IncentivioPayments>{children}</IncentivioPayments>
      )}
      {paymentGatewayMatches([PaymentGatewayType.CLOVER]) && children}
    </>
  );
};

export default Payment;
