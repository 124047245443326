import { Grid } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectReloadGiftCard } from 'redux/config/config.selectors';

const GiftCardViewActions = ({ handleGetGiftCardData, setAddValue }) => {
  const reloadGiftCard = useSelector(selectReloadGiftCard);
  const { t } = useTranslation();

  if (reloadGiftCard)
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <IncButtonDark
            onClick={handleGetGiftCardData}
            data-testid="CardItemGiftcard.CustomButton.RecheckBalance"
            fullWidth
          >
            {t('cardItem.recheckBalanceBtn')}
          </IncButtonDark>
        </Grid>
        <Grid item xs={12} sm={6}>
          <IncButton
            onClick={() => setAddValue(true)}
            data-testid="CardItemGiftcard.CustomButton.AddValue"
            fullWidth
          >
            {t('cardItem.addValueBtn')}
          </IncButton>
        </Grid>
      </Grid>
    );

  return (
    <Grid xs={12}>
      <IncButton
        onClick={handleGetGiftCardData}
        data-testid="CardItemGiftcard.CustomButton.RecheckBalance"
        fullWidth
      >
        {t('cardItem.recheckBalanceBtn')}
      </IncButton>
    </Grid>
  );
};

export default GiftCardViewActions;
