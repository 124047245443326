import React from 'react';
import * as S from './sms-signup.styles';
import SmsSignupForm from './sms-signup-form/sms-signup-form.component';
import { Title } from '../signup/signup-form/signup-form.styles';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

const SmsSignup = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <S.FormContainer>
          <Title variant="h5" component="h1">
            {t('signUp.title')}
          </Title>
          <SmsSignupForm />
        </S.FormContainer>
      </Grid>
    </Grid>
  );
};

export default SmsSignup;
