import React, { useEffect, useRef, useState } from 'react';
import * as S from './menu-card-new-item-content.styles';
import MenuCardNewItemContentPrice from './menu-card-new-item-content-price/menu-card-new-item-content-price.component';

const MenuCardNewItemContent = ({ title, price, description }) => {
  const titleRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(0);

  useEffect(() => {
    setLineHeight(titleRef.current.offsetHeight);
  }, []);

  return (
    <S.TopContainer data-testid="MenuCard.Content.Container">
      <S.InnerContainer>
        <S.Title component="h3" data-testid="MenuCard.Title" ref={titleRef}>
          {title}
        </S.Title>
        <MenuCardNewItemContentPrice price={price} />
      </S.InnerContainer>
      <S.Description
        data-testid="MenuCard.Description"
        $lineHeight={lineHeight}
      >
        {description}
      </S.Description>
    </S.TopContainer>
  );
};

export default MenuCardNewItemContent;
