import { Box, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';

export const height = 80;

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin: 3px 0px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const titleFontSize = 13;
const titleLineHeight = 1.12;
export const Title = styled(Typography)`
  font-size: ${titleFontSize}px;
  line-height: ${titleLineHeight};
  height: calc(${titleFontSize * titleLineHeight}px * 3);
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  font-weight: bold;
`;

export const QuickAddButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
