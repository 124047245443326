import { useMemo, useState } from 'react';
import InfoText from '../info-text/info-text.component';
import {
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormHelperText,
  Zoom,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CustomInput from 'components/custom-input/custom-input.component';
import * as S from './multi-select.styles';

const MultiSelect = ({ info, userDietaryPreferences, name, testIdPrefix }) => {
  const { control } = useFormContext();

  const defaultValue = useMemo(
    () =>
      userDietaryPreferences?.length > 0
        ? userDietaryPreferences
            .split(',')
            .map(
              userOption =>
                info.values.find(
                  infoOption => userOption === infoOption.toLowerCase(),
                ) ?? userOption,
            )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
    MenuListProps: {
      'data-testid': `${testIdPrefix}.Menu`,
      'aria-label': info.infoText,
    },
  };
  const [open, setOpen] = useState(false);
  const [showHelperText, setShowHelperText] = useState(false);

  return (
    <CustomInput>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ...rest } }) => (
          <FormControl fullWidth>
            <InputLabel id={`${testIdPrefix}.Label`}>{info.label}</InputLabel>
            <Select
              {...rest}
              inputProps={{ className: 'multiple' }}
              labelId={`${testIdPrefix}.Label`}
              id={`${testIdPrefix}.Select`}
              multiple
              value={value}
              onChange={e => {
                onChange(
                  e.target.value.filter(
                    optionValue =>
                      !!info.values?.find(
                        infoValue =>
                          infoValue.toLowerCase() === optionValue.toLowerCase(),
                      ),
                  ),
                );
              }}
              input={<Input />}
              renderValue={selected => selected.join(', ')}
              SelectDisplayProps={{
                'data-testid': `${testIdPrefix}.Select`,
              }}
              MenuProps={MenuProps}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => {
                setShowHelperText(value?.length > 0);
                setOpen(false);
              }}
            >
              <InfoText text={info.infoText} />
              {info.values?.map(infoValue => (
                <MenuItem dense key={infoValue} value={infoValue}>
                  <S.MultiSelectCheckbox
                    checked={value.indexOf(infoValue) > -1}
                  />
                  <ListItemText primary={infoValue} />
                </MenuItem>
              ))}
            </Select>
            {showHelperText && (
              <Zoom in={true} timeout={{ enter: 800 }}>
                <FormHelperText data-testid={`${testIdPrefix}.Info`}>
                  {info.infoText}
                </FormHelperText>
              </Zoom>
            )}
          </FormControl>
        )}
      />
    </CustomInput>
  );
};

export default MultiSelect;
