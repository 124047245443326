import React, { useContext, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import AccountLoyaltyPointsBox from '../account-loyalty-points-box/account-loyalty-points-box.component';
import AccountLoyaltyCustomLogo from './account-loyalty-custom-logo/account-loyalty-custom-logo';
import AccountLoyaltyCustomPoints from './acccount-loyalty-custom-points/account-loyalty-custom-points';
import { Box } from '@mui/material';
import AccountLoyaltyActivity from '../account-loyalty-activity/account-loyalty-activity.component';
import Messages from 'pages/account-inbox/messages/messages.component';
import LoyaltySubTitles from '../loyalty-subtitles/loyalty-subtitles.component';
import BackToRewards from '../back-to-rewards/back-to-rewards.component';
import { LoyaltyContext } from 'components/account-loyalty/account-loyalty.context';

const AccountLoyaltyCustom = ({ points, pointName }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const loyaltyContent = useContext(LoyaltyContext);
  return (
    <ReactCardFlip isFlipped={isFlipped}>
      <Box>
        <AccountLoyaltyCustomLogo handleLogoClick={handleCardFlip} />
        <AccountLoyaltyPointsBox points={points} pointName={pointName} />
        <AccountLoyaltyCustomPoints points={points} />
        <LoyaltySubTitles
          handleHistoryClick={handleCardFlip}
          hidden={isFlipped}
        />

        <Messages {...loyaltyContent} />
      </Box>
      <Box>
        <BackToRewards
          handleRewardsClick={handleCardFlip}
          hidden={!isFlipped}
        />
        <AccountLoyaltyCustomLogo handleLogoClick={handleCardFlip} />
        <AccountLoyaltyActivity />
      </Box>
    </ReactCardFlip>
  );
};

export default AccountLoyaltyCustom;
