import { Box, darken } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Image from 'components/Image/image.component';
import styled, { css } from 'styled-components';

export const contentHorizontalSpacing = css`
  padding-left: 2.5em;
  padding-right: 2.5em;
`;

export const ItemDetail = styled(Box)`
  ${contentHorizontalSpacing}
`;

export const Content = styled(DialogContent)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  .input-container {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
`;

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 750px;
  }
`;

export const CartActions = styled(DialogActions)`
  border-top: 1px solid
    ${({ theme }) =>
      darken(
        theme.palette.background.paper,
        theme.palette.type === 'dark' ? 0.35 : 0.1,
      )};
  padding-top: 1em;
  padding-bottom: 1em;
  flex-direction: column;
  ${contentHorizontalSpacing}
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }

  .input-container {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const Close = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  padding: 0;
  top: 1em;
  right: 1em;
  z-index: 3;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DetailImage = styled(Image)`
  max-width: 100%;
  max-height: 100px;
  margin: auto;
  display: block;
  padding: 1em;
`;
