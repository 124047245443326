import { TabList } from '@mui/lab';
import styled from 'styled-components';
import OptionGroupTabPanel from './option-group-tab-panel.component';

export const CustomTabs = styled(TabList)`
  .MuiTabs-indicator {
    height: 4px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiTab-wrapper {
    text-transform: none;
  }

  .Mui-selected {
    .MuiTab-wrapper {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
}
`;

export const CustomTabPanel = styled(OptionGroupTabPanel)`
  padding: 0;
`;
