import { Box } from '@mui/material';
import styled from 'styled-components';

export const OptionDetail = styled(Box)`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin: ${props => `0 0 0 ${props.spacing}px`};
  padding: 0.2em 0;
`;

export const OptionContainer = styled.div`
  margin-bottom: 0.3em;
`;

export const OptionDetailContainer = styled.div`
  margin-bottom: 0.2em;
`;
