import { Backdrop, Paper } from '@mui/material';
import styled from 'styled-components';
import { CheckoutH2 } from '../checkout.styles';

export const SectionHeader = styled(CheckoutH2)`
  text-transform: uppercase;
`;

export const DisabledBackdrop = styled(Backdrop)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100;
`;

export const CustomPaper = styled(Paper)`
  position: relative;
  overflow: hidden;

  ${({ error, theme }) =>
    error === 'true' && `border: 1px solid ${theme.palette.error.main}`}
`;
