import { Box } from '@mui/material';
import SpreedlyCheckoutButtons from '../spreedly-checkout-buttons.component';
import SpreedlyCheckoutButtonsProvider from './spreedly-checkout-buttons-provider/spreedly-checkout-buttons-provider.component';
import ThreeDS2Modal from 'pages/payments/threeds2/threeds2-modal/threeds2-modal.component';
import useScript from 'react-script-hook/lib/use-script';
import { useContext } from 'react';
import { NewPaymentContext } from 'components/payment/payment.context';

const SpreedlyCheckoutButtonsContainer = props => {
  const { initOrderResponse } = useContext(NewPaymentContext);

  const [loading] = useScript({
    src:
      initOrderResponse?.attributes?.ENABLED_3DS2?.toLowerCase() === 'true'
        ? 'https://core.spreedly.com/iframe/iframe-v1.min.js'
        : null,
  });

  return (
    <SpreedlyCheckoutButtonsProvider>
      <Box data-testid="CheckoutButtons.Grid">
        <SpreedlyCheckoutButtons {...props} scriptLoading={loading} />
      </Box>
      <ThreeDS2Modal />
    </SpreedlyCheckoutButtonsProvider>
  );
};

export default SpreedlyCheckoutButtonsContainer;
