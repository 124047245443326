import { Box } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const pulsateRing = keyframes`
    0% {
        transform: scale(.33);
    }
    50%, 100% {
        opacity: 0;
    }
`;

export const Container = styled(Box)`
  flex-grow: 1;
  order: 2;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 180px;
    width: 100%;
  }
`;

export const RequestedAddressPin = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    animation: ${pulsateRing} 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  &:after {
    border-radius: 50%;
    content: '';
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
