import { Avatar, Box } from '@mui/material';
import styled from 'styled-components';

const imageDim = 100;
const smallImageDim = 70;

export const ImageWrapper = styled(Box)`
  border-radius: 50%;
  border: 3px solid #ddd;
  transition: border-color 400ms;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ selected, theme }) =>
    selected &&
    `
    border-color: ${theme.palette.primary.light};
    border-width: 5px;

    ${theme.breakpoints.down('xs')} {
      border-width: 4px;
    }
    `}
`;

export const CustomAvatar = styled(Avatar)`
  width: ${imageDim}px;
  height: ${imageDim}px;
  margin: 4px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: ${smallImageDim}px;
    height: ${smallImageDim}px;
  }
`;
