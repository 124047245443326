import { Button } from '@mui/material';
import React from 'react';

const IncButton = ({
  size = 'medium',
  variant = 'contained',
  color = 'primary',
  disableElevation = true,
  disabled,
  children,
  ...rest
}) => {
  return (
    <Button
      size={size}
      variant={variant}
      color={color}
      disableElevation={disableElevation}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default IncButton;
