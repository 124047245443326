import styled from 'styled-components';
import { Grid } from '@mui/material';

export const LoyaltyCustomTwoGrid = styled(Grid)`
  max-width: 20%;
  padding: 5px;
  display: flex;
  justify-content: center;
`;

export const LoyaltyCustomTwoPunchImg = styled.img`
  width: 60%;
  height: auto;
`;
