import { Box, Typography } from '@mui/material';
import { CustomSpinner } from 'components/spinner/spinner.styles';
import { useTranslation } from 'react-i18next';
import { useOrderStatus } from './checkout-status.hooks';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';

const CheckoutStatus = () => {
  const { t } = useTranslation();
  useOrderStatus();
  const title = usePageTitle(t('head.title.processing'));

  return (
    <Box
      display="flex"
      width="100%"
      flex="1"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid="CheckoutStatus.Box"
    >
      <Helmet title={title} />
      <>
        <Box mb={1}>
          <CustomSpinner />
        </Box>
        <Typography>{t('checkout.statusPage.pleaseWait1')}</Typography>
        <Typography variant="body2">
          {t('checkout.statusPage.pleaseWait2')}
        </Typography>
      </>
    </Box>
  );
};
export default CheckoutStatus;
