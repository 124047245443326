import { Box } from '@mui/material';
import styled from 'styled-components';

export const OpenHours = styled(Box)`
  p {
    display: flex;
    justify-content: space-between;
  }
  p,
  span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.secondaryTextColor};
  }
`;
