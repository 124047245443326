import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs.component';
import AccountSettingsForm from './account-settings-form/account-settings-form.component';
import { selectIsLoading } from 'redux/user/user.selectors';
import { fetchUserDataStartAsync } from 'redux/user/user.actions';
import Spinner from 'components/spinner/spinner.component';
import { Helmet } from 'react-helmet';
import { usePageTitle } from 'hooks/usePageTitle.hooks';

const AccountSettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const title = usePageTitle(t('head.title.settings'));

  useEffect(() => {
    dispatch(fetchUserDataStartAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title={title} />
      <Breadcrumbs
        maxWidth="md"
        aria-label={`Currently located at ${t('breadcrumbs.home')}`}
      >
        <Link to="/" component={RouterLink} underline="hover">
          {t('breadcrumbs.home')}
        </Link>
        <Typography>{t('breadcrumbs.settings')}</Typography>
      </Breadcrumbs>
      {isLoading ? <Spinner /> : <AccountSettingsForm />}
    </>
  );
};

export default AccountSettingsPage;
