import { Box, useTheme } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import FormError from 'components/form-error/form-error.component';

const style = {
  base: {
    fontSize: '18px',
  },
};

const StripeCardBase = ({ error, mb = 2 }) => {
  const theme = useTheme();

  return (
    <Box mb={mb}>
      <Box
        border="1px solid"
        borderColor={theme.palette.text.primaryLight}
        borderRadius="4px"
        p={1}
        bgcolor={theme.palette.type === 'dark' ? '#fff' : 'unset'}
      >
        <CardElement options={{ style }} />
      </Box>
      {!!error && <FormError errorMessage={error.message} />}
    </Box>
  );
};
export default StripeCardBase;
