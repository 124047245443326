import { Box } from '@mui/material';
import Spinner from 'components/spinner/spinner.component';
import StripeCardBase from '../stripe-card-base/stripe-card-base.component';
import { useStripeCardGuestComponent } from '../stripe-card.hooks';

const StripeCardGuest = () => {
  const { attaching } = useStripeCardGuestComponent();

  return (
    <Box data-testid="StripeCard.Container">
      {attaching && <Spinner />}
      <StripeCardBase mb={0} />
    </Box>
  );
};
export default StripeCardGuest;
