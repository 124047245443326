import styled from 'styled-components';
import { Box, Button } from '@mui/material';

export const QtyButtons = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;
  justify-content: space-between;

  button {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const QtyButton = styled(Button)`
  margin: 0 !important;
  min-width: unset;
`;

export const Quantity = styled.span`
  padding: 0 1em;
`;
