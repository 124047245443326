import React from 'react';
import { CardGiftcard } from '@mui/icons-material';
import { PaymentOptionType } from 'components/payment/payment.constants';
import PaymentOption from './payment-option.component';
import { useTranslation } from 'react-i18next';

const GuestGiftCardPaymentOption = React.forwardRef(({ ...rest }, ref) => {
  const { t } = useTranslation();
  const title = t('paymentSelector.paymentOption.giftCard.title');

  return (
    <PaymentOption
      icon={<CardGiftcard />}
      title={title}
      type={PaymentOptionType.GUEST_GIFT_CARD}
      buttonProps={{ 'aria-label': title }}
      {...rest}
      ref={ref}
    />
  );
});

export default GuestGiftCardPaymentOption;
