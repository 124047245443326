import styled, { css } from 'styled-components';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

export const itemSpacing = css`
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const CustomContainer = styled.div`
  padding: 1em;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 1.5em;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 1.5em;
  }
`;

export const Head = styled.div`
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #d8d8d8;
`;

export const OrderStatus = styled(Typography)`
  font-size: 0.875rem;
  color: #e61111;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-right: 0.2em;
  }

  &.completed {
    color: #48a117;
  }
`;

export const TimeAgo = styled(Typography)`
  font-size: 0.75rem;
`;

export const ItemsContainer = styled.div`
  border-bottom: 1px solid #d8d8d8;
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  ${itemSpacing}
`;

export const Qty = styled.span`
  background-color: rgba(216, 216, 216, 0.3);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  border-radius: 4px;
`;

export const ItemName = styled(Typography)`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

export const Footer = styled(Grid)`
  align-items: center;
  padding: 1em 0;
`;

export const Total = styled(Typography)`
  font-weight: bold;
`;

export const Buttons = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  button {
    white-space: nowrap;
    &:last-of-type {
      margin-left: 0.5em;
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: 100%;
    }
  }
`;
