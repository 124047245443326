import { Typography } from '@mui/material';
import styled from 'styled-components';

export const TitleBox = styled.div`
  margin-bottom: 1em;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  padding: 1.5em;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-top: ${({ isImage }) => (isImage ? '-7em' : 'unset')};
  position: relative;
`;

export const Title = styled(Typography)`
  font-size: 2rem;
`;
