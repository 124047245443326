import { Box, Card, darken, Typography } from '@mui/material';
import styled from 'styled-components';

export const Subtitle = styled(Typography)`
  margin-bottom: 1.5em;
  font-weight: bold;
`;

export const EmptyActivity = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const ActivityCard = styled(Card)`
  margin-bottom: 0.5em;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark'
      ? darken(theme.palette.background.paper, 0.1)
      : 'inherit'};
`;

export const PointsContent = styled(Typography)`
  display: flex;
  margin: 1em;
`;

export const TitleContainer = styled(Typography)`
  flex: 1 1 auto;
`;

export const Title = styled(Typography)`
  font-weight: 600;
`;

export const Date = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: 100;
  opacity: 0.5;
`;

export const Points = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0.4em;
`;

export const Pagination = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(1)} 0
    ${({ theme }) => theme.spacing(1)} 0;
`;
