import { Box } from '@mui/material';
import styled from 'styled-components';

export const OptionContainer = styled(Box)`
  margin-top: -1em;
  margin-bottom: 1em;
  margin-left: 3em;
`;

export const OptionDetail = styled(Box)`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  padding: 0.2em 0;
  color: ${({ theme }) => theme.palette.success.main};
`;
