import { Box, Typography } from '@mui/material';
import ARImage from 'components/ar-image/ar-image.component';
import StoreTitle from 'components/store-title/store-title.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React from 'react';
import StoreShortDescription from '../store-short-description/store-short-description.component';
import StorePhone from '../store-phone/store-phone.component';

const StoreCard = ({ store }) => {
  const { title, shortDescription, longDescription, mediumImage } =
    useDisplayInfo(store?.displayInfo);

  return (
    <Box p={2} data-testid="StoreCard.Box">
      <StoreTitle name={title} component="h2" />
      <StoreShortDescription shortDescription={shortDescription} />
      {mediumImage && <ARImage src={mediumImage} testId="StoreCard.ARImage" />}
      {longDescription && (
        <Box mt={1}>
          <Typography>{longDescription}</Typography>
        </Box>
      )}
      <Box mt={2}>
        <StorePhone phoneNumber={store?.phoneNumber} />
      </Box>
    </Box>
  );
};

export default StoreCard;
