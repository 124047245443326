import { Link } from '@mui/material';
import React from 'react';
import { Span, Text, TermsAndConditionsLink } from '../toc-and-privacy.styles';
import { useTranslation } from 'react-i18next';

const TocAndPrivacyFormInfo = ({ description, handleTocOpen }) => {
  const { t } = useTranslation();

  return (
    <Text align="center">
      {description} <br />
      <TermsAndConditionsLink
        component="button"
        type="button"
        onClick={handleTocOpen}
        data-testid="TocAndPrivacy.TermsAndConditionsLink"
      >
        <Span>{t('guest.termsAndConditions.label')}</Span>
      </TermsAndConditionsLink>
      {' & '}
      <Link
        href="https://incentivio.com/privacy"
        target="_blank"
        data-testid="TocAndPrivacy.PrivacyLink"
        aria-label={t('register.privacyPolicy.ariaLabel')}
        underline="hover"
      >
        <Span>{t('guest.privacyPolicy.label')}</Span>
      </Link>
    </Text>
  );
};

export default TocAndPrivacyFormInfo;
