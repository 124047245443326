import { createTheme, darken, lighten } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import 'react-awesome-slider/dist/styles.css';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { selectors } from 'redux/app/app.slice';
import { DARK_THEME, DEFAULT_THEME } from 'theme.constants';
import {
  selectColors,
  selectFonts,
  selectMessageCountColor,
} from './redux/config/config.selectors';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export const useBreakPoints = () => {
  const matchesMd = useMediaQuery(`(min-width:${breakpoints.values.md}px)`);
  const matchesLg = useMediaQuery(`(min-width:${breakpoints.values.lg}px)`);
  return {
    matchesMd,
    matchesLg,
  };
};

const useThemeVars = () => {
  const colors = useSelector(selectColors);
  const fonts = useSelector(selectFonts);
  const messageCountColor = useSelector(selectMessageCountColor);
  const isMenu = useRouteMatch(['/locations/:storeTitle/menu']);

  const { matchesMd, matchesLg } = useBreakPoints();

  const spacing = useMemo(() => (matchesLg ? 16 : 8), [matchesLg]);
  const navHeight = useMemo(() => {
    let navHeight = isMenu ? '99px' : '60px';
    if (matchesMd) {
      navHeight = '60px';
    }
    if (matchesLg) {
      navHeight = '80px';
    }
    return navHeight;
  }, [isMenu, matchesLg, matchesMd]);

  const overrides = useMemo(
    () => ({
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '100px',
            fontSize: '1rem',
            textTransform: 'capitalize',
            fontWeight: 'bold',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            alignItems: 'center',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          button: {
            textDecoration: 'underline',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
    }),
    [],
  );

  return {
    colors,
    fonts,
    messageCountColor,
    spacing,
    navHeight,
    overrides,
  };
};

export const useTheme = () => {
  const { colors, fonts, messageCountColor, spacing, navHeight, overrides } =
    useThemeVars();

  const standardPalette = {
    ...colors,
    primary: {
      main: colors?.primaryColor ?? '#3ce',
      contrastText: fonts?.tertiaryFont?.fontColor ?? '#fff',
    },
    secondary: {
      main: colors?.secondaryColor ?? '#5ee',
      contrastText: fonts?.tertiaryFont?.fontColor ?? '#fff',
    },
    accentColor: colors?.accentColor ?? '#fff',
    bgColor: colors?.bgColor ?? '#fbfbfb',
    messageCountColor: messageCountColor ?? '#e55241',
    tonalOffset: 0.15,
    text: {
      primary: '#000',
      primaryLight: lighten('#000', 0.3),
    },
    background: {
      default: '#fafafa',
    },
  };

  const standardTheme = createTheme({
    spacing,
    navHeight,
    fonts,
    palette: standardPalette,
    breakpoints,
    components: {
      ...overrides,
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: standardPalette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: standardPalette.text.primary,
            fontSize: '0.875rem',
          },
        },
      },
    },
  });

  const darkPalette = {
    ...colors,
    fonts,
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    accentColor: '#fff',
    bgColor: '#121212',
    messageCountColor: messageCountColor ?? '#e55241',
    tonalOffset: 0.15,
    text: {
      primary: '#fff',
      primaryLight: darken('#fff', 0.2),
    },
    mode: 'dark',
    type: 'dark',
    headerBgColor: '#343434',
    background: {
      default: '#303030',
      paper: '#424242',
    },
  };

  const darkTheme = createTheme({
    spacing,
    navHeight,
    palette: darkPalette,
    breakpoints,
    components: {
      ...overrides,
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: darkPalette.text.primary,
          },
          ':focus-visible': {
            outline: `3px dashed white !important`,
          },
          '.Mui-focusVisible': {
            outline: `3px dashed white`,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: darkPalette.text.primary,
            fontSize: '0.875rem',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          button: {
            '&:focus-visible': {
              outline: `3px dashed white`,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&:focus-visible': {
              outlineOffset: '-3px',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            'background-color': '#424242',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  });

  const savedTheme = useSelector(selectors.selectTheme);
  const theme = useMemo(() => {
    let theTheme;
    switch (savedTheme) {
      case DEFAULT_THEME:
        theTheme = standardTheme;
        break;
      case DARK_THEME:
        theTheme = darkTheme;
        break;
      default:
        theTheme = standardTheme;
    }
    return theTheme;
  }, [darkTheme, savedTheme, standardTheme]);

  return { theme, standardTheme, darkTheme };
};
