import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SpreedlyField from './spreedly-field.component';
import CountryField from './country-field.component';
import StateField from './state-field.component';

const AddressFields = () => {
  const { t } = useTranslation();

  return (
    <Box display="grid" rowGap={theme => theme.spacing(1)}>
      <Typography variant="h6" component="h3">
        {t('checkout.payment.spreedly.sections.billingAddress')}
      </Typography>
      <Box display="grid" rowGap={theme => theme.spacing(2)}>
        <SpreedlyField
          name="address1"
          label={t('checkout.payment.spreedly.fields.streetAddress.label')}
          required
          autoComplete="billing street-address"
        />
        <SpreedlyField
          name="address2"
          label={t('checkout.payment.spreedly.fields.streetAddress2.label')}
          placeholder=""
          autoComplete="billing address-line2"
        />
        <Box
          display="grid"
          gridTemplateColumns="2fr 1fr 1fr"
          gap={theme => theme.spacing(1)}
        >
          <SpreedlyField
            name="city"
            label={t('checkout.payment.spreedly.fields.city.label')}
            required
            autoComplete="billing address-level2"
          />
          <StateField />
          <SpreedlyField
            name="zip"
            label={t('checkout.payment.spreedly.fields.zipCode.label')}
            required
            autoComplete="billing postal-code"
          />
        </Box>
        <CountryField />
      </Box>
    </Box>
  );
};
export default AddressFields;
