import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TogglerButton from 'components/toggler-button/toggler-button.component';
import styled from 'styled-components';

export const Container = styled(AppBar)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.headerBgColor};
  height: ${({ theme }) => theme.navHeight};
  display: flex;
  justify-content: center;
`;

export const ToolbarWrapper = styled(Toolbar)`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: 80px;
  }
`;

export const LogoLinkContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    order: 1;
  }
`;

export const CatalogOptions = styled(Box)`
  display: flex;
  justify-self: center;
  justify-content: center;
  width: 100%;

  button {
    max-width: ${({ ismenubrowsingstore }) =>
      ismenubrowsingstore ? '340px' : '289px'};
    white-space: nowrap;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex: 1;
    order: 2;
    justify-content: ${({ ismenubrowsingstore }) =>
      ismenubrowsingstore ? 'flex-end' : 'flex-start'};
    margin-left: 30px;
  }
`;

export const StoreNameToggler = styled(TogglerButton)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: ${({ ismenubrowsingstore }) =>
      ismenubrowsingstore ? '100%' : '33.33%'};
    font-size: 0.875rem;
  }
`;

export const ScheduleToggler = styled(TogglerButton)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 66.67%;
    font-size: 0.875rem;
  }
`;

export const CatalogOptionText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SkipLink = styled.a`
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.palette.bgColor};
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 8px 16px;
  z-index: 9999;
  top: -100px;

  &:focus {
    top: 0;
  }
`;
