import { useScheduleDateController } from './schedule-date-controller.hooks';
import ScheduleDatePicker from './schedule-date-picker/schedule-date-picker.component';

const ScheduleDateController = props => {
  const { getInitialFocusedDate } = useScheduleDateController(props);
  return (
    <ScheduleDatePicker
      scheduleProps={props}
      getInitialFocusedDate={getInitialFocusedDate}
    />
  );
};

export default ScheduleDateController;
