import { Box } from '@mui/material';
import DelayedMessage from '../delayed-message/delayed-message.component';
import TimeSelector from '../time-selector/time-selector.component';
import { useFieldNames } from '../useFieldNames.hooks';

const TimeSelectorWrapper = props => {
  const { fieldNames } = useFieldNames();
  const {
    availableTimes,
    delayedMessage,
    defaultTime,
    store: { storeId },
    ...rest
  } = props;

  return (
    <Box overflow="auto" maxHeight="250px" id="time-container">
      <DelayedMessage delayedMessage={delayedMessage} />
      <TimeSelector
        {...rest}
        name={fieldNames.time}
        defaultValue={defaultTime}
        times={availableTimes}
        containerId="time-container"
        storeId={storeId}
      />
    </Box>
  );
};

export default TimeSelectorWrapper;
