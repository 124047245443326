import { Grid, Tooltip } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  actions as appActions,
  selectors as appSelectors,
} from 'redux/app/app.slice';
import AddressAutocomplete from '../address-autocomplete/address-autocomplete.component';
import Form from 'components/form/form.component';
import * as S from './pickup-locations.styles';

const name = 'pickupSearch';
const PickupLocationsSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchByGeoLocation, setSearchByGeoLocation] = useState(false);
  const searchLocation = useSelector(appSelectors.selectSearchLocation);
  const [showError, setShowError] = useState();

  const handleChange = value => {
    setSearchByGeoLocation(false);
    setShowError(false);
    dispatch(appActions.setSearchLocation(value));
  };

  const successCallback = position => {
    const location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    setSearchByGeoLocation(true);
    dispatch(appActions.setGeoLocation(location));
    dispatch(appActions.setSearchLocation(undefined));
  };

  const errorCallback = error => {
    if (error.code === 1)
      toast.error(
        t('locations.pickupInput.locationSearch.geoLocationErrors.permission'),
      );
    else
      toast.error(
        t('locations.pickupInput.locationSearch.geoLocationErrors.failure'),
      );
    dispatch(appActions.setGeoLocation(undefined));
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  const handleAddressSubmit = e => {
    e.preventDefault();
    setShowError(!searchLocation);
  };

  return (
    <Grid container>
      <Grid item xs={11}>
        <Form onSubmit={handleAddressSubmit}>
          <AddressAutocomplete
            name={name}
            defaultValue={searchLocation}
            value={searchLocation}
            onChange={handleChange}
            autocompleteInputProps={{
              placeholder: t(
                'locations.pickupInput.locationSearch.placeholder',
              ),
            }}
            autocompleteProps={{
              forcePopupIcon: false,
              ListboxProps: {
                'data-testid': 'AddressSelect.CustomSelect.Menu',
              },
              'data-testid': 'AddressSelect.CustomSelect',
            }}
            formErrorProps={
              showError
                ? {
                    errorMessage: t(
                      'locations.pickupInput.locationSearch.error',
                    ),
                    'aria-label': t('addressSelect.aria.instructions'),
                  }
                : {}
            }
          />
        </Form>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title={t('locations.pickupInput.label')}>
          <S.GeoLocationIcon
            disabled={searchByGeoLocation}
            onClick={() => {
              getCurrentLocation();
              dispatch(appActions.setSearchLocation(null));
              setShowError(false);
            }}
            data-testid="PickupLocationSearch.CurrentLocation"
          >
            <MyLocationIcon />
          </S.GeoLocationIcon>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default PickupLocationsSearch;
