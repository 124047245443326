import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'components/form/form.component';
import IncButton from 'components/inc-button/inc-button.component';
import { NewPaymentContext } from 'components/payment/payment.context';
import Spinner from 'components/spinner/spinner.component';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useScript from 'react-script-hook';
import SpreedlyContext from '../spreedly.context';
import SpreedlyForm from './forms/spreedly-form.component';
import { getSchema } from './spreedly-card.utils';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';

const SpreedlyCard303942 = ({ handleCardInfo, button }) => {
  const { t } = useTranslation();
  const { initOrderResponse } = useContext(NewPaymentContext);
  const formName = initOrderResponse?.attributes?.hostedPaymentPageName;
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(getSchema(formName)),
  });
  const [scriptLoading] = useScript({
    src: 'https://core.spreedly.com/iframe/iframe-v1.min.js',
    checkForExisting: true,
  });
  const { setSubmitSpreedlyForm } = useContext(SpreedlyContext);
  const setPaymentLoading = useCheckoutPaymentLoading(
    state => state.setLoading,
  );
  const paymentLoading = useCheckoutPaymentLoading(state => state.loading);

  const submit = methods.handleSubmit(
    data => {
      if (!data.isValidCVV) {
        window.Spreedly.transferFocus('cvv');
        window.Spreedly.validate();
      } else {
        setPaymentLoading(true);
        window.Spreedly.tokenizeCreditCard(data);
      }
    },
    () => {
      window.Spreedly.validate();
    },
  );

  useEffect(() => {
    setSubmitSpreedlyForm(_state => submit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scriptLoading ? (
    <Spinner />
  ) : (
    <FormProvider {...methods}>
      <Box data-testid="SpreedlyCard.Container" id="payment-form">
        <Form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            submit();
          }}
          noValidate
        >
          <SpreedlyForm formName={formName} handleCardInfo={handleCardInfo} />
          {button && (
            <IncButton
              type="submit"
              data-testid="SpreedlyCard.CustomButton"
              fullWidth
              disabled={paymentLoading}
            >
              {t('checkout.payment.spreedly.submit')}
            </IncButton>
          )}
        </Form>
      </Box>
    </FormProvider>
  );
};

export default SpreedlyCard303942;
