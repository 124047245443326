import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import { darken, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';

const contentHorizontalSpacing = css`
  padding-left: 2em;
  padding-right: 2em;
`;

export const Container = styled(Box)`
  margin-bottom: 1em;

  ${({ nested }) =>
    nested &&
    `
    margin: 0 !important;
    .optionTitle {
      background: none !important;
    }
  `}
`;

export const OptionTitle = styled(Typography)`
  font-size: 1.125rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: ${({ theme }) =>
    darken(
      theme.palette.background.paper,
      theme.palette.type === 'dark' ? 0.35 : 0.1,
    )};
  ${contentHorizontalSpacing}
`;

export const HelperText = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-style: italic;
`;

export const Group = styled(FormGroup)`
  ${contentHorizontalSpacing}
`;

export const Error = styled(Typography)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: white;
  background-color: #ea170b;
  ${contentHorizontalSpacing};
  font-size: 0.875rem;

  &:after {
    content: '';
    background: #ea170b;
    left: 0;
    right: 0;
    position: absolute;
  }
`;
