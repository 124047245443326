import { createSelector } from 'reselect';
import { isExpired } from 'utils';
import {
  createFetchEntities,
  makeEntityAdapter,
  makeFetchNew,
  makeInitialState,
  makeSelectors,
  makeSlice,
  makeUpdateStatus,
} from '../appcontent/appcontent.utils';
import { selectData } from '../data.selectors';

const sliceName = 'data/offers';

const entityAdapter = makeEntityAdapter('distributedOfferId');

export const selectors = makeSelectors(
  'Offers',
  'offers',
  selectData,
  entityAdapter,
);

selectors.selectAllFiltered = createSelector(selectors.selectAll, allOffers =>
  allOffers.filter(
    offer =>
      offer.status !== 'USER_REDEEMED' &&
      offer.status !== 'SYSTEM_REDEEMED' &&
      !isExpired(offer.redemptionEndDate),
  ),
);

selectors.selectTotalFiltered = createSelector(
  selectors.selectAllFiltered,
  filteredOffers => filteredOffers.length,
);

selectors.selectLoyaltyOffers = createSelector(
  selectors.selectAllFiltered,
  filteredOffers => filteredOffers.filter(offer => !!offer.isLoyaltyOffer),
);

selectors.selectLoyaltyOffersSortWithPointsCost = createSelector(
  selectors.selectLoyaltyOffers,
  loyaltyOffers => loyaltyOffers.sort((a, b) => a.pointsCost - b.pointsCost),
);

selectors.selectLoyaltyOffersSortWithPointsCostExpired = createSelector(
  selectors.selectLoyaltyOffersSortWithPointsCost,
  loyaltyOffers => loyaltyOffers.filter(offer => offer.status === 'EXPIRED'),
);

selectors.selectLoyaltyOffersSortWithPointsCostNonExpired = createSelector(
  selectors.selectLoyaltyOffersSortWithPointsCost,
  loyaltyOffers => loyaltyOffers.filter(offer => offer.status !== 'EXPIRED'),
);

selectors.selectSortedOffers = createSelector(
  selectors.selectLoyaltyOffersSortWithPointsCostNonExpired,
  selectors.selectLoyaltyOffersSortWithPointsCostExpired,
  (
    loyaltyOffersSortWithPointsCostNonExpired,
    loyaltyOffersSortWithPointsCostExpired,
  ) => [
    ...loyaltyOffersSortWithPointsCostNonExpired,
    ...loyaltyOffersSortWithPointsCostExpired,
  ],
);

selectors.selectNonLoyaltyOffers = createSelector(
  selectors.selectAllFiltered,
  filteredOffers => filteredOffers.filter(offer => !offer.isLoyaltyOffer),
);

selectors.selectTotalLoyalty = createSelector(
  selectors.selectLoyaltyOffers,
  loyaltyOffers => loyaltyOffers.length,
);

const fetchOffers = createFetchEntities(
  `${sliceName}/fetchOffers`,
  '/appoffers',
  response => slice.actions.upsertMany(response.data?.offerList?.offers ?? []),
  response =>
    slice.actions.totalPages(response.data?.offerList?.paging?.totalPages ?? 0),
  (page, thunkAPI) =>
    slice.actions.retrievedPages(
      Math.max(page, selectors.selectRetrievedPages(thunkAPI.getState())),
    ),
  response => response.data?.offerList?.offers,
  {
    count: 100,
    languageCode: 'EN',
    page: 1,
  },
  {
    condition: (_, { getState }) => !selectors.selectLoading(getState()),
  },
);

const refreshOffers = makeFetchNew(
  `${sliceName}/refreshOffers`,
  '/appoffers',
  response => slice.actions.upsertMany(response.data?.offerList?.offers ?? []),
  response => response.data?.offerList?.offers,
  {
    count: 10,
    languageCode: 'EN',
    page: 1,
  },
);

export const initialState = makeInitialState(entityAdapter);

export const slice = makeSlice(
  sliceName,
  initialState,
  entityAdapter,
  fetchOffers,
  refreshOffers,
);

const deleteOffer = makeUpdateStatus(
  `${sliceName}/deleteOffer`,
  '/changedistributedofferstatus',
  distributedOfferId => ({ distributedOfferId, redeemStatus: 'DELETED' }),
  distributedOfferId => slice.actions.removeOne(distributedOfferId),
);

const updateOfferStatus = makeUpdateStatus(
  `${sliceName}/updateStatus`,
  '/changedistributedofferstatus',
  (distributedOfferId, redeemStatus) => ({ distributedOfferId, redeemStatus }),
  (id, status) => slice.actions.updateOne({ id, changes: { status } }),
);

export const actions = {
  ...slice.actions,
  fetchOffers,
  refreshOffers,
  deleteOffer,
  updateOfferStatus,
};

export default slice.reducer;
