import { Box } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';
import OrderItemOptionsList from 'components/order-item-options-collapse/order-item-options-list/order-item-options-list.component';
import { CheckoutH3, ItemTotal } from 'pages/checkout/checkout.styles';
import React from 'react';
import { useSummaryItemComponent } from './summary-item.hooks';
import * as S from './summary-item.styles';
import { useTranslation } from 'react-i18next';
import ItemAndModierDiscounts from 'components/item-and-modifier-discounts/item-and-modifier-discounts.component';
import { convertCentsToDollar } from 'utils';
import SummaryItemsQtyButtons from './summary-item-qty-buttons/summary-item-qty-buttons.component';

const SummaryItem = ({ item }) => {
  const {
    title,
    smallImage,
    modifyQtyType,
    modifyingQtyItemIds,
    options,
    quantity,
    openModal,
    handleRemoveItem,
    handleDecrement,
    handleIncrement,
    hideQuantityPicker,
    basePrice,
    displaySubtotal,
    itemDiscounts,
    displayPreDiscountSubtotal,
    isOrderDiscountApplied,
  } = useSummaryItemComponent(item);
  const { t } = useTranslation();
  return (
    <Box display="flex" flex="1" minHeight="80px" data-testid="SummaryItem.Box">
      {!!smallImage?.[0] && (
        <Box width="80px" height="80px" borderRadius="4px" overflow="hidden">
          <S.Image src={smallImage[0]} alt="" data-testid="SummaryItem.Image" />
        </Box>
      )}
      <Box display="flex" pl={1} flex="1">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ width: '100%' }}
          pr={2}
        >
          <Box>
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flex="1">
                <CheckoutH3
                  id={`cart-item-${item.orderItemId}-title`}
                  data-testid="SummaryItem.CheckoutH3.Title"
                >
                  {title}
                </CheckoutH3>
              </Box>
              <Box pl={1}>
                <S.Price variant="body2" data-testid="SummaryItem.Price">
                  {convertCentsToDollar(basePrice)}
                </S.Price>
              </Box>
            </Box>
            {itemDiscounts?.length > 0 && (
              <ItemAndModierDiscounts
                itemDiscounts={itemDiscounts}
                dataTestId="Item.Discount"
              />
            )}
            <Box>
              {options && (
                <Box pl="8px">
                  <OrderItemOptionsList options={options} />
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flex="1"
              justifyContent="space-between"
              alignItems="center"
              pl={1}
            >
              <Box display="flex" flex="1">
                <ItemTotal>{t('checkout.items.itemTotal.label')}</ItemTotal>
              </Box>
              <Box>
                <ItemTotal variant="body2" data-testid="SummaryItem.ItemTotal">
                  $
                  {isOrderDiscountApplied
                    ? displayPreDiscountSubtotal
                    : displaySubtotal}
                </ItemTotal>
              </Box>
            </Box>
          </Box>

          <Box display="flex">
            <Box>
              <S.EditButton
                id={`cart-item-${item.orderItemId}-edit`}
                size="small"
                variant="text"
                onClick={openModal}
                data-testid="SummaryItem.EditButton"
                aria-labelledby={`cart-item-${item.orderItemId}-title cart-item-${item.orderItemId}-edit`}
              >
                {t('cart.editAction.label')}
              </S.EditButton>
              <IncButton
                id={`cart-item-${item.orderItemId}-remove`}
                size="small"
                variant="text"
                onClick={handleRemoveItem}
                disabled={
                  modifyQtyType === 'REMOVE_ITEM' && modifyingQtyItemIds.length
                }
                data-testid="SummaryItem.IncButton.Remove"
                aria-labelledby={`cart-item-${item.orderItemId}-title cart-item-${item.orderItemId}-remove`}
              >
                {t('cart.removeAction.label')}
              </IncButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <SummaryItemsQtyButtons
        quantity={quantity}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
        hideQuantityPicker={hideQuantityPicker}
        modifyQtyType={modifyQtyType}
        modifyingQtyItemIds={modifyingQtyItemIds}
        title={title}
      />
    </Box>
  );
};

export default SummaryItem;
