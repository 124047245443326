import MenuDialogContext from 'hooks/dialog/menu-dialog.context';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { getAnalytics } from 'util/analytics';
import { getOptionCategories } from 'utils';
import {
  addOrderItemStartAsync,
  updateOrderItemStartAsync,
} from 'redux/cart/cart.actions';
import { makeSelectOrderItemByOrderItemId } from 'redux/cart/cart.selectors';
import { getOptions, mapOptionsToRequest } from 'redux/catalogs/catalogs.utils';

export const useMenuModal = args => {
  const { getValues, trigger } = args;

  const dispatch = useDispatch();

  const {
    handleClose,
    item,
    catalogId,
    groupId,
    globalOptionGroups,
    editInfo,
    recommendationView,
    path,
    visibility,
    group,
    itemQuantity,
  } = useContext(MenuDialogContext);

  const selectOrderItemByOrderItemId = useCallback(
    state =>
      makeSelectOrderItemByOrderItemId(
        editInfo ? editInfo.orderItemId : item.itemId,
      )(state),
    [editInfo, item.itemId],
  );
  const orderItem = useSelector(selectOrderItemByOrderItemId);

  const tabValues = { selectOptions: 0, customizations: 1 };
  const extendedAttributes = item.extendedAttributes;

  const optionGroups =
    globalOptionGroups && item?.inheritParentOptions
      ? [...(item?.optionGroups ?? []), ...globalOptionGroups]
      : item?.optionGroups ?? [];

  const { mediumImage, title, shortDescription, longDescription } =
    useDisplayInfo(item?.displayInfo);

  const { title: groupDisplayName } = useDisplayInfo(group?.displayInfo);

  const [selectionGroups, customizationGroups] =
    getOptionCategories(optionGroups);

  const isCustomizations = customizationGroups?.length > 0;

  const [tabVal, setTabVal] = useState(tabValues.selectOptions);

  useEffect(() => {
    if (!group) return;

    getAnalytics().trackProductViewed({
      ...item,
      group: { groupName: group.title, groupDisplayName },
    });
  }, [item, group, groupDisplayName]);

  useEffect(() => {
    if (selectionGroups.length < 1 && isCustomizations) {
      setTabVal(tabValues.customizations);
    }
  }, [isCustomizations, selectionGroups.length, tabValues.customizations]);

  const filteredEditOptionsByGroupId = useCallback(
    group =>
      editInfo?.options?.filter(opt => opt.group.groupId === group.groupId),
    [editInfo],
  );

  const handleTabChange = (_e, newValue) => {
    setTabVal(newValue);
    trigger();
  };

  const onSubmit = async ({ itemInstructions }) => {
    if (editInfo) {
      await handleModifyItem(itemInstructions);
      return;
    }
    await handleAddToCart(itemInstructions);
  };

  const onError = errors => {
    if (errors?.options && Object.keys(errors.options).length) {
      const firstOptionErrorKey = Object.keys(errors.options)[0];

      const isSelectionGroup = selectionGroups.some((_, index) =>
        firstOptionErrorKey.startsWith(`g${index}`),
      );

      if (isSelectionGroup) {
        setTabVal(tabValues.selectOptions);
      } else {
        setTabVal(tabValues.customizations);
      }

      scroller.scrollTo(firstOptionErrorKey, {
        containerId: 'CatalogModal.Content',
        smooth: true,
      });
    }
  };

  const handleOrderItemResponse = response => {
    if (response === 'MODIFIER_OUT_OF_STOCK') return;

    if (response === 'ITEM_OUT_OF_STOCK') handleClose();

    if (response?.data) handleClose();
  };

  const handleAddToCart = async itemInstructions => {
    const response = await dispatch(
      addOrderItemStartAsync(
        catalogId,
        groupId,
        { itemId: item.itemId, title },
        itemInstructions,
        mapOptionsToRequest(getOptions(getValues()?.options)),
        itemQuantity,
        recommendationView,
        path,
      ),
    );
    handleOrderItemResponse(response);
  };

  const handleModifyItem = async itemInstructions => {
    const response = await dispatch(
      updateOrderItemStartAsync({
        ...orderItem,
        groupId,
        itemInstructions,
        options: mapOptionsToRequest(getOptions(getValues()?.options)),
        quantity: itemQuantity,
      }),
    );

    handleOrderItemResponse(response);
  };

  const onClose = (e, reason) => {
    if (reason === 'backdropClick') {
      e.type === 'click' && e.button === 0 && handleClose();
    } else {
      handleClose();
    }
  };

  return {
    handleClose,
    onClose,
    visibility,
    onError,
    onSubmit,
    handleTabChange,
    filteredEditOptionsByGroupId,
    tabVal,
    extendedAttributes,
    mediumImage,
    title,
    shortDescription,
    longDescription,
    item,
    selectionGroups,
    isCustomizations,
    tabValues,
    customizationGroups,
    editInfo,
    itemQuantity,
  };
};
