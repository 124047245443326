import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';

export const OptionWrapper = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.primary.main};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};

    .MuiIconButton-label {
      &:after {
        content: '';
        background-color: ${({ theme }) => theme.palette.primary.main};
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 2px;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
