import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MuiRating from '@mui/lab/Rating';

const Rating = ({ max, className, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...rest}
      control={control}
      render={({ field }) => (
        <MuiRating max={max} className={className} {...field} />
      )}
    />
  );
};

export default Rating;
