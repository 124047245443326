import { Box } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CloverFormContext from '../clover-form/clover-form.context';
import { cloverSubmitButtonClick } from '../clover.utils';

const CloverSubmitButton = ({ callback = () => null, label }) => {
  const { t } = useTranslation();
  const { setShowLoading, showLoading } = useContext(CloverFormContext);

  const onClick = async () => {
    setShowLoading(true);
    const payload = await cloverSubmitButtonClick();
    setShowLoading(false);
    if (!!payload)
      callback({
        ...payload,
        full_name: `${payload.brand} ${t('clover.nickname.suffix')}`,
      });
  };

  return (
    <Box marginTop="20px">
      <IncButton
        fullWidth
        type="submit"
        onClick={onClick}
        disabled={showLoading}
        data-testid="CloverSubmitButton.IncButton"
      >
        {label ?? t('clover.submitButton.label')}
      </IncButton>
    </Box>
  );
};

export default CloverSubmitButton;
