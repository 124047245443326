import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MandatoryFieldsInstruction = () => {
  const { t } = useTranslation();
  return (
    <Box mb={0.5}>
      <Typography variant="caption">
        * {t('forms.mandatoryFieldInstruction')}
      </Typography>
    </Box>
  );
};

export default MandatoryFieldsInstruction;
