import styled from 'styled-components';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';

export const SectionTitle = styled.h2`
  font-size: 1.125rem;
`;

export const SectionContainer = styled.div`
  margin-bottom: 2em;

  .MuiFormControlLabel-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .MuiSwitch-root {
    order: 2;
  }
`;

export const SectionLinkText = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
`;

export const ProfilePic = styled(Avatar)`
  width: 80px;
  height: 80px;
  margin-bottom: 1em;
  cursor: pointer;
`;

export const ProfilePicUpload = styled.input`
  display: none;
`;

export const EmailLink = styled(Link)`
  padding-left: 3px;
  padding-bottom: 3px;
  margin-left: 15px;
  font-size: 14px;
`;
