import { FormControlLabel } from '@mui/material';
import { CustomCheckbox } from 'components/custom-checkbox/custom-checkbox.styles';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SavePaymentInstrumentCheckbox = () => {
  const { t } = useTranslation();
  const { field } = useController({ name: 'savePaymentInstrument' });

  return (
    <FormControlLabel
      data-testid="SpreedlySaveCardCheckbox.CheckboxWrapper"
      control={<CustomCheckbox id="savePaymentInstrument" />}
      label={t('payment.saveOption')}
      {...field}
      checked={field.value === true}
      defaultValue={false}
    />
  );
};
export default SavePaymentInstrumentCheckbox;
