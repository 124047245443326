import React from 'react';
import * as S from '../locations-finder.styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import OrderTypeSelectorButtons from 'components/new-order/order-type-selector/order-type-selector-buttons/order-type-selector-buttons.component';
import {
  ForceOrderTypeToggleButton,
  ForceOrderTypeToggleGroup,
} from 'components/new-order/order-type-selector/order-type-selector.styles';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useFormContext } from 'react-hook-form';

const ForceOrderTypeSelectPanel = ({
  types,
  setSelectedForceOrderType,
  selectedForceOrderType,
}) => {
  const { t } = useTranslation();
  const { fieldNames } = useFieldNames();
  const { setValue } = useFormContext();

  return (
    <S.Container
      data-testid="LocationsFinderOrderType.Container"
      flexDirection="column"
      alignItems="center"
      p={2}
    >
      <Box mb={2}>
        <S.ForcedOrderTypeTitle variant="h5">
          {t('locations.forceOrderType.label')}
        </S.ForcedOrderTypeTitle>
      </Box>
      <Grid container spacing={1}>
        <OrderTypeSelectorButtons
          types={types}
          as={ForceOrderTypeToggleGroup}
          roverAs={ForceOrderTypeToggleButton}
          value={selectedForceOrderType}
          exclusive
          id="locationsFinderOrderType"
          data-testid="OrderTypeSelector.CircleToggleGroup"
          aria-label={t('orderTypeSelector.ariaLabel')}
          ref={null}
          onChange={(_e, newValue) => {
            setValue(fieldNames.orderType, newValue);
            setSelectedForceOrderType(newValue);
          }}
        />
      </Grid>
    </S.Container>
  );
};

export default ForceOrderTypeSelectPanel;
