import { TabContext } from '@mui/lab';
import ScrollImageTab from 'components/image-tab/scroll-image-tab/scroll-image-tab.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTabList } from '../menu.styles';
import SingleCatalogGroups from './single-catalog-groups/single-catalog-groups.component';
import { useGroupTabs } from './single-catalog-menu.hooks';

const SingleCatalogMenu = () => {
  const {
    tabs,
    groups,
    catalog,
    tabValue,
    fallbackImage,
    spyOffset,
    spy,
    groupsHaveImages,
    tabListRef,
    setTabValue,
    handleTabChange,
  } = useGroupTabs();
  const { t } = useTranslation();

  return groups.length > 1 ? (
    <TabContext value={tabValue}>
      <CustomTabList
        onChange={handleTabChange}
        indicatorColor="primary"
        aria-label={t('catalog.tabs.groups')}
        variant="scrollable"
        scrollButtons="auto"
        ref={tabListRef}
        data-testid="Menu.Tabs"
        textColor="inherit"
      >
        {tabs.map(({ label, imageSrc, groupId }) => (
          <ScrollImageTab
            key={`tab-${groupId}`}
            value={groupId}
            offset={spyOffset}
            onSetActive={() => setTabValue(groupId)}
            imageSrc={groupsHaveImages ? imageSrc || fallbackImage : null}
            label={label}
            spy={spy}
            data-testid="Menu.Tab"
            groupId={groupId}
          />
        ))}
      </CustomTabList>
      <SingleCatalogGroups groups={groups} catalog={catalog} />
    </TabContext>
  ) : (
    <SingleCatalogGroups groups={groups} catalog={catalog} />
  );
};

export default SingleCatalogMenu;
