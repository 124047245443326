import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';

export const CustomContainer = styled(Container)`
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: left;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  word-break: break-word;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: center;
  }
`;

export const Body = styled.div`
  flex-grow: 1;
`;

export const Title = styled(Typography)`
  font-weight: bold;
  font-size: 1.25rem;
`;

export const Description = styled(Typography)`
  margin-top: 0.5em;
`;

export const ReviewBox = styled(Box)`
  border: 1px solid;
  border-radius: 4px;
  padding: 1em;
  margin: 2em auto 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: center;
  }
`;

export const ThankYouMessage = styled.div`
  margin-top: 1em;
  font-size: 1.25em;
`;
