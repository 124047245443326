import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SpreedlyField from './spreedly-field.component';

const NameFields = () => {
  const { t } = useTranslation();

  return (
    <Box display="grid" rowGap={theme => theme.spacing(1)}>
      <Typography variant="h6" component="h3">
        {t('checkout.payment.spreedly.sections.name')}
      </Typography>
      <Box display="grid">
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={theme => theme.spacing(1)}
        >
          <SpreedlyField
            name="first_name"
            label={t('forms.label.firstName')}
            required
            autoComplete="given-name"
          />
          <SpreedlyField
            name="last_name"
            label={t('forms.label.lastName')}
            required
            autoComplete="family-name"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default NameFields;
