import { Box, Grid, Input, InputLabel } from '@mui/material';
import {
  getResponseErrorCode,
  getResponseIncErrorMessage,
} from 'apis/incentivio-api.util';
import AppleSignup from 'components/create-account/apple-signup/apple-signup.component';
import GoogleSignUp from 'components/create-account/google-signup/google-signup.component';
import PasswordVisibility from 'components/form/form-fields/password-visibility/password-visibility.component';
import TrimEmailField from 'components/form/form-fields/trim-email-field/trim-email-field.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';
import IncButton from 'components/inc-button/inc-button.component';
import ResendCode from 'components/verify-code/resend-code/resend-code.component';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { EMAIL_CODE, SMS_CODE } from 'redux/config/config.constants';
import { selectSocialSignInEnabled } from 'redux/config/config.selectors';
import { signInStartAsync } from 'redux/user/user.actions';
import { selectIsLoading } from '../../redux/user/user.selectors';
import CustomCheckbox from '../custom-checkbox/custom-checkbox.component';
import CustomInput from '../custom-input/custom-input.component';
import FormError from '../form-error/form-error.component';
import Form from '../form/form.component';
import Spinner from '../spinner/spinner.component';
import * as S from './sign-in-fields.styles';

const SignInFields = ({ send, verificationMethod, setState, state }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const socialLogin = useSelector(selectSocialSignInEnabled);
  const isCheckout = useRouteMatch(['/checkout']);
  const isAuthRoute = !!useRouteMatch([
    '/auth/verifyemail',
    '/auth/verifysms',
    '/auth/resetpassword',
  ]);
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { component } = locationState || {};

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();

  const onSubmit = async data => {
    const res = await dispatch(signInStartAsync(data));

    if (!!res?.data) {
      component && history.push(component);
      isAuthRoute && history.push('/');
      return;
    }

    if (
      res?.response?.data?.error === 'invalid_grant' &&
      res?.response?.data?.error_description === 'user not verified'
    ) {
      if ([SMS_CODE, EMAIL_CODE].includes(verificationMethod)) {
        setState(state => ({
          ...state,
          verified: false,
          email: data.email,
          password: data.password,
          previous: 'LOGIN',
        }));

        send('CODE_VERIFICATION');
        return;
      }

      setState(state => ({ ...state, email: data.email, verified: false }));

      return;
    }

    let errorMessage;
    if (getResponseErrorCode(res) === 'RECAPTCHA_TOKEN_NOT_FOUND') {
      errorMessage = t('errors.refreshRequired');
    } else {
      errorMessage =
        getResponseIncErrorMessage(res) || t('errors.genericError');
    }

    setError(errorMessage);
  };

  const { ref, ...registerPasswordProps } = register('password', {
    required: true,
  });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} dataTestId="SignInFields.Form">
        {isLoading && !isCheckout && <Spinner fade={false} />}
        <MandatoryFieldsInstruction />
        <CustomInput>
          <InputLabel htmlFor="email">{t('signIn.label.email')}*</InputLabel>
          <TrimEmailField
            name="email"
            fullWidth
            placeholder={t('signIn.placeholder.email')}
            inputProps={{ autoComplete: 'email', 'aria-required': true }}
            error={!!errors.email}
            {...register('email', {
              required: t('signIn.errors.email'),
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('forms.errors.email.invalid'),
              },
            })}
          />
          {errors.email && (
            <FormError
              errorMessage={errors.email.message}
              data-testid="SignInFields.TrimEmailField.FormError"
            />
          )}
        </CustomInput>

        <CustomInput>
          <InputLabel htmlFor="password">
            {t('signIn.label.password')}*
          </InputLabel>
          <Input
            id="password"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            {...registerPasswordProps}
            inputRef={ref}
            error={!!errors.password}
            placeholder={t('signIn.placeholder.password')}
            inputProps={{ autoComplete: 'current-password' }}
            endAdornment={
              <PasswordVisibility
                setShowPassword={setShowPassword}
                showPassword={showPassword}
                iconButtonProps={{
                  'data-testid': 'SignInFields.IconButton.Show',
                  'aria-label': t('forms.password.ariaLabel.toggleVisibility'),
                }}
              />
            }
          />
          {errors.password && (
            <FormError
              errorMessage={t('signIn.errors.password')}
              data-testid="SignInFields.Password.FormError"
            />
          )}
          <Box justifyContent="space-between" display="flex">
            <S.StyledActionLink
              data-testid="SignInFields.ActionItem.ForgotPassword"
              onClick={() => send('FORGOT_PASSWORD')}
            >
              {t('signIn.actions.forgotPassword')}
            </S.StyledActionLink>
            {!socialLogin && (
              <S.StyledActionLink
                onClick={() => send('REGISTER')}
                data-testid="SignInFields.ActionItem.CreateAccount"
              >
                {t('signIn.actions.createAccount')}
              </S.StyledActionLink>
            )}
          </Box>
        </CustomInput>

        <CustomCheckbox
          {...register('remember_me', { required: false })}
          label={t('signIn.label.rememberMe')}
          dataTestId="SignInFields.CustomCheckbox.RememberMe"
        />

        {error && <FormError errorMessage={error} />}

        {!state.verified && <ResendCode email={state.email} />}

        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <IncButton
                type="submit"
                data-testid="SignInFields.CustomButton.Submit"
                fullWidth
                className="inlineSubmitButton"
              >
                {t('signIn.label.login')}
              </IncButton>
            </Grid>

            {!!socialLogin && (
              <>
                <Grid item xs={12}>
                  <GoogleSignUp
                    btnText={t('authOptions.label.googleSignIn')}
                    send={send}
                    setError={setError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppleSignup
                    btnText={t('authOptions.label.appleSignIn')}
                    send={send}
                    setError={setError}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Form>
    </FormProvider>
  );
};

export default SignInFields;
