import { InputLabel } from '@mui/material';
import CustomCheckbox from 'components/custom-checkbox/custom-checkbox.component';
import CustomInput from 'components/custom-input/custom-input.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReferralCode = ({ name = 'referralCode', register, ...rest }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const handleClick = useCallback(() => setChecked(prev => !prev), []);

  return (
    <>
      <CustomInput>
        <CustomCheckbox
          checked={checked}
          label={t('register.label.referralCheckbox')}
          dataTestId="ReferralCode.CustomCheckbox.Referral"
          onChange={handleClick}
        />
      </CustomInput>
      {checked && (
        <CustomInput>
          <InputLabel htmlFor={name}>
            {t('register.label.referralCode')}
          </InputLabel>
          <TrimTextField
            fullWidth
            id={name}
            {...register(name, { required: false })}
            placeholder={t('register.placeholder.referralCode')}
            {...rest}
          />
        </CustomInput>
      )}
    </>
  );
};

export default ReferralCode;
