import { Box } from '@mui/material';
import { CardGiftcard } from '@mui/icons-material';
import AddExistingGiftCard from 'components/add-purchase-gift-card/add-existing-gift-card/add-existing-gift-card.component';
import PurchaseGiftCard from 'components/add-purchase-gift-card/purchase-gift-card/purchase-gift-card.component';
import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAddGiftCardToWallet,
  selectPurchaseNewGiftCard,
} from 'redux/config/config.selectors';
import CardSelect from '../card-select/card-select.component';

const GiftCardPanel = ({ giftCards, selectedGiftCard, onChangeGiftCard }) => {
  const { t } = useTranslation();
  const {
    open: existingOpen,
    handleClose: existingHandleClose,
    handleOpen: existingHandleOpen,
  } = useCustomDialog();
  const {
    open: newOpen,
    handleClose: newHandleClose,
    handleOpen: newHandleOpen,
  } = useCustomDialog();

  const addGiftCardToWallet = useSelector(selectAddGiftCardToWallet);
  const purchaseNewGiftCard = useSelector(selectPurchaseNewGiftCard);

  return (
    <Box data-testid="GiftCardPanel.Box">
      {giftCards.length > 0 && (
        <Box mb={1}>
          <CardSelect
            cards={giftCards}
            value={selectedGiftCard}
            onChange={onChangeGiftCard}
            icon={<CardGiftcard fontSize="large" />}
          />
        </Box>
      )}
      {purchaseNewGiftCard && (
        <>
          <IncButton
            variant="text"
            fullWidth={false}
            data-testid="PaymentSelector.IncButton.PurchaseCard"
            onClick={newHandleOpen}
          >
            <u>{t('addPurchaseGiftCard.purchaseCardLinkAction')}</u>
          </IncButton>
          <PurchaseGiftCard open={newOpen} handleClose={newHandleClose} />
        </>
      )}
      {addGiftCardToWallet && (
        <>
          <IncButton
            variant="text"
            fullWidth={false}
            data-testid="PaymentSelector.IncButton.AddExistingGiftCardButton"
            onClick={existingHandleOpen}
          >
            <u>{t('addPurchaseGiftCard.addCardLinkAction')}</u>
          </IncButton>
          <AddExistingGiftCard
            open={existingOpen}
            handleClose={existingHandleClose}
          />
        </>
      )}
    </Box>
  );
};
export default GiftCardPanel;
