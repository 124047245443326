import { Box } from '@mui/material';
import { TabContext } from '@mui/lab';
import { PaymentOptionType } from '../payment.constants';
import CreditCardPanel from './credit-card-panel/credit-card-panel.component';
import GiftCardPanel from './gift-card-panel/gift-card-panel.component';
import GuestCardPanel from './guest-card-panel.component/guest-card-panel.component';
import GuestGiftCardPanel from './guest-gift-card-panel/guest-gift-card-panel.component';
import PaymentOptions from './payment-options/payment-options.component';
import * as S from './payment-selector.styles';
import HiddenTypography from 'components/hidden-typography/hidden-typography.component';
import { useTranslation } from 'react-i18next';

const PaymentSelector = ({
  paymentOptions = [],
  selectedPaymentOption,
  onChangePaymentOption,
  cards = [],
  selectedCard,
  onChangeCard,
  giftCards = [],
  selectedGiftCard,
  onChangeGiftCard,
}) => {
  const { t } = useTranslation();

  if (!selectedPaymentOption) return null;

  return (
    <Box data-testid="PaymentSelector.Box">
      <TabContext value={selectedPaymentOption}>
        <PaymentOptions
          options={paymentOptions}
          onChange={onChangePaymentOption}
        />
        <Box mt={1}>
          {paymentOptions[PaymentOptionType.CARD] && (
            <S.CustomTabPanel value={PaymentOptionType.CARD}>
              <CreditCardPanel
                cards={cards}
                selectedCard={selectedCard}
                onChangeCard={onChangeCard}
              />
            </S.CustomTabPanel>
          )}

          {paymentOptions[PaymentOptionType.GUEST_CARD] && (
            <S.CustomTabPanel value={PaymentOptionType.GUEST_CARD}>
              <GuestCardPanel />
            </S.CustomTabPanel>
          )}

          {paymentOptions[PaymentOptionType.GIFT_CARD] && (
            <S.CustomTabPanel value={PaymentOptionType.GIFT_CARD}>
              <GiftCardPanel
                giftCards={giftCards}
                selectedGiftCard={selectedGiftCard}
                onChangeGiftCard={onChangeGiftCard}
              />
            </S.CustomTabPanel>
          )}

          {paymentOptions[PaymentOptionType.GUEST_GIFT_CARD] && (
            <S.CustomTabPanel value={PaymentOptionType.GUEST_GIFT_CARD}>
              <GuestGiftCardPanel />
            </S.CustomTabPanel>
          )}

          {paymentOptions[PaymentOptionType.PAY_LATER] && (
            <S.CustomTabPanel value={PaymentOptionType.PAY_LATER}>
              <HiddenTypography>
                {t('paymentSelector.paymentOption.payLater.panel')}
              </HiddenTypography>
            </S.CustomTabPanel>
          )}

          {paymentOptions[PaymentOptionType.GOOGLE_PAY] && (
            <S.CustomTabPanel value={PaymentOptionType.GOOGLE_PAY}>
              <HiddenTypography>
                {t('paymentSelector.paymentOption.googlePay.panel')}
              </HiddenTypography>
            </S.CustomTabPanel>
          )}

          {paymentOptions[PaymentOptionType.APPLE_PAY] && (
            <S.CustomTabPanel value={PaymentOptionType.APPLE_PAY}>
              <HiddenTypography>
                {t('paymentSelector.paymentOption.applePay.panel')}
              </HiddenTypography>
            </S.CustomTabPanel>
          )}
        </Box>
      </TabContext>
    </Box>
  );
};

export default PaymentSelector;
