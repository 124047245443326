import { useController } from 'react-hook-form';
import AddressAutocomplete from '../address-autocomplete/address-autocomplete.component';
import { addressValidation } from '../address-autocomplete/address-autocomplete.utils';

const AddressAutocompleteField = ({
  name,
  defaultValue,
  autocompleteProps,
  autocompleteInputProps,
}) => {
  const {
    field: { ref, ...fieldRest },
    formState: { errors },
  } = useController({
    name,
    defaultValue,
    rules: addressValidation,
  });

  return (
    <AddressAutocomplete
      autocompleteProps={autocompleteProps}
      autocompleteInputProps={autocompleteInputProps}
      formErrorProps={
        !!errors[name] ? { errorMessage: errors[name].message } : {}
      }
      {...fieldRest}
    />
  );
};

export default AddressAutocompleteField;
