import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rover, useRoverState } from 'reakit';
import * as S from './order-type-tabs.styles';
import {
  getOrderTypePanelId,
  getOrderTypeTabId,
} from './order-type-tabs.utils';

const OrderTypeTabs = React.forwardRef(({ value, onChange, types }, ref) => {
  const { t } = useTranslation();
  const roverProps = useRoverState();

  return (
    <S.ButtonsGrid
      ref={ref}
      data-testid="OrderTypeSelector.CircleToggleGroup"
      aria-label={t('orderTypeSelector.ariaLabel')}
      role="tablist"
    >
      {types.map(({ label, type, ...rest }) => (
        <Box component="li" flex={1} key={type}>
          <Rover
            {...roverProps}
            as={S.OrderTypeButton}
            variant={value === type ? 'contained' : null}
            color="primary"
            fullWidth
            onClick={() => onChange(type)}
            id={getOrderTypeTabId(type)}
            role="tab"
            aria-controls={getOrderTypePanelId(type)}
            {...rest}
          >
            {label}
          </Rover>
        </Box>
      ))}
    </S.ButtonsGrid>
  );
});
export default OrderTypeTabs;
