import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import * as S from './card-item.styles';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import CardItemEdit from './card-item-edit/card-item-edit.component';
import CardItemGiftCard from './card-item-giftcard/card-item-giftcard.component';

const CardItem = ({ card, testId }) => {
  const { t } = useTranslation();
  const {
    image,
    nickname,
    cardType,
    lastFour,
    isDefault,
    paymentInstrumentId,
  } = card;
  const [editModal, setEditModal] = useState(false);
  const [giftModal, setGiftModal] = useState(false);

  const handleGiftModal = e => {
    if (e.target.className && !e.target.className.includes('editButton')) {
      setGiftModal(true);
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        data-testid={testId ? testId : 'CardItem'}
      >
        <S.InfoBox
          className={isDefault ? 'isDefault' : ''}
          onClick={
            cardType === 'Gift Card'
              ? handleGiftModal
              : () => setEditModal(true)
          }
        >
          <S.InfoTitle>
            <div>
              <S.PaymentIcon alt="" src={image} />
              <span>{nickname}</span>
            </div>
            <S.Edit
              className="editButton"
              onClick={() => setEditModal(true)}
              data-testid="CardItem.Edit"
            >
              {t('accountSettings.editAction')}
              <EditIcon />
            </S.Edit>
          </S.InfoTitle>
          <S.InfoText>
            {t('cardItem.cardInfo', { cardType, lastFour })}
          </S.InfoText>
        </S.InfoBox>
      </Grid>

      {editModal && (
        <CardItemEdit
          handleClose={() => setEditModal(false)}
          isDefault={isDefault}
          isOpen={editModal}
          nickname={nickname}
          lastFour={lastFour}
          paymentInstrumentId={paymentInstrumentId}
          testId="CardItem.CardItemEdit"
        />
      )}

      {giftModal && (
        <CardItemGiftCard
          isOpen={giftModal}
          paymentInstrumentId={paymentInstrumentId}
          handleClose={() => setGiftModal(false)}
        />
      )}
    </>
  );
};

export default CardItem;
