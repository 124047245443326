import React from 'react';
import { useSelector } from 'react-redux';
import { LoyaltyGridContainer } from '../../../account-loyalty.styles';
import * as S from './account-loyalty-custom-points.styles';
import { Grid } from '@mui/material';
import {
  selectLoyaltyPointImgs,
  selectLoyaltyPointDivisor,
} from '../../../../../redux/config/config.selectors';

const gridSize = 1;
const imagesPerRow = 12 / gridSize;
const numRows = 4;
const maxPointsDisplayed = imagesPerRow * numRows;
const lessPointsDisplayed = gridSize === 1 ? 2 : 1;

const AccountLoyaltyCustomPoints = ({ points }) => {
  const images = useSelector(selectLoyaltyPointImgs);
  const divisor = useSelector(selectLoyaltyPointDivisor);
  const unitPoints = points / divisor;

  const displayingPointsCount =
    unitPoints > maxPointsDisplayed
      ? maxPointsDisplayed - lessPointsDisplayed
      : unitPoints;
  const nonDisplayingPointsCount = unitPoints - displayingPointsCount;
  const hasMore = nonDisplayingPointsCount > 1;
  const showHalfPunch = !hasMore && points % divisor > 0;

  return (
    <>
      <LoyaltyGridContainer container spacing={1}>
        {Array.from({ length: displayingPointsCount }).map((v, i) => (
          <Grid key={`punch-img-${i}`} item xs={gridSize}>
            <S.LoyaltyPunchImg
              src={images[0]}
              data-testid="AccountLoyaltyCustomPoints.LoyaltyPunchImg.fullImage"
              alt=""
            />
          </Grid>
        ))}

        {showHalfPunch && images[1] && (
          <Grid key={`punch-img-remainder`} item xs={gridSize}>
            <S.LoyaltyPunchImg
              src={images[1]}
              data-testid="AccountLoyaltyCustomPoints.LoyaltyPunchImg.halfImage"
              alt=""
            />
          </Grid>
        )}

        {hasMore && (
          <Grid key={`hasMore`} item xs={gridSize * lessPointsDisplayed}>
            <S.HasMore>
              +{' '}
              <span data-testid="AccountLoyaltyCustomPoints.HasMore.Count">
                {Math.floor(nonDisplayingPointsCount)}
              </span>
            </S.HasMore>
          </Grid>
        )}
      </LoyaltyGridContainer>
    </>
  );
};

export default AccountLoyaltyCustomPoints;
