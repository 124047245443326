import { ListSubheader, Select } from '@mui/material';
import styled from 'styled-components';
import PlaceIcon from '@mui/icons-material/HomeWork';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export const CustomSelect = styled(Select)`
  && .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  ${({ hidden }) => hidden && `display: none;`}
`;

export const SavedAddressIcon = styled(PlaceIcon)`
  color: dodgerblue;
`;

export const BrowserLocationIcon = styled(MyLocationIcon)`
  color: darkcyan;
`;

export const Subheader = styled(ListSubheader)`
  &&.MuiListSubheader-root {
    pointer-events: none;
  }
`;
