import { useValidateSpreedly } from 'components/payment/spreedly/spreedly-payments.hooks';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PurchaseButton from '../purchase-button/purchase-button.component';
import SpreedlyContext from 'components/payment/spreedly/spreedly.context';

const GiftCardSpreedlyPurchaseButton = ({
  processOrder,
  disabled,
  cardInfo,
  paymentLoading,
  setPaymentLoading,
}) => {
  const validate = useValidateSpreedly();
  const { t } = useTranslation();

  window.Spreedly?.on('errors', function (_errors) {
    setPaymentLoading(false);
  });

  const onClick = () => {
    if (validate()) {
      setPaymentLoading(true);
      document.getElementById('payment-form').requestSubmit();
    } else {
      document.getElementById('errors').innerHTML = t(
        'checkout.payment.errors.requiredFields',
      );
    }
  };

  useEffect(() => {
    if (!cardInfo) return;
    processOrder(cardInfo.token);
    setPaymentLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo]);

  return (
    <PurchaseButton disabled={paymentLoading || disabled} onClick={onClick} />
  );
};

export const GiftCardSpreedlyPurchaseButton303942 = ({
  processOrder,
  disabled,
  cardInfo,
  paymentLoading,
  setPaymentLoading,
}) => {
  const { submitSpreedlyForm } = useContext(SpreedlyContext);

  const onClick = () => {
    submitSpreedlyForm();
  };

  useEffect(() => {
    if (!cardInfo) return;
    processOrder(cardInfo.token);
    setPaymentLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo]);

  return (
    <PurchaseButton disabled={paymentLoading || disabled} onClick={onClick} />
  );
};

export default GiftCardSpreedlyPurchaseButton;
