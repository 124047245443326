import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FirstName from 'components/form/form-fields/first-name.component';
import { FormProvider, useForm } from 'react-hook-form';
import Form from 'components/form/form.component';
import LastName from 'components/form/form-fields/last-name.component';
import InputPhone from 'components/input-phone/input-phone.component';
import IncButton from 'components/inc-button/inc-button.component';
import { useTranslation } from 'react-i18next';
import {
  selectFirstName,
  selectLastName,
  selectPhoneNumber,
  selectIsLoading,
  selectUserData,
} from 'redux/user/user.selectors';
import { updateUserData } from 'redux/user/user.actions';
import { Typography } from '@mui/material';
import Spinner from 'components/spinner/spinner.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const LoggedInUserMissingFields = ({ nextStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const phoneNumber = useSelector(selectPhoneNumber);
  const loading = useSelector(selectIsLoading);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async data => {
    const newUserData = {
      ...userData,
      ...data,
    };
    const response = await dispatch(updateUserData(newUserData));
    if (response?.data) nextStep();
  };

  if (loading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          aria-label={t('loggedInUserMissingFields.title')}
          gutterBottom
        >
          {t('loggedInUserMissingFields.title')}
        </Typography>

        <MandatoryFieldsInstruction />

        <FirstName
          register={register}
          errors={errors}
          defaultValue={firstName}
        />
        <LastName register={register} errors={errors} defaultValue={lastName} />
        <InputPhone defaultValue={phoneNumber} />

        <IncButton type="submit" fullWidth>
          {t('guest.label.continue')}
        </IncButton>
      </Form>
    </FormProvider>
  );
};

export default LoggedInUserMissingFields;
