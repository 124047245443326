import React from 'react';
import * as S from './store-title.styles';
import { Box } from '@mui/material';

const StoreTitle = ({
  fontSize = 1.125,
  name,
  shortDescription,
  component = 'h4',
}) => (
  <Box>
    <S.Name
      gutterBottom
      component={component}
      fontSize={fontSize}
      data-testid="StoreTitle.Name"
    >
      {name}
    </S.Name>
  </Box>
);

export default StoreTitle;
