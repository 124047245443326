import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';

import OrderFulfillTime from '../order-fulfill-time/order-fulfill-time.component';

const PlaceOrder = ({ handlePlaceOrder, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <IncButton
        onClick={handlePlaceOrder}
        data-testid="PlaceOrder.CustomButton"
        fullWidth
        {...rest}
      >
        {t('checkout.payment.paymentButtons.placeOrder')}
        <OrderFulfillTime />
      </IncButton>
    </Grid>
  );
};

export default PlaceOrder;
