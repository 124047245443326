import { Grid, TextField } from '@mui/material';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import CustomInput from 'components/custom-input/custom-input.component';
import Form from 'components/form/form.component';
import IncButton from 'components/inc-button/inc-button.component';
import { IncButtonDark } from 'components/inc-button/inc-button.styles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeleteAccountDialog } from './delete-account-dialog.hooks';
import * as S from './delete-account-dialog.styles';

const DeleteAccountDialog = ({ open, close }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    watch,
    register,
    formState: { isSubmitting },
  } = useForm();

  const { onSubmit } = useDeleteAccountDialog();

  const deleteConfirmText = watch('deleteConfirmText');

  const { ref, ...registerProps } = register('deleteConfirmText');

  return (
    <CustomDialog
      open={open}
      close={close}
      title={t('dialogs.deleteAccount.title')}
      testId="AccountSetings.DeleteAccountDialog"
    >
      <S.DescriptionOne id="delete-description-1">
        {t('dialogs.deleteAccount.description1')}
      </S.DescriptionOne>
      <S.DescriptionTwo id="delete-description-2">
        {t('dialogs.deleteAccount.description2')}
      </S.DescriptionTwo>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput>
          <TextField
            id="deleteConfirmText"
            label={t('dialogs.deleteAccount.input.placeholder')}
            fullWidth
            {...registerProps}
            inputRef={ref}
            inputProps={{
              'aria-labelledby':
                'delete-description-1 delete-description-2 deleteConfirmText',
            }}
          />
        </CustomInput>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <IncButtonDark
              onClick={close}
              data-testid="DeleteAccountDialog.CustomButton.Cancel"
              fullWidth
            >
              {t('dialogs.deleteAccount.cancelButton')}
            </IncButtonDark>
          </Grid>
          <Grid item xs={6}>
            <IncButton
              type="submit"
              className="inlineSubmitButton"
              data-testid="DeleteAccountDialog.CustomButton.Submit"
              fullWidth
              disabled={
                deleteConfirmText?.toLowerCase() !== 'delete' || isSubmitting
              }
            >
              {t('dialogs.deleteAccount.submitButton')}
            </IncButton>
          </Grid>
        </Grid>
      </Form>
    </CustomDialog>
  );
};

export default DeleteAccountDialog;
