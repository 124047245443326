import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { getEnv } from 'util/getEnv';
import {
  selectClientName,
  selectFavicon,
  selectgAPI,
  selectShouldLoadRecaptcha,
} from '../../redux/config/config.selectors';
import { useTranslation } from 'react-i18next';
import { selectors as clientSelectors } from '../../redux/client/client.slice';
import location from 'util/location';

const Head = () => {
  const { t } = useTranslation();
  const gApi = useSelector(selectgAPI);
  const favicon = useSelector(selectFavicon);
  const shouldLoadRecaptcha = useSelector(selectShouldLoadRecaptcha);
  const clientName = useSelector(selectClientName);
  const shortDescription = useSelector(clientSelectors.selectShortDescription);

  return (
    <Helmet
      title={`${clientName} | ${t('head.title.main')}`}
      meta={[
        {
          name: 'description',
          content:
            shortDescription || t('metaTags.description', { clientName }),
        },
        getEnv('NODE_ENV') !== 'production'
          ? {
              name: 'robots',
              content: 'noindex',
            }
          : {},
      ]}
    >
      {(gApi || gApi === '') && (
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${gApi}&libraries=places&callback=initMap&v=3.51`}
          async
          defer
        ></script>
      )}
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="icon"
        id="favicon"
        href={favicon ?? `${location.getLocationOrigin()}/favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        id="apple-touch-icon"
        href={favicon ?? `${location.getLocationOrigin()}/logo192.png`}
      />
      <script crossorigin src={process.env.REACT_APP_SQUARE_URL} onLoa></script>
      {shouldLoadRecaptcha && (
        <script
          src={`https://www.google.com/recaptcha/enterprise.js?onload=onRecaptchaLoad&render=${getEnv(
            'REACT_APP_RECAPTCHA_KEY',
          )}`}
          async
          defer
        ></script>
      )}
    </Helmet>
  );
};
export default React.memo(Head);
