import { useMemo, useState } from 'react';
import InfoText from '../info-text/info-text.component';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Zoom,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CustomInput from 'components/custom-input/custom-input.component';
import { useTranslation } from 'react-i18next';

const SingleSelect = ({
  info,
  userDietaryPreferences,
  name,
  testIdPrefix = '',
}) => {
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
    MenuListProps: {
      'data-testid': `${testIdPrefix}.Menu`,
      'aria-label': info.infoText,
    },
  };
  const defaultValue = useMemo(
    () =>
      info.values?.find(val => val.toLowerCase() === userDietaryPreferences) ??
      userDietaryPreferences ??
      '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { control } = useFormContext();
  const { t } = useTranslation();
  const [showHelperText, setShowHelperText] = useState(false);

  return (
    <CustomInput>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth>
            <InputLabel id={`${testIdPrefix}.Label`}>{info.label}</InputLabel>
            <Select
              inputProps={{ className: 'single' }}
              labelId={`${testIdPrefix}.Label`}
              id={`${testIdPrefix}.Select`}
              value={value}
              onChange={e => {
                onChange(e.target.value);
                setShowHelperText(!!e.target.value);
              }}
              SelectDisplayProps={{
                'data-testid': `${testIdPrefix}.Select`,
              }}
              MenuProps={MenuProps}
              renderValue={val => val}
            >
              <InfoText text={info.infoText} />
              <MenuItem dense value="">
                <em>{t('dietaryPreferences.singleSelect.none')}</em>
              </MenuItem>
              {info.values?.map(value => (
                <MenuItem dense key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {showHelperText && (
              <Zoom in={true} timeout={{ enter: 800 }}>
                <FormHelperText data-testid={`${testIdPrefix}.Info`}>
                  {info.infoText}
                </FormHelperText>
              </Zoom>
            )}
          </FormControl>
        )}
      />
    </CustomInput>
  );
};

export default SingleSelect;
