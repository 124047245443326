import React from 'react';
import RenderCode from 'components/render-code/render-code.component';
import { useSelector } from 'react-redux';
import { selectHideLoyaltyScanCode } from 'redux/config/config.selectors';
import { Box } from '@mui/material';

const AccountLoyaltyCardRenderCode = ({ externalId, loyaltyCardCodeType }) => {
  const hideLoyaltyScanCode = useSelector(selectHideLoyaltyScanCode);

  if (!externalId || hideLoyaltyScanCode) return null;

  return (
    <Box mb="2em">
      <RenderCode
        code={externalId}
        codeFormat={loyaltyCardCodeType}
        displayValue={false}
      />
    </Box>
  );
};

export default AccountLoyaltyCardRenderCode;
