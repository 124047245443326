import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const CheckboxWrapper = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    font-size: 0.875rem;
    margin-left: ${({ theme }) => theme.spacing(0)};
    margin-top: ${({ theme }) => theme.spacing(-2)};
  }
`;
