import { Autoplay, FreeMode } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import * as S from './item-carousel.styles';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';

const ItemCarousel = ({ items, renderItem }) => {
  return (
    <S.ItemSwiper
      modules={[Autoplay, FreeMode]}
      slidesPerView="auto"
      spaceBetween={15}
      loop={true}
      loopedSlides={items.length}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
      }}
      freeMode={{
        enabled: true,
        momentumBounce: false,
        momentumRatio: 0.5,
        momentumVelocityRatio: 0.5,
      }}
    >
      {items.map(item => (
        <SwiperSlide key={item.item.itemId}>{renderItem(item)}</SwiperSlide>
      ))}
    </S.ItemSwiper>
  );
};

export default ItemCarousel;
