import React from 'react';
import * as S from './option-group.styles';
import OptionItem from './option-item/option-item.component';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { getError, hasError } from './option-group.utils';
import { useOptionGroup } from './option-group.hooks';

const OptionGroup = ({
  group,
  editOptions,
  nested,
  parentIds,
  testId = 'OptionGroup',
  formKey,
  isItemEdited,
  parentName,
  isParentAndEditFlow,
  ...rest
}) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const selections = watch(`options.${formKey}`, []) ?? [];

  const {
    displayInfo,
    selectionMandatory,
    helperTextKey,
    itemsSortedByAvailability,
    maxItemSelections,
    minItemSelections,
    groupId,
    editingOptionItem,
  } = useOptionGroup(group, editOptions);

  return (
    <S.Container nested={nested} data-testid={testId} name={formKey}>
      <S.OptionTitle className="optionTitle" component="h3">
        {displayInfo[0].title}
        {selectionMandatory && '*'}
        <S.HelperText>{helperTextKey}</S.HelperText>
      </S.OptionTitle>
      {hasError(errors, formKey) && (
        <S.Error>{getError(errors, formKey)}</S.Error>
      )}
      <S.Group row>
        <Box>
          {itemsSortedByAvailability
            .map(item => ({ ...item, formKey }))
            .map((optionItem, i) => {
              return (
                <div key={optionItem.itemId}>
                  <OptionItem
                    maxItemSelections={maxItemSelections}
                    minItemSelections={minItemSelections}
                    group={group}
                    item={optionItem}
                    parentIds={parentIds}
                    groupFormKey={formKey}
                    editOptions={editOptions?.length ? editOptions : null}
                    isParentEdited={isItemEdited}
                    data={selections}
                    parentName={parentName}
                    identifier={`${formKey}-i${i}`}
                    isParentAndEditFlow={isParentAndEditFlow}
                    {...rest}
                  />

                  {optionItem.optionGroups.length > 0 &&
                  selections.find(item => optionItem.itemId === item.itemId)
                    ? optionItem.optionGroups.map(
                        (group, index) =>
                          group.items.length > 0 && (
                            <OptionGroup
                              nested="true"
                              parentIds={[
                                ...(parentIds || []),
                                {
                                  itemId: optionItem.itemId,
                                  groupId: groupId,
                                },
                              ]}
                              key={group.groupId}
                              group={group}
                              formKey={`${formKey}-i${i}-g${index}`}
                              editOptions={
                                editingOptionItem(optionItem.itemId)?.options ??
                                null
                              }
                              isItemEdited={
                                !!editingOptionItem(optionItem.itemId)
                              }
                              parentName={`${formKey}-i${i}`}
                              {...rest}
                            />
                          ),
                      )
                    : null}
                </div>
              );
            })}
        </Box>
      </S.Group>
    </S.Container>
  );
};

export default OptionGroup;
