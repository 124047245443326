import { Box } from '@mui/material';
import React from 'react';
import Theme from 'Theme';
import { useViewOfferInbox } from '../view-offer-inbox/view-offer-inbox.hooks';
import AccountInboxListView from 'pages/account-inbox/account-inbox-list-view/account-inbox-list-view.component';

const ViewOffersList = () => {
  const props = useViewOfferInbox();

  return (
    <Theme>
      <Box data-testid="ViewOffers.List">
        <AccountInboxListView {...props} disableOnClick />
      </Box>
    </Theme>
  );
};

export default ViewOffersList;
