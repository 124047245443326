import { Box } from '@mui/material';
import { useIncentivioPaymentsPlaceOrder } from 'components/payment/incentivio/incentivio-payments.hooks';
import WalletButton from 'components/payment/wallet-button/wallet-button.component';
import PlaceOrder from 'pages/checkout/checkout-buttons/place-order/place-order.component';
import { useNewCheckoutButtons } from '../../checkout-buttons-new.hooks';

const IncentivioCheckoutButtons = ({ insufficientModalSetOpen }) => {
  const { trigger } = useIncentivioPaymentsPlaceOrder();

  const { showPlaceOrder, handlePlaceOrder, loading, validate } =
    useNewCheckoutButtons(trigger, insufficientModalSetOpen);

  return (
    <Box data-testid="CheckoutButtons.Grid">
      {showPlaceOrder && (
        <PlaceOrder handlePlaceOrder={handlePlaceOrder} disabled={loading} />
      )}
      {!showPlaceOrder && <WalletButton validate={validate} />}
    </Box>
  );
};

export default IncentivioCheckoutButtons;
