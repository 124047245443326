import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VerifyEmailErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Typography component="h2" aria-label={t('verifyEmail.error')}>
      {t('verifyEmail.error')}
    </Typography>
  );
};

export default VerifyEmailErrorMessage;
