import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Title = styled(Typography)`
  font-size: 2rem;
  margin-bottom: 1rem 0;
  text-align: center;
`;

export const SubHead = styled(Typography)`
  font-size: 1.125rem;
  margin-bottom: ${({ issmallmargin }) => (!!issmallmargin ? '1em' : '2em')};
  text-align: center;
`;

export const Description = styled(Box)`
  font-size: 0.875rem;
  margin-bottom: 2em;
  color: ${({ theme }) => theme.palette.text.primaryLight};
  text-align: center;
`;

export const Body = styled(Typography)`
  font-size: 1.125rem;
  margin-bottom: 1em;
  text-align: center;
`;
