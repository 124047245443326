import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getLanguageKey } from 'utils';
import { store } from '../redux/store';
import { refreshToken, signOut } from '../redux/user/user.actions';
import {
  selectAccessToken,
  selectRememberMe,
} from '../redux/user/user.selectors';
import { getClientId } from '../util/clientId';
import { getEnv } from '../util/getEnv';
import {
  recaptchaInterceptor,
  recaptchaInterceptorRunWhen,
} from './recaptcha-interceptor';

const INC_BASE_API = axios.create({
  baseURL: getEnv('REACT_APP_MOBILE_API_URL'),
  timeout: 90000,
});

const excludedUrls = ['useraccounts/updatepassword', 'oauth/token'];
const isExcludedUrl = failedRequest =>
  excludedUrls.some(url => failedRequest.config.url.indexOf(url) !== -1);

createAuthRefreshInterceptor(INC_BASE_API, failedRequest => {
  if (!isExcludedUrl(failedRequest) && selectRememberMe(store.getState())) {
    return store.dispatch(refreshToken(failedRequest));
  } else {
    if (failedRequest.config?.authErrorHandler) {
      failedRequest.config.authErrorHandler(failedRequest);
    } else {
      store.dispatch(signOut());
    }
    return Promise.reject(failedRequest);
  }
});

INC_BASE_API.interceptors.request.use(request => {
  if (
    request.authenticated &&
    !(
      request.headers.Authorization &&
      request.headers.Authorization.startsWith('Basic')
    )
  ) {
    const accessToken = selectAccessToken(store.getState());
    if (accessToken) request.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const clientId = getClientId();
  if (clientId) {
    request.headers.CLIENTID = clientId;
  }

  if (window.location.pathname.startsWith('/test'))
    request.headers['inc-is-test'] = 'true';

  request.headers['inc-device'] = 'WEB';
  request.headers['inc-user-language'] = getLanguageKey().toUpperCase();

  return request;
});

INC_BASE_API.interceptors.request.use(recaptchaInterceptor, null, {
  runWhen: recaptchaInterceptorRunWhen,
});

export default INC_BASE_API;
