import { configureTestMaps } from 'App.utils';
import PaymentsContainer from 'pages/payments/payments-container.component';
import SocialShareContainer from 'pages/social-share/social-share.container';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectgAPI } from 'redux/config/config.selectors';
import Spinner from './components/spinner/spinner.component';
import usei18nListener from './hooks/usei18nListener';
import ClientContainer from './pages/client/client.container';
import { setIsGoogleMapsLoaded } from './redux/config/config.actions';
import i18nSlice from './redux/i18n/i18n.slice';
import ViewOffersList from 'pages/view-offers/view-offers-list/view-offers-list.component';
import ViewOfferInbox from 'pages/view-offers/view-offer-inbox/view-offer-inbox.component';
import ViewMessagesList from 'pages/view-messages/view-messages-list/view-messages-list.component';
import ViewMessageInbox from 'pages/view-messages/view-message-inbox/view-message-inbox.component';
import { useUncaughtResizeError } from 'hooks/useUncaughtResizeError';

function App() {
  const dispatch = useDispatch();
  const gApi = useSelector(selectgAPI);
  usei18nListener('languageChanged', lng => {
    dispatch(i18nSlice.actions.localeChanged(lng));
  });

  useUncaughtResizeError();

  useEffect(() => {
    window.initMap = () => {
      configureTestMaps(gApi);
      dispatch(setIsGoogleMapsLoaded(true));
    };
  }, [dispatch, gApi]);

  return (
    <Suspense fallback={<Spinner />}>
      <ToastContainer role="alert" className="App-ToastContainer" />
      <BrowserRouter>
        <Switch>
          <Route exact path="/socialshare/fb">
            <SocialShareContainer />
          </Route>
          <Route exact path="/offers/offer">
            <ViewOfferInbox />
          </Route>
          <Route exact path="/messages/message">
            <ViewMessageInbox />
          </Route>
          <Route exact path="/offers/list">
            <ViewOffersList />
          </Route>
          <Route exact path="/messages/list">
            <ViewMessagesList />
          </Route>
          <Route exact path="/payments/threeds2verification">
            <PaymentsContainer />
          </Route>
          <Route path={['/', '/test']}>
            <ClientContainer />
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
