import { Box } from '@mui/material';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectRequestedStoreGoogleMapsQueryUrl } from 'redux/common.selectors';
import { selectRequestedStore } from 'redux/locations/locations.selectors';
import CheckoutSection from '../checkout-section/checkout-section.component';
import { MapsLink } from '../checkout.styles';
import * as S from './checkout-location-section.styles';

const CheckoutLocationSection = ({ ...rest }) => {
  const store = useSelector(selectRequestedStore);
  const { smallImage, mediumImage, title, shortDescription } = useDisplayInfo(
    store?.displayInfo,
  );
  const storeMapLink = useSelector(selectRequestedStoreGoogleMapsQueryUrl);

  return (
    <CheckoutSection
      p={0}
      data-testid="CheckoutLocationSection.CheckoutSection"
      {...rest}
    >
      <Box display="flex">
        {(!!smallImage || !!mediumImage) && (
          <Box width="70px" minWidth="70px" height="70px">
            <S.Image
              src={smallImage ?? mediumImage}
              alt=""
              data-testid="CheckoutLocationSection.Image"
            />
          </Box>
        )}
        <Box
          pl={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minWidth="0"
          height="70px"
        >
          <MapsLink
            href={storeMapLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Map for ${title}`}
            title={title}
            data-testid="CheckoutLocationSection.MapsLink"
          >
            <S.StoreName>{title}</S.StoreName>
          </MapsLink>
          <S.Description data-testid="CheckoutLocationSection.Description">
            {shortDescription}
          </S.Description>
        </Box>
      </Box>
    </CheckoutSection>
  );
};

export default CheckoutLocationSection;
