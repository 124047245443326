import OrderTypeSelector from 'components/new-order/order-type-selector/order-type-selector.component';
import OrderTypeTabsPanel from 'components/new-order/order-type-tabs/order-type-tabs-panel/order-type-tabs-panel.component';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useIsViewOnlyLocationEnabled } from 'hooks/useIsViewOnlyLocationEnabled';
import {
  useForceOrderType,
  useLocationsToggleButtons,
} from 'pages/store/new-order-form/useLocationsToggleButtons.hooks';
import useResizeObserver from 'use-resize-observer/polyfilled';
import LocationsFinderList from '../locations-list/locations-list.component';
import LocationsSearch from '../locations-search/locations-search';
import ForceOrderTypeSelectPanel from './forcer-order-type-select-panel/force-order-type-select-panel.component';
import LocationsAddressControls from './locations-address-controls/locations-address-controls.component';
import { useSetDefaultOrderType } from './locations-finder.hooks';
import * as S from './locations-finder.styles';
import { CATERING, DELIVERY, PICKUP } from 'util/constants';

const LocationsFinder = ({
  headerRef,
  selectedStore,
  setSelectedStore,
  orderType,
}) => {
  const { fieldNames } = useFieldNames();
  const viewOnlyLocationEnabled = useIsViewOnlyLocationEnabled();
  const {
    selectedForceOrderType,
    setSelectedForceOrderType,
    showForceOrderTypeControls,
  } = useForceOrderType();

  const { orderTabs, defaultTab } = useLocationsToggleButtons(
    orderType,
    setSelectedStore,
  );
  const { ref, height } = useResizeObserver({ box: 'border-box' });

  useSetDefaultOrderType(defaultTab);

  if (showForceOrderTypeControls)
    return (
      <ForceOrderTypeSelectPanel
        selectedForceOrderType={selectedForceOrderType}
        setSelectedForceOrderType={setSelectedForceOrderType}
        types={orderTabs}
      />
    );

  return (
    <S.Container data-testid="LocationsFinder.Container">
      <S.StickyContainer ref={ref}>
        <OrderTypeSelector
          types={orderTabs}
          defaultValue={defaultTab}
          name={fieldNames.orderType}
        />
      </S.StickyContainer>
      <S.ListContainer id="locationsListContainer" mt={`${height}px`}>
        <OrderTypeTabsPanel value={PICKUP} orderTabs={orderTabs}>
          <LocationsSearch />
          <LocationsFinderList
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
          />
        </OrderTypeTabsPanel>

        <OrderTypeTabsPanel value={DELIVERY} orderTabs={orderTabs}>
          {viewOnlyLocationEnabled ? (
            <LocationsSearch />
          ) : (
            <LocationsAddressControls
              orderType={orderType}
              headerRef={headerRef}
            />
          )}
          <LocationsFinderList
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
          />
        </OrderTypeTabsPanel>

        <OrderTypeTabsPanel value={CATERING} orderTabs={orderTabs}>
          {viewOnlyLocationEnabled ? (
            <LocationsSearch />
          ) : (
            <LocationsAddressControls
              orderType={orderType}
              headerRef={headerRef}
            />
          )}
          <LocationsFinderList
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
          />
        </OrderTypeTabsPanel>
      </S.ListContainer>
    </S.Container>
  );
};

export default LocationsFinder;
