import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectSlug } from 'redux/cart/cart.selectors';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumbs.component';
import { selectRequestedStoreDITitle } from '../../../redux/locations/locations.selectors';

const MenuBreadcrumbs = () => {
  const { t } = useTranslation();
  const title = useSelector(selectRequestedStoreDITitle);
  const slug = useSelector(selectSlug);

  return (
    <Breadcrumbs maxWidth="xl" aria-label="Breadcrumbs">
      <Link to="/" component={RouterLink} underline="hover">
        {t('breadcrumbs.locations')}
      </Link>
      <Link to={`/locations/${slug}`} component={RouterLink} underline="hover">
        {title}
      </Link>
      <Typography>{t('breadcrumbs.menu')}</Typography>
    </Breadcrumbs>
  );
};

export default MenuBreadcrumbs;
