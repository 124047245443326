import { useTranslation } from 'react-i18next';
import * as S from './account-inbox-list-view.styles';
import { Box } from '@mui/material';
import Messages from '../messages/messages.component';

const AccountInboxListView = ({
  loading,
  containerAriaAttributes,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <S.CustomContainer
      maxWidth="md"
      minheight={loading ? '100vh' : null}
      data-testid="AccountInbox.AccountContainer"
      {...containerAriaAttributes}
    >
      <Box>
        <h1>{t('accountInbox.title')}</h1>
      </Box>
      <Messages {...rest} loading={loading} />
    </S.CustomContainer>
  );
};

export default AccountInboxListView;
