import styled from 'styled-components';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const Container = styled(Breadcrumbs)`
  padding: 0.5em 0;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};

  .MuiBreadcrumbs-li {
    font-weight: bold;
    position: relative;
    font-size: 0.875rem;
    z-index: 2;
  }

  .MuiBreadcrumbs-separator,
  .MuiBreadcrumbs-li {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  a,
  p {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
