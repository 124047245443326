import { InputLabel } from '@mui/material';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  selectCustomizationChars,
  selectItemInstructionsLabel,
  selectItemInstructionsWatermarkText,
  selectShowItemInstructions,
} from 'redux/config/config.selectors';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';
import * as S from './item-instructions.styles';

const ItemInstructions = ({ editInfo }) => {
  const { register } = useFormContext();
  const showItemInstructions = useSelector(selectShowItemInstructions);

  const itemInstructionsLabel = useSelector(selectItemInstructionsLabel);
  const customizationChars = useSelector(selectCustomizationChars);
  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());

  const itemInstructionsWatermarkText = useSelector(
    selectItemInstructionsWatermarkText,
  );

  if (!showItemInstructions) return null;

  return (
    <S.Container>
      <InputLabel htmlFor="itemInstructions">
        {itemInstructionsLabel}
      </InputLabel>

      <TrimTextField
        id="itemInstructions"
        fullWidth
        defaultValue={editInfo ? editInfo.itemInstructions : ''}
        {...register('itemInstructions')}
        inputProps={{ maxLength: customizationChars ? customizationChars : 50 }}
        placeholder={itemInstructionsWatermarkText}
        disabled={isMenuBrowsingStore}
      />
    </S.Container>
  );
};

export default ItemInstructions;
