import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import * as S from './back-action.styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

export const BackAction = ({
  handleBack,
  label,
  testId = 'BackAction.BackButton',
  'aria-label': ariaLabel,
}) => {
  const { t } = useTranslation();
  return (
    <Box mb={1}>
      <S.BackButton
        color="inherit"
        data-testid={testId}
        className="back-button"
        onClick={handleBack}
        aria-label={ariaLabel}
      >
        <ArrowBackIcon />
        {label ? label : t('actions.back')}
      </S.BackButton>
    </Box>
  );
};

export default BackAction;
