import { Box, Link } from '@mui/material';
import React from 'react';
import { Span, TermsAndConditionsLink, Text } from '../toc-and-privacy.styles';
import { useTranslation } from 'react-i18next';

const TocAndPrivacySettingsPage = ({ handleTocOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Text variant="body2">
        {t('register.privacyPolicy.settings.label')}{' '}
        <Link
          href="https://incentivio.com/privacy"
          target="_blank"
          data-testid="TocAndPrivacy.PrivacyLink"
          aria-label={t('register.privacyPolicy.ariaLabel')}
          underline="hover"
        >
          <Span>{t('register.privacyPolicy.settings.linkText')}</Span>
        </Link>
      </Text>
      <Box mt={1} />
      <Text variant="body2">
        {t('register.termsAndConditions.settings.label')}{' '}
        <TermsAndConditionsLink
          component="button"
          type="button"
          onClick={handleTocOpen}
          data-testid="TocAndPrivacy.TermsAndConditionsLink"
        >
          <Span>{t('register.termsAndConditions.settings.linkText')}</Span>
        </TermsAndConditionsLink>
      </Text>
    </>
  );
};

export default TocAndPrivacySettingsPage;
