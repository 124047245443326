import { Tab } from '@mui/material';
import { TabList, TabPanel } from '@mui/lab';
import styled from 'styled-components';

export const StyledTabPanel = styled(TabPanel)`
  padding: 15px 0 0 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0 0 0;
  }
`;

export const StyledTabList = styled(TabList)`
  width: 100%;

  .MuiTabs-indicator {
    width: ${({ $isTodayOff }) => ($isTodayOff ? '100%' : 'inherit')};
  }

  .MuiTabs-flexContainer {
    justify-content: ${({ $isTodayOff }) =>
      $isTodayOff ? 'center' : 'inherit'};
  }
`;

export const StyledTab = styled(Tab)`
  width: ${({ $isTodayOff }) => ($isTodayOff ? 'inherit' : '50%')};
`;
