import { Grid } from '@mui/material';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import { CustomLink } from 'components/flow/step-header/step-header.styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailImage, ItemDetail } from '../menu-modal.styles';

const NutritionalInfoLink = ({ extendedAttributes }) => {
  const { t } = useTranslation();
  const [nutritionalInfoOpen, setNutritionalInfoOpen] = useState(false);

  if (
    extendedAttributes &&
    Object.entries(extendedAttributes).length > 0 &&
    Object.keys(extendedAttributes)[0] === 'nutritional_info'
  )
    return (
      <ItemDetail mb={2}>
        <CustomLink
          onClick={() => setNutritionalInfoOpen(true)}
          data-testid="CatalogModal.CustomLink.NutritionalInfo"
        >
          {t('catalog.modal.nutritionalInfoLabel')}
        </CustomLink>

        <CustomDialog
          open={nutritionalInfoOpen}
          close={() => setNutritionalInfoOpen(false)}
        >
          <Grid container spacing={2}>
            {Object.entries(extendedAttributes).map((image, index) => {
              if (image[0] !== 'nutritional_info') return null;
              return (
                <Grid key={`${image[0]}-${index}`} item xs>
                  <DetailImage src={image[1]} alt="" />
                </Grid>
              );
            })}
          </Grid>
        </CustomDialog>
      </ItemDetail>
    );

  return null;
};

export default NutritionalInfoLink;
