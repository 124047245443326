import { Box, darken, Tab } from '@mui/material';
import styled from 'styled-components';
import mediaHelper from 'styled-media-helper';

const width = 160;
const smallWidth = 100;

const smallBreakpoints = {};
let i = 1.5;
while (smallWidth * i < 600) {
  smallBreakpoints[i] = smallWidth * i;
  i += 1;
}
smallBreakpoints['last'] = 600;

const media = mediaHelper(smallBreakpoints);

export const CustomTab = styled(Tab)`
  padding: 0px 0.26px;
  height: auto;
  justify-content: space-between;

  > div:last-child {
    opacity: 0.7;
  }

  &.MuiTab-root {
    align-items: center;
    width: ${width}px;

    ${({ scroll }) => scroll && `height: 100%;`}

    ${Object.entries(smallBreakpoints)
      .map(([key]) =>
        key !== 'last'
          ? `
            ${media.up(key)} {
              width: calc(100vw / ${key});
            }
          `
          : `
            ${media.up(key)} {
              width: ${width}px;
            }
          `,
      )
      .join('\n')}
  }

  &.MuiTab-textColorInherit {
    opacity: 1;
  }

  &.Mui-selected {
    font-weight: bold;
    color: inherit;

    padding: 0px;

    > div:last-child {
      opacity: 1;
    }
  }

  &:hover > *:first-child {
    border-color: ${darken('#ddd', 0.2)};
  }

  &:hover.Mui-selected > *:first-child {
    border-color: ${({ theme }) => theme.palette.primary.light};
  }

  &.MuiTab-labelIcon.Mui-selected > *:first-child {
    margin: 0px auto 14px auto;
  }

  &.MuiTab-labelIcon > *:first-child {
    margin: 2px 2px 16px 2px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiTab-labelIcon > *:first-child {
      margin: 1px 1px 16px 1px;
    }

    &.MuiTab-root {
      padding: 6px;
    }
  }
`;

export const LabelContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  transition: flex 400ms;
  word-break: break-word;
  margin-bottom: 8px;
`;
