import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styled, { css } from 'styled-components';

const activeStyles = css`
  overflow: hidden;

  .MuiToggleButton-label {
    line-height: normal;
  }

  &.Mui-selected {
    border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};

    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }

  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export const TimeToggleGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-direction: column;
`;

export const TimeToggleButton = styled(ToggleButton)`
  && {
    padding: 0;
    border-radius: 100px;
    background: transparent;
    margin: 0.5em 0;
    height: 40px;
    width: 100%;
    color: #252525;
    font-size: 1rem;
    border: 1px solid #252525;
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

    ${({ theme }) =>
      theme.palette.type === 'dark' &&
      `
    color: ${theme.palette.text.primary};
    border-color: ${theme.palette.text.primary};
  `}

    ${activeStyles}
  }
`;
