import { Box } from '@mui/material';
import styled from 'styled-components';

export const StickyContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: calc(
      100vh - ${({ theme }) => theme.spacing(2)} -
        ${({ theme }) => theme.navHeight} - 10px
    );
  }
`;

export const StickyScroll = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
