import { Box } from '@mui/material';
import styled from 'styled-components';

export const Date = styled(Box)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primaryLight};
  font-size: 0.875rem;
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;
