import { IconButton, SwipeableDrawer } from '@mui/material';
import HorizontalLogo from 'components/horizontal-logo/horizontal-logo.component';
import { useTranslation } from 'react-i18next';
import * as S from './header-drawer.styles';

const HeaderDrawer = ({ children, isLight, onClose, ...rest }) => {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer
      anchor="right"
      onClose={onClose}
      PaperProps={{ 'data-testid': 'Drawer.SwipeableDrawer' }}
      ModalProps={{
        keepMounted: false,
      }}
      {...rest}
    >
      <S.InnerContainer className={isLight ? 'light' : null}>
        <S.Header>
          <HorizontalLogo />
          <IconButton
            onClick={onClose}
            aria-label={t('drawer.closeIcon.ariaLabel')}
            data-testid="Drawer.IconButton.Close"
          >
            <S.Close />
          </IconButton>
        </S.Header>
        {children}
      </S.InnerContainer>
    </SwipeableDrawer>
  );
};

export default HeaderDrawer;
