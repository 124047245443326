import styled from 'styled-components';
import { darken } from '@mui/material';

export const Container = styled.div`
  form {
    width: 100%;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  h2 {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  ${({ theme }) =>
    theme.palette.type === 'dark' &&
    `
    select {
      background-color: white;
      color: black;
    }

    input#card_number {
      background-color: white;
      color: black;
    }
  `}

  .spf-button {
    background: ${({ theme }) => theme.palette.primary.main} !important;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    height: 40px;
    border-radius: 100px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    padding: 0 1em;
    width: 100%;
    text-transform: capitalize;

    &:hover {
      background: ${({ theme }) =>
        darken(theme.palette.primary.main, 0.1)} !important;
      border: 1px solid
        ${({ theme }) => darken(theme.palette.primary.main, 0.1)};
    }
  }
`;
