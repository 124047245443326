import { useCustomDialog } from 'components/custom-dialog/custom-dialog.hooks';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { selectLocationId } from 'redux/cart/cart.selectors';
import { selectRequestedFulfillTimeAvailabilityObject } from 'redux/data/orderavailability/orderavailability.selectors';
import { Box } from '@mui/material';
import OrderTypeIcon from 'components/order-type-icon/order-type-icon.component';
import OrderDateTime from 'components/order-date-time/order-date-time.component';
import * as S from '../header.styles';
import RescheduleOrderModal from './reschedule-order-modal/reschedule-order-modal.component';
import { makeSelectIsMenuBrowsingStore } from 'redux/locations/locations.selectors';

const RescheduleOrderController = () => {
  const { orderTypeLabel } = useOrderTypeLabels();
  const { open, handleClose, handleOpen } = useCustomDialog();

  const storeId = useSelector(selectLocationId);
  const rftao = useSelector(state =>
    selectRequestedFulfillTimeAvailabilityObject(state, storeId),
  );

  const isMenuBrowsingStore = useSelector(makeSelectIsMenuBrowsingStore());

  if (!rftao || isMenuBrowsingStore) return null;
  const ariaControlsValue = open ? 'reschedule-order' : '';
  return (
    <>
      <S.ScheduleToggler
        aria-controls={ariaControlsValue}
        aria-haspopup="true"
        handleToggle={handleOpen}
        toggleTestId="Header.TogglerButton.RescheduleOrderController"
      >
        <Box display="flex" marginRight="20px" alignItems="center">
          <OrderTypeIcon />
          <S.CatalogOptionText>{orderTypeLabel}</S.CatalogOptionText>
        </Box>
        <QueryBuilderIcon />
        <S.CatalogOptionText>
          <OrderDateTime />
        </S.CatalogOptionText>
      </S.ScheduleToggler>

      <RescheduleOrderModal open={open} handleClose={handleClose} />
    </>
  );
};

export default RescheduleOrderController;
