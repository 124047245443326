import { SvgIcon } from '@mui/material';
import React from 'react';

const Certificate = ({ ...rest }) => {
  return (
    <SvgIcon {...rest}>
      <path d="M 23,12 20.56,9.22 20.9,5.54 17.29,4.72 15.4,1.54 12,3 8.6,1.54 6.71,4.72 3.1,5.53 3.44,9.21 1,12 3.44,14.78 3.1,18.47 6.71,19.29 8.6,22.47 12,21 l 3.4,1.46 1.89,-3.18 3.61,-0.82 -0.34,-3.68 z" />
    </SvgIcon>
  );
};

export default Certificate;
