import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { PICKUP } from 'util/constants';
import { actions } from 'redux/data/orderavailability/orderavailability.slice';
import SectionContext from 'pages/checkout/checkout-section/checkout-section.context';

import { useNewOrderForm } from 'pages/store/new-order-form/useNewOrderForm.hooks';
import { DateTime } from 'luxon';

export const useScheduleDatePicker = (scheduleProps, datePickerChange) => {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const { fieldNames } = useFieldNames();
  const [pickerDate, setPickerDate] = useState();

  const {
    offDays,
    orderType,
    store: { storeId, pickupMaxDaysOut, deliveryMaxDaysOut },
    setShowCalendar,
    showCalendar,
  } = scheduleProps;

  useEffect(() => {
    setShowCalendar(true);
  }, [orderType, setShowCalendar]);

  useHandleCheckoutSectionButtons(showCalendar, storeId);

  const fetchOrderTimes = () => {
    dispatch(
      actions.fetchOrderAvailability({
        requestedOrderType: orderType,
        storeId,
        selectedDay: getValues(fieldNames.day).toISODate(),
        address: getValues(fieldNames.address),
      }),
    );
  };

  const getNextEnableNoOfDays = (direction, days = 0) => {
    let theDays = days;
    if (pickerDate.plus({ days: theDays }) === maxDate) return theDays;

    if (direction === 'RIGHT') theDays = days + 1;
    else theDays = days - 1;

    if (offDays.includes(pickerDate.plus({ days: theDays }).toISODate()))
      return getNextEnableNoOfDays(direction, theDays);

    return theDays;
  };

  const handleArrowClick = direction => {
    const days = getNextEnableNoOfDays(direction);
    datePickerChange(getValues(fieldNames.day).plus({ days }));
    setPickerDate(pickerDate.plus({ days }));
    fetchOrderTimes();
  };

  const getFutureDate = days => DateTime.local().plus({ days });

  const maxDate = getFutureDate(
    orderType === PICKUP ? pickupMaxDaysOut : deliveryMaxDaysOut,
  );

  const minDate = getFutureDate(1);

  const handleDatePickerChange = e => {
    setPickerDate(e);
    datePickerChange(e);
    setShowCalendar(false);
    fetchOrderTimes();
  };

  const handleDateSelectedClick = () => {
    setShowCalendar(true);
  };

  return {
    handleArrowClick,
    maxDate,
    minDate,
    showCalendar,
    pickerDate,
    handleDatePickerChange,
    handleDateSelectedClick,
    getNextEnableNoOfDays,
  };
};

const useHandleCheckoutSectionButtons = (showCalendar, storeId) => {
  const { setEditable } = useContext(SectionContext) ?? {};
  const { orderAvailabilityError, isOrderAvailabilityLoading } =
    useNewOrderForm(storeId);

  useEffect(() => {
    if (!setEditable) return;

    setEditable(
      !showCalendar && !orderAvailabilityError && !isOrderAvailabilityLoading,
    );

    return () => setEditable(true);
  }, [
    isOrderAvailabilityLoading,
    orderAvailabilityError,
    setEditable,
    showCalendar,
  ]);
};
