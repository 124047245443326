import { Box } from '@mui/material';
import { PaymentGatewayType } from 'components/payment/payment.constants';
import {
  usePaymentGatewayMatches,
  useWallets,
} from 'components/payment/payment.new.hooks';
import StripeWalletButton from '../incentivio/stripe-wallet-button/stripe-wallet-button.component';
import SpreedlyWallet from '../spreedly/spreedly-wallet/spreedly-wallet.component';

const WalletButton = ({ validate }) => {
  const { walletSupport, loading } = useWallets();
  const matchesPaymentGateway = usePaymentGatewayMatches();

  const enabled =
    !loading && (walletSupport?.applePay || walletSupport?.googlePay);

  if (!enabled) return null;

  return (
    <Box position="relative">
      {matchesPaymentGateway([PaymentGatewayType.INCENTIVIO_PAYMENTS]) && (
        <StripeWalletButton validate={validate} />
      )}
      {matchesPaymentGateway([PaymentGatewayType.SPREEDLY]) && (
        <SpreedlyWallet validate={validate} />
      )}
    </Box>
  );
};

export default WalletButton;
