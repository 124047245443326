import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${({ theme }) => theme.navHeight});
  text-align: center;
`;

export const Title = styled(Typography)`
  font-weight: bold;
`;
