import { Box } from '@mui/material';
import { convertCentsToDollar } from 'utils';
import * as S from './item-and-modifier-discounts.styles';

const ItemAndModierDiscounts = ({
  itemDiscounts,
  dataTestId,
  spacing = 10,
}) => {
  return itemDiscounts.map(itemDiscount => {
    const { discountId, description, appliedDiscount } = itemDiscount;
    return (
      <S.DiscountBox
        display="flex"
        flex="1"
        justifyContent="space-between"
        alignItems="center"
        data-testid={dataTestId}
        spacing={spacing}
        key={discountId}
      >
        <Box display="flex" flex="1">
          <S.DiscountText data-testid={`${dataTestId}.Title`}>
            {description}
          </S.DiscountText>
        </Box>
        {appliedDiscount !== 0 ? (
          <Box pl={1}>
            <S.DiscountText
              variant="body2"
              data-testid={`${dataTestId}.Amount`}
            >
              {`(-${convertCentsToDollar(appliedDiscount)})`}
            </S.DiscountText>
          </Box>
        ) : null}
      </S.DiscountBox>
    );
  });
};

export default ItemAndModierDiscounts;
