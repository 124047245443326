import React from 'react';
import * as S from './gift-card-view.styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/spinner.component';
import { selectIsCardLoading } from 'redux/user/user.selectors';
import {
  selectIsHideQrCode,
  selectLoyaltyCardCodeType,
} from 'redux/config/config.selectors';
import GiftCardViewActions from './giftcard-view-actions/giftcard-view-actions.component';
import RenderCode from 'components/render-code/render-code.component';

const GiftCardView = ({ data, ...rest }) => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsCardLoading);
  const hideQrCode = useSelector(selectIsHideQrCode);
  const loyaltyCardCodeType = useSelector(selectLoyaltyCardCodeType);

  if (isLoading) return <Spinner />;

  if (!data) return null;

  return (
    <>
      {data.cardIdentifier && (
        <>
          {!hideQrCode && (
            <RenderCode
              codeFormat={loyaltyCardCodeType}
              code={data.cardIdentifier}
              displayValue={false}
            />
          )}
          <S.CardIdentifier>
            {data.cardIdentifier.match(/.{1,4}/g).join(' ')}
          </S.CardIdentifier>
          {data.securityCode && (
            <S.CardIdentifier>
              {`${t('eGiftCard.view.securityCode')}:
          ${data.securityCode}`}
            </S.CardIdentifier>
          )}
        </>
      )}
      <S.YourBalance>
        <span>{t('cardItem.cardBalance')}</span>
        <span data-testid="CardItemGiftcard.cardAccountBalance">
          <b>${parseFloat(data.cardAccountBalance).toFixed(2)}</b>
        </span>
      </S.YourBalance>
      <GiftCardViewActions {...rest} />
    </>
  );
};

export default GiftCardView;
