import OrderItemOptionsCollapse from 'components/order-item-options-collapse/order-item-options-collapse.component';
import { useOpenMenuItemDialogPath } from 'hooks/dialog/menu-dialog.hooks';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeOrderItemStartAsync,
  updateOrderItemStartAsync,
} from '../../../redux/cart/cart.actions';
import {
  makeIsItemQtyBeingModified,
  makeSelectOrderItemPath,
  selectOrderId,
} from '../../../redux/cart/cart.selectors';
import { addGroupIdToOptions } from '../../../redux/cart/cart.utils';
import { selectItemInstructionsLabel } from '../../../redux/config/config.selectors';
import EditIcon from '../../edit-icon/edit-icon.component';
import * as S from './drawer-cart-item.styles';
import DrawerCartItemQtyButtons from './drawer-cart-tem-qty-buttons/drawer-cart-item-qty-buttons.component';

const DrawerCartItem = ({ item, testId = 'DrawerCartItem' }) => {
  const { t } = useTranslation();
  const orderId = useSelector(selectOrderId);
  const dispatch = useDispatch();

  const {
    quantity,
    options,
    itemId,
    itemInstructions,
    itemTotal: { total },
    orderItemId,
  } = item;
  const { groupId } = item.group;
  const { displayTotal } = item.itemTotal;
  const { title, smallImage } = useDisplayInfo(item.displayInfo);
  const itemInstructionsLabel = useSelector(selectItemInstructionsLabel);
  const selectModifyingQtyItemIds = useMemo(
    () => makeIsItemQtyBeingModified(orderItemId),
    [orderItemId],
  );
  const modifyingQtyItemIds = useSelector(selectModifyingQtyItemIds);
  const selectOrderItemPath = useMemo(
    () => makeSelectOrderItemPath(orderItemId),
    [orderItemId],
  );
  const orderItemPath = useSelector(selectOrderItemPath);

  const editInfo = { quantity, total, options, itemInstructions, orderItemId };
  const openModal = useOpenMenuItemDialogPath(orderItemPath, {
    editInfo,
  });

  const [detailsShown, setDetailsShown] = useState(false);
  const [modifyQtyType, setModifyQtyType] = useState('');

  const onToggle = () => {
    setDetailsShown(!detailsShown);
  };

  const handleRemoveItem = () => {
    setModifyQtyType('REMOVE_ITEM');
    dispatch(removeOrderItemStartAsync(orderId, orderItemId, itemId));
  };

  const handleDecrement = () => {
    setModifyQtyType('-');

    if (quantity === 1) {
      dispatch(removeOrderItemStartAsync(orderId, orderItemId, itemId));
      return;
    }

    dispatch(
      updateOrderItemStartAsync({
        ...item,
        groupId: groupId,
        quantity: quantity - 1,
        options: addGroupIdToOptions(item.options),
      }),
    );
  };

  const handleIncrement = () => {
    setModifyQtyType('+');
    dispatch(
      updateOrderItemStartAsync({
        ...item,
        groupId: groupId,
        quantity: quantity + 1,
        options: addGroupIdToOptions(item.options),
      }),
    );
  };

  return (
    <>
      <S.Item data-testid={testId}>
        {smallImage.length > 0 && (
          <S.ImageContainer>
            <img data-testid="testProductImg" src={smallImage[0]} alt="" />
          </S.ImageContainer>
        )}
        <S.ContentContainer
          className={smallImage.length === 0 ? 'full-width' : ''}
        >
          <S.Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <S.Title id={`cart-item-${item.orderItemId}-title`}>
                {title}
              </S.Title>
              <EditIcon
                id={`cart-item-${item.orderItemId}-edit`}
                aria-label={t('cart.editAction.label')}
                onClick={openModal}
                data-testid="DrawerCartItem.EditIcon"
                aria-labelledby={`cart-item-${item.orderItemId}-title cart-item-${item.orderItemId}-edit`}
              >
                {t('cart.editAction.label')}
              </EditIcon>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <S.RemoveItem
                color="inherit"
                id={`cart-item-${item.orderItemId}-remove`}
                onClick={handleRemoveItem}
                data-testid="DrawerCartItem.RemoveItem"
                aria-labelledby={`cart-item-${item.orderItemId}-title cart-item-${item.orderItemId}-remove`}
                disabled={
                  modifyQtyType === 'REMOVE_ITEM' &&
                  modifyingQtyItemIds.length > 0
                }
              >
                {t('cart.removeAction.label')}
              </S.RemoveItem>
            </div>
            <S.Price>${displayTotal}</S.Price>
            {options && (
              <S.ToggleDetailsButton
                onClick={onToggle}
                aria-label={`${detailsShown ? 'Hide Details' : 'Show Details'}`}
                aria-pressed={false}
                data-testid="DrawerCartItem.ToggleDetailsButton"
              >
                {detailsShown ? t('drawer.cart.hide') : t('drawer.cart.show')}
              </S.ToggleDetailsButton>
            )}
            {itemInstructions && (
              <>
                <S.ItemInstructionsLabel>
                  {itemInstructionsLabel}
                </S.ItemInstructionsLabel>
                <S.ItemInstructions>{itemInstructions}</S.ItemInstructions>
              </>
            )}
          </S.Text>
          <DrawerCartItemQtyButtons
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            quantity={quantity}
            modifyQtyType={modifyQtyType}
            modifyingQtyItemIds={modifyingQtyItemIds}
            title={title}
          />
          <OrderItemOptionsCollapse isOpened={detailsShown} options={options} />
        </S.ContentContainer>
      </S.Item>
    </>
  );
};

export default DrawerCartItem;
