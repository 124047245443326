import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VerifySmsErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Typography component="h2" aria-label={t('verifySms.error')}>
      {t('verifySms.error')}
    </Typography>
  );
};

export default VerifySmsErrorMessage;
