import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  spreedlyCvvName,
  spreedlyNumberName,
} from '../spreedly-form.component';
import SpreedlyField from './spreedly-field.component';
import SpreedlyIFrameField from './spreedly-iframe-field.component';

const CardFields = () => {
  const { t } = useTranslation();

  return (
    <Box display="grid" rowGap={theme => theme.spacing(1)}>
      <Typography variant="h6" component="h3">
        {t('checkout.payment.spreedly.sections.paymentDetails')}
      </Typography>
      <Box display="grid">
        <Box
          display="grid"
          gridTemplateColumns="2fr 1fr"
          gap={theme => theme.spacing(1)}
        >
          <SpreedlyIFrameField
            name={spreedlyNumberName}
            label={t('checkout.payment.spreedly.fields.cardNumber.label')}
            id="spreedly-number-test"
          />
          <SpreedlyIFrameField
            name={spreedlyCvvName}
            label={t('checkout.payment.spreedly.fields.cvv.label')}
            id="spreedly-cvv-test"
          />
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr 2fr"
          gap={theme => theme.spacing(1)}
          alignItems="baseline"
          maxWidth={{ xs: '100%', sm: 360 }}
        >
          <SpreedlyField
            name="month"
            label={t('checkout.payment.spreedly.fields.expirationDate.label')}
            placeholder="MM"
            inputProps={{
              'aria-label': t(
                'checkout.payment.spreedly.fields.expirationMonth.label',
              ),
            }}
            autoComplete="cc-exp-month"
            required
            type="number"
          />
          <SpreedlyField
            name="year"
            placeholder="YYYY"
            inputProps={{
              'aria-label': t(
                'checkout.payment.spreedly.fields.expirationYear.label',
              ),
            }}
            autoComplete="cc-exp-year"
            required
            type="number"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default CardFields;
