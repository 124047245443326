import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';

export const CustomMenuItem = styled(MenuItem)`
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;

  & .MuiListItemText-root {
    margin: ${({ theme }) => theme.spacing(0.25)} 0;
  }
`;

export const MenuItemIcon = styled(ListItemIcon)`
  && {
    min-width: unset;
  }
`;

export const MenuItemText = styled(ListItemText)`
  min-width: 200px;
  text-transform: capitalize;
`;

export const MessageBadge = styled(Badge)`
  .MuiBadge-badge {
    height: 10px;
    min-width: 10px;
    top: 4px;
    right: 3px;
    padding: 0;
    background-color: ${({ theme }) => theme.palette.messageCountColor};
  }
`;
