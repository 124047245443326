import { Box, InputLabel, useTheme } from '@mui/material';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import { useSpreedlyIframeField } from '../spreedly-form.hooks';
import { spreedlyIframeFields } from '../spreedly-form.component';

const SpreedlyIFrameField = ({ name, label, id }) => {
  const theme = useTheme();
  const { error } = useSpreedlyIframeField(name);

  return (
    <Box id={id}>
      <CustomInput>
        <InputLabel htmlFor={spreedlyIframeFields[name]} required>
          {label}
        </InputLabel>
        <Box
          id={name}
          borderBottom={`1px solid ${theme.palette.grey[500]}`}
          display="flex"
          alignItems="flex-end"
          sx={{
            '& iframe': {
              height: '28px',
            },
          }}
        ></Box>
        {error && <FormError aria-live="assertive" errorMessage={error} />}
      </CustomInput>
    </Box>
  );
};
export default SpreedlyIFrameField;
