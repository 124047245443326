import { TextField } from '@mui/material';
import React, { useCallback, forwardRef } from 'react';
import { useTrimFields } from '../useTrimFields.hooks';

const TrimTextField = forwardRef((props, ref) => {
  const { name, onBlur, inputProps = {}, ...rest } = props;
  const { handleTrim, handleKeyPress } = useTrimFields(name);

  const finalOnBlur = useCallback(
    e => {
      handleTrim(e);
      onBlur && onBlur(e);
    },
    [handleTrim, onBlur],
  );

  return (
    <TextField
      name={name}
      inputRef={ref}
      onBlur={finalOnBlur}
      onKeyPress={handleKeyPress}
      inputProps={{ 'aria-describedby': 'FormError', ...inputProps }}
      {...rest}
    />
  );
});

export default TrimTextField;
