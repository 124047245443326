import { Box } from '@mui/material';
import { useIsNewSpreedlyCardFlow } from 'components/payment/payment-selector/credit-card-panel/credit-card-panel-controller/credit-card-panel-controller.hooks';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import SavePaymentInstrumentCheckbox from './fields/save-payment-instrument-checkbox.component';
import { SpreedlyDefault } from './spreedly-default.component';
import { Provider as SpreedlyFormProvider } from './spreedly-form.context';
import { useSpreedlyForm } from './spreedly-form.hooks';
import { SpreedlyToast } from './spreedly-toast.component';

export const spreedlyNumberName = 'spreedly-card-number';
export const spreedlyCvvName = 'spreedly-cvv';
export const spreedlyIframeFields = {
  [spreedlyNumberName]: 'number',
  [spreedlyCvvName]: 'cvv',
};
export const spreedlyFieldsMap = {
  number: spreedlyNumberName,
  cvv: spreedlyCvvName,
};

const SpreedlyForm = ({ formName, handleCardInfo }) => {
  const value = useSpreedlyForm(handleCardInfo);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isNewSpreedlyCardFlow = useIsNewSpreedlyCardFlow();

  return (
    <SpreedlyFormProvider value={value}>
      <Box
        display="grid"
        rowGap={theme => theme.spacing(2)}
        sx={{
          '& .MuiInputLabel-root': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <SpreedlyFormSwitch formName={formName} />
        {isLoggedIn && isNewSpreedlyCardFlow && (
          <SavePaymentInstrumentCheckbox />
        )}
      </Box>
    </SpreedlyFormProvider>
  );
};

export const SpreedlyFormSwitch = ({ formName }) => {
  switch (formName) {
    case 'spreedly':
      return <SpreedlyDefault />;
    case 'toast_spreedly':
    case 'toast_spreedly_canada':
      return <SpreedlyToast />;
    default:
      throw new Error('Spreedly Configuration Error - Unknown Form Name');
  }
};

export default SpreedlyForm;
