import { Grid } from '@mui/material';
import styled from 'styled-components';

const baseLineHeight = 1.5;

export const Container = styled(Grid)`
  box-shadow: 0px 1px 15px 0px rgba(0, 66, 106, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  height: 148px;
  border-radius: var(--borderRadius, 4px);
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
  overflow: hidden;
  cursor: pointer;
  line-height: ${baseLineHeight};
  flex-wrap: nowrap;
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    border: 1px solid gray;
    cursor: default;
  `}

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0px 1px 15px 0px rgba(0, 66, 106, 0.5);
  }
`;

export const GridItem = styled(Grid)`
  && {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;
