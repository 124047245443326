import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import * as S from '../drawer/drawer.styles';
import TogglerButton from '../toggler-button/toggler-button.component';
import { useTranslation } from 'react-i18next';
import { selectItemsCount } from '../../redux/cart/cart.selectors';
import { useSelector } from 'react-redux';
import HeaderDrawer from 'components/header-drawer/header-drawer.component';
import { Box } from '@mui/material';

const Drawer = ({
  toggleText,
  children,
  isLight,
  handleToggleDrawer,
  isDrawerOpen = false,
  togglerButtonProps,
}) => {
  const { t } = useTranslation();
  const totalItemsCount = useSelector(selectItemsCount);

  return (
    <>
      <TogglerButton
        handleToggle={handleToggleDrawer(true)}
        {...togglerButtonProps}
      >
        {toggleText === t('drawer.login.toggle') ? (
          <AccountCircleIcon />
        ) : (
          <S.CartTogglerWrapper>
            <S.CustomBadge
              overlap="rectangular"
              badgeContent={totalItemsCount}
              data-testid="Drawer.CustomBadge"
            >
              <ShoppingCartIcon />
            </S.CustomBadge>
          </S.CartTogglerWrapper>
        )}
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{toggleText}</Box>
      </TogglerButton>

      <HeaderDrawer
        open={isDrawerOpen}
        onOpen={handleToggleDrawer(true)}
        onClose={handleToggleDrawer(false)}
        isLight={isLight}
      >
        {children}
      </HeaderDrawer>
    </>
  );
};

export default Drawer;
