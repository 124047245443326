import styled from 'styled-components';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const Item = styled(Box)`
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid #d8d8d8;
  flex-wrap: wrap;
`;

export const ImageContainer = styled(Box)`
  //max-width: 71px;
  height: auto;
  width: 30%;

  img {
    width: 100%;
    padding-right: 1em;
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  width: 70%;
  flex-wrap: wrap;

  &.full-width {
    width: 100%;
  }

  .ReactCollapse--collapse {
    width: 100%;
    transition: height 500ms;
  }
`;

export const Text = styled(Box)`
  width: 80%;
`;

export const Title = styled(Typography)`
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Price = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ToggleDetailsButton = styled.button`
  font-size: 0.75rem;
  text-decoration: underline;
  cursor: pointer;
  border: unset;
  background-color: transparent;
  margin-left: -0.25em !important;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ItemInstructionsLabel = styled(Typography)`
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ItemInstructions = styled(Typography)`
  font-size: 0.875rem;
  opacity: 0.5;
`;

export const RemoveItem = styled(Button)`
  cursor: pointer;
  text-decoration: underline !important;
  font-size: 0.5rem;
  margin: 0 0 0 -10px !important;
  padding: 0;
`;
