import { useGate } from 'statsig-react';
import SpreedlyCard303942 from '../spreedly-card/spreedly-card-303942.component';
import SpreedlyCard from '../spreedly-card/spreedly-card.component';
import * as S from './spreedly-card-guest.styles';

const SpreedlyCardGuest = () => {
  const { value: isSpreedlyFormRefactor, isLoading } = useGate(
    'spreedly-form-refactor',
  );

  if (isLoading) return null;

  return (
    <S.Container>
      {isSpreedlyFormRefactor ? <SpreedlyCard303942 /> : <SpreedlyCard />}
    </S.Container>
  );
};

export default SpreedlyCardGuest;
