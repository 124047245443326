import { DateTime } from 'luxon';
import { boolean, number, object, string } from 'yup';

export const spreedlyDefaultSchema = object({
  first_name: string().required().trim(),
  last_name: string().required().trim(),
  month: number()
    .max(12)
    .min(1)
    .required()
    .typeError(() => 'month is a required field'),
  year: number()
    .min(DateTime.now().year)
    .max(2999)
    .required()
    .typeError(() => 'year is a required field'),
  savePaymentInstrument: boolean(),
  isValidCVV: boolean().default(false),
});

export const spreedlyToastSchema = spreedlyDefaultSchema.concat(
  object({
    address1: string().required().trim(),
    address2: string().trim(),
    city: string().required().trim(),
    state: string().required().trim(),
    zip: string().required().trim(),
    country: string().required().trim(),
  }),
);
