import { ErrorMessage } from '@hookform/error-message';
import { Box, InputLabel } from '@mui/material';
import AddMenuItem from 'components/address/address-select/add-menu-item/add-menu-item.component';
import CustomInput from 'components/custom-input/custom-input.component';
import Spinner from 'components/spinner/spinner.component';
import React from 'react';
import { useAddressControls } from '../address-controls.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addressEquals } from 'pages/locations/locations.utils';
import { addNewAddress } from 'redux/user/user.actions';
import NewAddress from 'components/address/new-address/new-address.component';
import FormError from 'components/form-error/form-error.component';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import AddressSelect from 'components/address/address-select/address-select.component';

const AddressControlsField = ({
  name,
  defaultValue,
  orderType,
  label,
  dataTestId,
}) => {
  const {
    setValue,
    setError,
    errors,
    trigger,
    showSpinner,
    setShowSpinner,
    addresses,
    setAddresses,
    selectName,
    newName,
    defaultSelectValue,
    selectValue,
    handleCancel,
    error,
  } = useAddressControls(name, defaultValue, orderType);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleNewAddressForLoggedIn = async address => {
    if (
      addresses.some(existingAddress => addressEquals(existingAddress, address))
    ) {
      setError(name, {
        type: 'duplicate',
        message: t('address.select.errors.duplicate'),
      });
      return;
    }
    setShowSpinner(true);
    const newAddress = await dispatch(addNewAddress(address));
    if (newAddress) {
      setValue(selectName, newAddress.addressId);
      setValue(name, newAddress);
      trigger(name);
    }
    setShowSpinner(false);
  };

  const handleNewAddressForLoggedOut = async address => {
    address.addressId = `${address.placeId}.${address.aptSuite}`;
    address.addressAlias = address.main_text;
    setAddresses(prev => [...prev, address]);
    setValue(selectName, address.addressId);
    setValue(name, address);
    trigger(name);
  };

  return (
    <Box position="relative" id={name}>
      {showSpinner && <Spinner />}
      <CustomInput>
        <InputLabel htmlFor={selectName} data-testid={dataTestId}>
          {label}
        </InputLabel>
        <AddressSelect
          name={selectName}
          addresses={addresses}
          addressesGroupTitle={
            isLoggedIn && t('address.select.addressesGroupTitle')
          }
          defaultValue={defaultSelectValue}
          addMenuItem={<AddMenuItem title={t('address.select.newAddress')} />}
          error={error}
          hidden={addresses.length < 1}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FormError errorMessage={message} />}
        />
      </CustomInput>
      {selectValue === 'new' && (
        <NewAddress
          name={newName}
          addressFieldsOptions={{
            aliasEnabled: isLoggedIn,
          }}
          onSubmit={
            isLoggedIn
              ? handleNewAddressForLoggedIn
              : handleNewAddressForLoggedOut
          }
          onCancel={handleCancel}
          cancelEnabled={addresses?.length > 0}
        />
      )}
    </Box>
  );
};

export default AddressControlsField;
