import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const QuantityContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: -12px;
  margin-top: 5px;
`;

export const OptionQuantityContainer = styled.div`
  margin-bottom: 6px;
`;

export const QtyButton = styled(IconButton)`
  margin-top: -4px;
`;
