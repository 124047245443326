import styled from 'styled-components';
import { Box, alpha } from '@mui/material';

export const Container = styled(Box)`
  label {
    font-size: 0.875rem;
  }

  input {
    &::placeholder {
      font-size: 0.875rem;
    }
  }

  .MuiInputAdornment-root {
    color: ${({ theme }) => alpha(theme.palette.text.primary, 0.5)};
  }

  .MuiInput-underline:not(.Mui-error) {
    &:after {
      border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
    }
  }

  .MuiOutlinedInput-multiline {
    margin-top: 0.5rem;
    fieldset {
      border: 1px solid
        ${({ theme }) => alpha(theme.palette.text.primary, 0.42)};
    }
  }

  .error-display {
    &:before {
      display: inline;
      content: '⚠ ';
    }
  }
`;
