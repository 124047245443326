import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import TimeSelectorWrapper from 'components/new-order/time-selector-wrapper/time-selector-wrapper.component';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import * as S from './schedule-date-picker.styles';
import { useScheduleDatePicker } from './useScheduleDatePicker.hooks';
import { useController, useFormContext } from 'react-hook-form';
import { useFieldNames } from 'components/new-order/useFieldNames.hooks';
import { DateTime } from 'luxon';

const ScheduleDatePicker = props => {
  const { scheduleProps, getInitialFocusedDate } = props;
  const { fieldNames } = useFieldNames();
  const { getValues } = useFormContext();
  const name = fieldNames.day;
  const {
    field: { onChange: datePickerChange, value, ...fieldRest },
  } = useController({ name, defaultValue: getInitialFocusedDate });

  const {
    handleArrowClick,
    maxDate,
    minDate,
    pickerDate,
    handleDatePickerChange,
    handleDateSelectedClick,
    getNextEnableNoOfDays,
  } = useScheduleDatePicker(scheduleProps, datePickerChange);

  if (scheduleProps.showCalendar || !pickerDate)
    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateCalendar
          onChange={handleDatePickerChange}
          data-testid="ScheduleDatePicker.KeyboardDatePicker"
          minDate={minDate}
          maxDate={maxDate}
          disableHighlightToday
          shouldDisableDate={day =>
            scheduleProps.offDays.includes(day.toISODate())
          }
          slotProps={{
            leftArrowIcon: {
              'data-testid': 'ScheduleDatePicker.LeftArrow',
            },
            rightArrowIcon: {
              'data-testid': 'ScheduleDatePicker.RightArrow',
            },
            day: {
              'data-testid': 'ScheduleDatePicker.Day',
            },
          }}
          value={
            getValues(fieldNames.day).isLuxonDateTime
              ? getValues(fieldNames.day)
              : DateTime.fromISO(getValues(fieldNames.day))
          }
          {...fieldRest}
        />
      </LocalizationProvider>
    );

  return (
    <>
      <S.DateController
        data-testid="ScheduleDatePicker.SelectedDateController"
        display="flex"
        justifyContent="space-between"
        width="80%"
        margin="auto"
        alignItems="center"
        pb="1em"
      >
        <Button
          onClick={() => handleArrowClick('LEFT')}
          disabled={
            pickerDate
              .plus({ days: getNextEnableNoOfDays('LEFT') })
              .toISODate() < minDate.toISODate()
          }
          data-testid="ScheduleDatePicker.SelectedDateController.LeftArrow"
          color="inherit"
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          color="inherit"
          onClick={handleDateSelectedClick}
          data-testid="ScheduleDatePicker.SelectedDateController.SelectedDateControl"
        >
          {`${pickerDate.weekdayLong} ${pickerDate.toLocaleString()}`}
        </Button>
        <Button
          onClick={() => handleArrowClick('RIGHT')}
          disabled={
            pickerDate
              .plus({ days: getNextEnableNoOfDays('RIGHT') })
              .toISODate() >= maxDate.toISODate()
          }
          data-testid="ScheduleDatePicker.SelectedDateController.RightArrow"
          color="inherit"
        >
          <ChevronRightIcon />
        </Button>
      </S.DateController>
      <TimeSelectorWrapper {...scheduleProps} />
    </>
  );
};

export default ScheduleDatePicker;
