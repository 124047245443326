import { Box, Fade, useTheme } from '@mui/material';
import React from 'react';
import CheckoutButtonsSection from '../checkout-buttons-section/checkout-buttons-section.component';
import CheckoutContactInfoSection from '../checkout-contact-info-section/checkout-contact-info-section.component';
import CheckoutDeliveryInstructionsSection from '../checkout-delivery-instructions-section/checkout-delivery-instructions-section.component';
import { useCheckoutDeliveryInstructionsSection } from '../checkout-delivery-instructions-section/checkout-delivery-instructions-section.hooks';
import CheckoutItemsSection from '../checkout-items-section/checkout-items-section.component';
import CheckoutLocationSection from '../checkout-location-section/checkout-location-section.component';
import CheckoutOffersModal from '../checkout-offers-modal/checkout-offers-modal.component';
import CheckoutOffersSection from '../checkout-offers-section/checkout-offers-section.component';
import { useCheckoutOffersSection } from '../checkout-offers-section/checkout-offers-section.hooks';
import CheckoutOrderDetailsSection from '../checkout-order-details-section/checkout-order-details-section.component';
import CheckoutOrderInstructionsSection from '../checkout-order-instructions-section/checkout-order-instructions-section.component';
import { useCheckoutOrderInstructionsSection } from '../checkout-order-instructions-section/checkout-order-instructions-section.hooks';
import CheckoutPaymentSectionWrapper from '../checkout-payment-section-new/checkout-payment-section-wrapper/checkout-payment-section-wrapper.component';
import CheckoutRecommendationsSection from '../checkout-recommendations-section/checkout-recommendations-section.component';
import CheckoutSummarySection from '../checkout-summary-section/checkout-summary-section.component';
import * as S from './checkout-sections.styles';
import { useSelector } from 'react-redux';
import { selectRecommendationsEnabled } from 'redux/config/config.selectors';

const CheckoutSections = () => {
  const { show: showOrderInstructionsSection } =
    useCheckoutOrderInstructionsSection();
  const { show: showDeliveryInstructionsSection } =
    useCheckoutDeliveryInstructionsSection();
  const { show: showOffersSection } = useCheckoutOffersSection();
  const theme = useTheme();
  const showRecommendationsSection = useSelector(selectRecommendationsEnabled);

  return (
    <Box
      display="flex"
      height="100%"
      flex="1"
      flexDirection={['column', 'column', 'row']}
    >
      <Box display="flex" p={2} pb={[0, 0, 2]} justifyContent="center" flex="2">
        <Fade in={true} timeout={{ appear: 1000, enter: 1000 }}>
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            maxWidth={['100%', '100%', '600px']}
          >
            <CheckoutLocationSection />
            <CheckoutContactInfoSection />
            <CheckoutOrderDetailsSection />
            {showDeliveryInstructionsSection && (
              <CheckoutDeliveryInstructionsSection />
            )}
            {showOrderInstructionsSection && (
              <CheckoutOrderInstructionsSection />
            )}
            <CheckoutPaymentSectionWrapper />

            <CheckoutItemsSection mb={[2, 2, 0]} />
          </Box>
        </Fade>
      </Box>
      <S.StickyContainer
        display="flex"
        flex="1"
        pt={[0, 0, 2]}
        pr={2}
        pb={[2, 2, 0]}
        pl={[2, 2, 0]}
        position={['relative', 'relative', 'sticky']}
        top={[0, 0, theme.navHeight]}
      >
        <S.StickyScroll
          position="relative"
          height="100%"
          width={['100%', '100%', '400px']}
        >
          <Box display="flex" flexDirection="column" width="100%">
            {showOffersSection && (
              <Box mb={[2, 2, 2, 1]}>
                <CheckoutOffersSection />
              </Box>
            )}
            {showRecommendationsSection && (
              <CheckoutRecommendationsSection mb={[2, 2, 2, 1]} />
            )}
            <CheckoutSummarySection mb={[2, 2, 2, 1]} />
            <CheckoutButtonsSection />
          </Box>
        </S.StickyScroll>
      </S.StickyContainer>

      <CheckoutOffersModal />
    </Box>
  );
};

export default CheckoutSections;
