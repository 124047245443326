import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  max-width: fit-content;
  align-items: center;
  white-space: break-spaces;
  min-height: 42px;
`;
