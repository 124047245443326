import { Box } from '@mui/material';
import { CheckoutH3 } from 'pages/checkout/checkout.styles';
import React from 'react';

const OrderDetailsRow = ({
  icon: Icon,
  title,
  subtitle,
  children,
  testIdPrefix = '',
  ...rest
}) => {
  return (
    <Box display="flex" alignItems="center" flex={1} {...rest}>
      <Box display="flex" flexDirection="column" mr={1} alignItems="center">
        <Box>
          <Icon color="primary" fontSize="large" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" minWidth="0">
        <Box>
          <CheckoutH3 data-testid={`${testIdPrefix}.Title`}>{title}</CheckoutH3>
        </Box>
        {subtitle && (
          <Box data-testid={`${testIdPrefix}.SubTitle`}>{subtitle}</Box>
        )}
        {children && (
          <Box data-testid={`${testIdPrefix}.Children`}>{children}</Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderDetailsRow;
