import { Typography, Box } from '@mui/material';
import styled from 'styled-components';

export const DiscountText = styled(Typography)`
  color: ${({ theme }) => theme.palette.success.main};
  font-size: 0.8rem;
`;

export const DiscountBox = styled(Box)`
  margin: ${props => `0 0 0 ${props.spacing}px`};
`;
