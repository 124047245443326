import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomBox = styled(Box)`
  margin-top: ${({ theme, ishideheader }) =>
    ishideheader ? `-${theme.navHeight}` : '0px'};
  padding-top: 30px;
  min-height: 100vh;
  ${({ theme }) => theme.palette.type !== 'dark' && `background-color: white;`}
`;

export const Title = styled(Typography)`
  font-weight: 600;
  margin-bottom: 1em;
`;

export const ThankyouMessage = styled(Typography)`
  font-weight: 600;
`;
