import { useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { scroller } from 'react-scroll';
import CheckoutContext from '../checkout.context';

export const useCheckoutSection = ({
  title,
  isEditing = false,
  onSubmit,
  onCancel = () => null,
  validate,
} = {}) => {
  const [editable, setEditable] = useState(false);
  const {
    setSectionValidation,
    validateSection,
    getSectionValidationState,
    getSectionEditingState,
    setSectionEditingState,
  } = useContext(CheckoutContext);

  const id = useMemo(() => getSectionId(title), [title]);
  const editing = useMemo(
    () => getSectionEditingState(id),
    [getSectionEditingState, id],
  );
  const setEditing = useCallback(
    newState => setSectionEditingState(id, newState),
    [id, setSectionEditingState],
  );

  useEffect(
    () => {
      !!id && !!setSectionEditingState && setSectionEditingState(id, isEditing);

      return () => {
        !!id &&
          !!setSectionEditingState &&
          setSectionEditingState(id, undefined);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, setSectionEditingState],
  );

  const theme = useTheme();
  const offset = useMemo(
    () => -parseInt(theme.navHeight.replace('px', '')),
    [theme.navHeight],
  );
  const scrollToTop = useCallback(() => {
    scroller.scrollTo(id, {
      offset,
    });
  }, [id, offset]);

  const submit = useCallback(async () => {
    try {
      await onSubmit();
      setEditing(false);
      scrollToTop();
      validateSection(id);
    } catch (err) {}
  }, [id, onSubmit, scrollToTop, setEditing, validateSection]);

  const cancel = useCallback(async () => {
    await onCancel();
    setEditing(false);
    scrollToTop();
    validateSection(id);
  }, [id, onCancel, scrollToTop, setEditing, validateSection]);

  useEffect(() => {
    !!validate && setSectionValidation(id, validate);

    return () => {
      !!id && !!validate && setSectionValidation(id, undefined);
    };
  }, [id, setSectionValidation, validate]);

  return useMemo(
    () => ({
      id,
      title,
      editing,
      setEditing,
      editable,
      setEditable,
      scrollToTop,
      submit,
      cancel,
      validate,
      errors: getSectionValidationState(id)?.message,
    }),
    [
      id,
      title,
      editing,
      setEditing,
      editable,
      scrollToTop,
      submit,
      cancel,
      validate,
      getSectionValidationState,
    ],
  );
};

export const getSectionId = title => (title ? `${title}-section` : undefined);
