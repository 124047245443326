import { Box } from '@mui/material';
import AddressFields from './fields/address-fields.component';
import CardFields from './fields/card-fields.component';
import NameFields from './fields/name-fields.component';

export const SpreedlyToast = () => {
  return (
    <Box display="grid" rowGap={theme => theme.spacing(2)}>
      <NameFields />
      <AddressFields />
      <CardFields />
    </Box>
  );
};
