import SpreedlyContext from 'components/payment/spreedly/spreedly.context';
import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { useGate } from 'statsig-react';
import GiftCardSpreedlyPurchaseButton, {
  GiftCardSpreedlyPurchaseButton303942,
} from './gift-card-spreedly-purchase-button/gift-card-spreedly-purchase-button.component';
import PurchaseButton from './purchase-button/purchase-button.component';

const GiftCardPurchaseButton = props => {
  const loggedIn = useSelector(selectIsLoggedIn);
  const spreedlyContext = useContext(SpreedlyContext);
  const { value: isSpreedlyFormRefactor, isLoading } = useGate(
    'spreedly-form-refactor',
  );
  const setPaymentLoading = useCheckoutPaymentLoading(
    state => state.setLoading,
  );
  const paymentLoading = useCheckoutPaymentLoading(state => state.loading);

  /* istanbul ignore next */
  if (isLoading) return null;

  if (!loggedIn && !!spreedlyContext)
    return isSpreedlyFormRefactor ? (
      <GiftCardSpreedlyPurchaseButton303942
        {...props}
        setPaymentLoading={setPaymentLoading}
        paymentLoading={paymentLoading}
        cardInfo={spreedlyContext.cardInfo}
      />
    ) : (
      <GiftCardSpreedlyPurchaseButton
        {...props}
        setPaymentLoading={setPaymentLoading}
        paymentLoading={paymentLoading}
        cardInfo={spreedlyContext.cardInfo}
      />
    );

  return <PurchaseButton {...props} />;
};

export default GiftCardPurchaseButton;
