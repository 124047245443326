import { Box } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';
import Spinner from 'components/spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import StripeCardBase from './stripe-card-base/stripe-card-base.component';
import { useStripeCardComponent } from './stripe-card.hooks';

const StripeCard = ({ handleCardInfo }) => {
  const { t } = useTranslation();
  const { attaching, loading, onSubmit, error } =
    useStripeCardComponent(handleCardInfo);

  return (
    <Box data-testid="StripeCard.Container">
      {(loading || attaching) && <Spinner />}
      <form id="payment-form" onSubmit={onSubmit}>
        <StripeCardBase error={error} />
        {!attaching && (
          <IncButton
            onClick={onSubmit}
            data-testid="StripeCard.IncButton.Submit"
            fullWidth
            disabled={attaching || loading}
          >
            {t('payment.cardModal.submitAction')}
          </IncButton>
        )}
      </form>
    </Box>
  );
};
export default StripeCard;
