import React, { useState } from 'react';
import TocModal from './toc-modal/toc-modal.component';
import { Box } from '@mui/material';
import TocAndPrivacySettingsPage from './toc-and-privacy-settings-page/toc-and-privacy-settings-page.component';
import TocAndPrivacyFormInfo from './toc-and-privacy-form-info/toc-and-privacy-form-info.component';

const TocAndPrivacy = ({ description, isSettingsPage = false }) => {
  const [tocOpen, setTocOpen] = useState(false);

  return (
    <>
      <TocModal open={tocOpen} close={() => setTocOpen(false)} />
      {!isSettingsPage ? (
        <TocAndPrivacyFormInfo
          description={description}
          handleTocOpen={() => setTocOpen(true)}
        />
      ) : (
        <Box mt={1}>
          <TocAndPrivacySettingsPage handleTocOpen={() => setTocOpen(true)} />
        </Box>
      )}
    </>
  );
};

export default TocAndPrivacy;
