import React from 'react';
import * as S from './order-item-offers.styles';
import { Box } from '@mui/material';

const OrderItemOffers = ({ itemDiscounts }) => {
  return (
    <S.OptionContainer>
      {itemDiscounts.map((itemDiscount, i) => {
        const { discountId, description, displayAppliedDiscount } =
          itemDiscount;

        return (
          <Box key={`offers-list-${discountId}-${i}`}>
            <S.OptionDetail data-testid="OrderItemOffers">
              <Box display="inline" mr="3px">
                {description}
              </Box>
              <span data-testid="OrderItemOffers.Amount">
                {`(-$${displayAppliedDiscount})`}
              </span>
            </S.OptionDetail>
          </Box>
        );
      })}
    </S.OptionContainer>
  );
};

export default OrderItemOffers;
