import { ErrorMessage } from '@hookform/error-message';
import { InputLabel, ToggleButtonGroup } from '@mui/material';
import CurrencyField from 'components/currency-field/currency-field.component';
import CustomInput from 'components/custom-input/custom-input.component';
import FormError from 'components/form-error/form-error.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './card-value-selector.styles';

const CardValueSelector = ({
  name,
  ariaLabel,
  presetValues = [25, 50, 75, 100],
  onChange,
  onBlur,
  value,
  errors,
}) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState(
    presetValues.includes(value) ? value : 'custom',
  );
  const customInputName = `${name}-custom`;

  const [customAmount, setCustomAmount] = useState(value);

  const handleChange = (_e, newValue) => {
    if (newValue) {
      setSelectedButton(newValue);
      newValue === 'custom' ? onChange(customAmount) : onChange(newValue);
    }
  };

  const onCustomAmountChange = newValue => {
    setCustomAmount(newValue);
    onChange(parseFloat(newValue));
  };

  const handleOnBlur = e => {
    onBlur(parseFloat(e.target.value));
  };

  return (
    <>
      <S.StyledCustomInput>
        <InputLabel htmlFor={name}>
          {t('eGiftCard.form.amount.label')}*
        </InputLabel>
        <ToggleButtonGroup
          name={name}
          value={selectedButton}
          exclusive
          aria-label={ariaLabel || 'Select a card value'}
          onChange={handleChange}
        >
          {presetValues.map(val => (
            <S.CustomToggleButton
              value={val}
              key={val}
              aria-label={`${val} dollars`}
              data-testid="CardValueSelector.CustomToggleButton"
            >
              ${val}
            </S.CustomToggleButton>
          ))}
          <S.CustomToggleButton
            value="custom"
            aria-label={t('eGiftCard.form.customAmount.ariaLabel')}
            data-testid="CardValueSelector.CustomToggleButton"
          >
            {t('eGiftCard.form.customAmount.title')}
          </S.CustomToggleButton>
        </ToggleButtonGroup>
      </S.StyledCustomInput>
      {selectedButton === 'custom' && (
        <CustomInput>
          <InputLabel htmlFor={customInputName}>
            {t('eGiftCard.form.customAmount.label')}*
          </InputLabel>
          <CurrencyField
            id={customInputName}
            name={customInputName}
            value={customAmount}
            onChange={onCustomAmountChange}
            onBlur={handleOnBlur}
            error={!!errors[name]}
            fullWidth
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormError errorMessage={message} />}
          />
        </CustomInput>
      )}
    </>
  );
};

export default CardValueSelector;
