import { Grid } from '@mui/material';
import styled from 'styled-components';

export const EmptyInbox = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    max-width: 300px;
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const MessageGrid = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
