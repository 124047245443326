import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import OrderOptions from 'components/new-order/order-options/order-options.component';
import { selectRequestedAddressIsValid } from 'redux/cart/cart.selectors';
import { useOrderTypeLabels } from 'hooks/useOrderTypeLabels';
import { useTranslation } from 'react-i18next';
import { useNewOrderSubmit } from '../useNewOrderSubmit.hooks';
import { useFormContext } from 'react-hook-form';
import IncButton from 'components/inc-button/inc-button.component';
import { useLocationsCardSelections } from '../useSkipDayTimeSelector.hooks';
import ScheduleOrder from 'components/new-order/schedule-order/schedule-order.component';

const StorePageSelections = ({ store, orderType }) => {
  const { t } = useTranslation();
  const { pickupLabel, deliveryLabel } = useOrderTypeLabels();
  const { handleSubmit: rhfHandleSubmit } = useFormContext();
  const { handleSubmit } = useNewOrderSubmit(store, rhfHandleSubmit);

  const requestedAddressIsValid = useSelector(selectRequestedAddressIsValid);

  const { dayTimeSkip, firstAvailableTime } = useLocationsCardSelections(
    store,
    orderType,
  );

  if (orderType === 'DELIVERY' && !requestedAddressIsValid) return null;

  return (
    <Box position="relative" minHeight="200px">
      <OrderOptions
        store={store}
        orderType={orderType}
        label={
          orderType === 'PICKUP'
            ? t('orderOptionLabel.pickup', { pickupLabel })
            : t('orderOptionLabel.delivery', { deliveryLabel })
        }
      />
      {dayTimeSkip ? (
        <IncButton
          aria-label={t('locations.card.startAsapOrder.ariaLabel')}
          data-testid="OrderOptionSelect.StartAsapOrder"
          fullWidth
          onClick={() => handleSubmit({ time: firstAvailableTime })}
        >
          {t('locations.card.startAsapOrder.label')}
        </IncButton>
      ) : (
        <ScheduleOrder
          store={store}
          orderType={orderType}
          onTimeClick={handleSubmit}
        />
      )}
    </Box>
  );
};

export default StorePageSelections;
