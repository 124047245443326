import { Divider, Typography } from '@mui/material';
import styled from 'styled-components';

export const Amount = styled(Typography)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 8vw;
  }
`;

export const VerticalDivider = styled(Divider)`
  margin-left: 10px;
  margin-right: 10px;
`;
