import { Box } from '@mui/material';
import { useCloverPaymentsPlaceOrder } from 'components/payment/clover/clover-payments.hooks';
import PlaceOrder from 'pages/checkout/checkout-buttons/place-order/place-order.component';
import { useNewCheckoutButtons } from '../../checkout-buttons-new.hooks';

const CloverCheckoutButtons = ({ insufficientModalSetOpen }) => {
  const { trigger } = useCloverPaymentsPlaceOrder();
  const { showPlaceOrder, handlePlaceOrder, loading } = useNewCheckoutButtons(
    trigger,
    insufficientModalSetOpen,
  );

  return (
    <Box data-testid="CheckoutButtons.Grid">
      {showPlaceOrder && (
        <PlaceOrder handlePlaceOrder={handlePlaceOrder} disabled={loading} />
      )}
    </Box>
  );
};

export default CloverCheckoutButtons;
