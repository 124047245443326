import { Typography, Link } from '@mui/material';
import styled from 'styled-components';

export const LoggedInBodyMessage = styled(Typography)`
  text-align: center;
`;

export const AddToAccountLink = styled(Link)`
  text-decoration: underline;
`;
