import { Typography } from '@mui/material';
import { useDrawerLoginOptions } from 'components/drawer-login-options/drawer-login-options.hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './anon-body.styles';

const AnonBody = () => {
  const { setDrawerState } = useDrawerLoginOptions();
  const { t } = useTranslation();

  return (
    <Typography component="p" align="center">
      {t('eGiftCard.view.anon')}
      <S.LinkButton
        component="button"
        variant="body1"
        onClick={() => setDrawerState(true)}
        data-testid="AnonBody.LinkButton.SignUpOrLogin"
      >
        {t('eGiftCard.view.signUpOrLogin')}
      </S.LinkButton>
    </Typography>
  );
};

export default AnonBody;
