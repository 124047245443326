import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './account-loyalty-card.styles';
import {
  selectLoyaltyCardTitle,
  selectLoyaltyCardButtonName,
  selectLoyaltyCardCodeType,
  selectHideLoyaltyScanCode,
} from '../../redux/config/config.selectors';
import { getLoyaltyAccounts } from '../../redux/user/user.actions';
import CustomDialog from '../custom-dialog/custom-dialog.component';
import Spinner from '../spinner/spinner.component';
import {
  selectFirstName,
  selectIsLoading,
  selectLastName,
  selectLoyaltyExternalId,
} from '../../redux/user/user.selectors';
import { Box } from '@mui/material';
import AccountLoyaltyCardRenderCode from './account-loyalty-card-render-code/account-loyalty-card-render-code.component';
import IncButton from 'components/inc-button/inc-button.component';

const AccountLoyaltyCard = ({ open, handleClose, fetchLoyaltyData = true }) => {
  const { t } = useTranslation();
  const loyaltyCardCodeType = useSelector(selectLoyaltyCardCodeType);
  const loyaltyCardTitle = useSelector(selectLoyaltyCardTitle);
  const loyaltyCardSubTitle = useSelector(selectLoyaltyCardButtonName);
  const isLoading = useSelector(selectIsLoading);
  const externalId = useSelector(selectLoyaltyExternalId);
  const hideLoyaltyScanCode = useSelector(selectHideLoyaltyScanCode);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchLoyaltyData) dispatch(getLoyaltyAccounts());
  }, [dispatch, fetchLoyaltyData]);

  return (
    <CustomDialog
      close={handleClose}
      open={open}
      testId="AccountLoyaltyCard.CustomDialog"
    >
      {isLoading && <Spinner />}
      <S.Title>{loyaltyCardSubTitle}</S.Title>
      <S.SubHead issmallmargin={hideLoyaltyScanCode}>
        {loyaltyCardTitle}
      </S.SubHead>
      <AccountLoyaltyCardRenderCode
        loyaltyCardCodeType={loyaltyCardCodeType}
        externalId={externalId}
      />
      <S.Body data-testid="AccountLoyaltyCard.Username">{`${firstName} ${lastName}`}</S.Body>
      <S.Body data-testid="AccountLoyaltyCard.ExternalId">{externalId}</S.Body>
      {!hideLoyaltyScanCode && (
        <S.Description data-testid="AccountLoyaltyCard.Description">
          {t('accountLoyalty.loyaltyCardDescription')}
        </S.Description>
      )}

      <Box mt={3}>
        <IncButton
          onClick={handleClose}
          data-testid="AccountLoyaltyCard.CustomButton"
          fullWidth
        >
          {t('accountLoyalty.closeAction')}
        </IncButton>
      </Box>
    </CustomDialog>
  );
};

export default AccountLoyaltyCard;
