import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 575px;
  }
`;

export const Content = styled(DialogContent)`
  padding: 2em;
  overflow: unset;
`;

export const Title = styled(Typography)`
  margin-bottom: 0.5em;
`;

export const OriginalImg = styled.img`
  width: 100%;
  height: auto;
`;

export const Close = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  padding: 0;
  top: 1em;
  right: 1em;
  z-index: 3;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
