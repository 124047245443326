import styled from 'styled-components';
import { IconButton } from '@mui/material';

export const CustomButton = styled(IconButton)`
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  width: 23px;
  height: 23px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
