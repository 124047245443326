import { Box } from '@mui/material';
import styled from 'styled-components';

export const DateController = styled(Box)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 95%;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 80%;
  }
`;
