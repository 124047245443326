import { useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
  selectMinAmountGiftCard,
  selectMaxAmountGiftCard,
  selectGiftCardSelectableAmounts,
} from 'redux/config/config.selectors';
import CardValueSelector from './card-value-selector.component';

const CardValueSelectorController = ({ errors, control }) => {
  const { t } = useTranslation();
  const min = useSelector(selectMinAmountGiftCard);
  const max = useSelector(selectMaxAmountGiftCard);
  const presetValues = useSelector(selectGiftCardSelectableAmounts);
  const errorMessage = t('eGiftCard.form.amount.errors.minMax', { min, max });

  return (
    <Controller
      control={control}
      name="amount"
      rules={{
        validate: val => (isNaN(val) ? errorMessage : true),
        min: {
          value: min,
          message: errorMessage,
        },
        max: {
          value: max,
          message: errorMessage,
        },
      }}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <CardValueSelector
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          presetValues={presetValues}
          errors={errors}
        />
      )}
    />
  );
};

export default CardValueSelectorController;
