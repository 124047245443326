import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs.component';
import AccountInboxListView from './account-inbox-list-view/account-inbox-list-view.component';
import { useInbox } from './account-inbox.hooks';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';

const AccountInbox = () => {
  const { t } = useTranslation();
  const props = useInbox();
  const title = usePageTitle(t('head.title.inbox'));

  return (
    <>
      <Helmet title={title} />
      <Breadcrumbs maxWidth="md">
        <Link to="/" component={RouterLink} underline="hover">
          {t('breadcrumbs.home')}
        </Link>
        <Typography aria-label={t('breadcrumbs.inbox.ariaLabel')}>
          {t('breadcrumbs.inbox.title')}
        </Typography>
      </Breadcrumbs>
      <AccountInboxListView {...props} />
    </>
  );
};

export default AccountInbox;
