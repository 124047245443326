import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const TopContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
`;

export const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primaryLight};
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${({ $lineHeight }) => ($lineHeight > 24 ? 3 : 4)};
`;
