import ReactCurrencyInputField from 'react-currency-input-field';
import { TextField, InputAdornment } from '@mui/material';

const CurrencyField = ({ onChange, ...rest }) => {
  return (
    <TextField
      slotProps={{
        htmlInput: {
          step: 0.01,
          allowDecimals: true,
          disableGroupSeparators: true,
          allowNegativeValue: false,
          onValueChange: value => {
            onChange(value ?? '');
          },
          'aria-describedby': 'FormError',
        },
        input: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputComponent: ReactCurrencyInputField,
        },
      }}
      {...rest}
    />
  );
};

export default CurrencyField;
