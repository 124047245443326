import { Box, ButtonBase } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertCentsToDollar } from 'utils';
import * as S from './recommendation-item.styles';
import AddIcon from '@mui/icons-material/Add';
import { useRecommendationsComponent } from '../checkout-recommendations-section.hooks';
import { useDisplayInfo } from 'hooks/useDisplayInfo';

const RecommendationItem = ({ item }) => {
  const { handleItemClicked, quickAddToCart, displayInfo } =
    useRecommendationsComponent(item);

  const { smallImage } = useDisplayInfo(displayInfo);
  const {
    item: { price },
  } = item;
  const { t } = useTranslation();

  return (
    <Box
      data-testid="RecommendationItem.Box"
      position="relative"
      width="fit-content"
    >
      <ButtonBase
        focusRipple
        onClick={handleItemClicked}
        data-testid="RecommendationItem.ButtonBase"
      >
        <S.Container
          borderRadius="4px"
          width="230px"
          overflow="hidden"
          textAlign="left"
        >
          <Box display="flex">
            {smallImage?.[0] && (
              <Box height={S.height} width={S.height} flexShrink={0}>
                <S.Image
                  src={smallImage[0]}
                  alt=""
                  data-testid="RecommendationItem.Image"
                />
              </Box>
            )}
            <Box
              p="6px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <S.Title data-testid="RecommendationItem.Title">
                {displayInfo.title}
              </S.Title>
              <Box fontSize="12px" data-testid="RecommendationItem.Price">
                {price !== 0 ? convertCentsToDollar(price) : ''}
              </Box>
            </Box>
          </Box>
        </S.Container>
      </ButtonBase>
      <Box position="absolute" bottom="12px" right="8px" zIndex={100}>
        <S.QuickAddButton
          size="small"
          aria-label={t('recommendations.quickAddButton.ariaLabel', {
            itemName: displayInfo?.title,
          })}
          onClick={quickAddToCart}
          data-testid="RecommendationItem.QuickAddButton"
        >
          <AddIcon fontSize="small" />
        </S.QuickAddButton>
      </Box>
    </Box>
  );
};

export default RecommendationItem;
