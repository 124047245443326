import { Box } from '@mui/material';
import CloverCard from 'components/payment/clover/clover-card/clover-card.component';
import StripeCardGuest from 'components/payment/incentivio/stripe-card/stripe-card-guest/stripe-card-guest.component';
import { PaymentGatewayType } from 'components/payment/payment.constants';
import { usePaymentGatewayMatches } from 'components/payment/payment.new.hooks';
import SpreedlyCardGuest from 'components/payment/spreedly/spreedly-card-guest/spreedly-card-guest.component';
import SquareCard from 'components/payment/square/square-card/square-card.component';

const GuestCardPanel = () => {
  const paymentGatewayMatches = usePaymentGatewayMatches();

  return (
    <Box data-testid="GuestCardPanel.Box">
      {paymentGatewayMatches([PaymentGatewayType.INCENTIVIO_PAYMENTS]) && (
        <StripeCardGuest />
      )}
      {paymentGatewayMatches([PaymentGatewayType.SPREEDLY]) && (
        <SpreedlyCardGuest />
      )}
      {paymentGatewayMatches([PaymentGatewayType.CLOVER]) && (
        <CloverCard hideSubmitButton />
      )}
      {paymentGatewayMatches([PaymentGatewayType.SQUARE]) && <SquareCard />}
    </Box>
  );
};

export default GuestCardPanel;
