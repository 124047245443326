import * as S from './pickup-location.styles';
import { Typography } from '@mui/material';
import { BoldDescription } from '../info-view/info-view.styles';

const PickupLocation = ({ title, storeTitle, storeAddress }) => {
  return (
    <>
      <Typography>{title}</Typography>
      <BoldDescription>{storeTitle}</BoldDescription>
      <S.StoreAddress>{storeAddress}</S.StoreAddress>
    </>
  );
};

export default PickupLocation;
