import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { selectRequestedFulfillTime } from 'redux/cart/cart.selectors';
import OrderDateTime from 'components/order-date-time/order-date-time.component';
import AsapDurationLabel from 'components/asap-duration-label/asap-duration-label.component';
import { selectAsapDuration } from 'redux/data/orderavailability/orderavailability.selectors';

const OrderFulfillTime = () => {
  const requestedFulfillTime = useSelector(selectRequestedFulfillTime);
  const asapDuration = useSelector(selectAsapDuration);

  return (
    <Box fontSize="11px" ml="4px">
      {requestedFulfillTime === 'asap' ? (
        <AsapDurationLabel asapDuration={asapDuration} />
      ) : (
        <>
          (<OrderDateTime />)
        </>
      )}
    </Box>
  );
};

export default OrderFulfillTime;
