import {
  spreedlyDefaultSchema,
  spreedlyToastSchema,
} from './forms/spreedly-form.schema';

export const getSchema = formName => {
  switch (formName) {
    case 'spreedly':
      return spreedlyDefaultSchema;
    case 'toast_spreedly':
    case 'toast_spreedly_canada':
      return spreedlyToastSchema;
    default:
      throw new Error('Spreedly Configuration Error - Unknown Form Name');
  }
};
