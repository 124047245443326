import { Link, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
  background-color: #fff;
`;

export const Text = styled(Typography)`
  font-size: 0.8rem;
`;

export const Span = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const TermsAndConditionsLink = styled(Link)`
  vertical-align: inherit;
  font-size: 0.8rem;
`;
