import { Box, useTheme } from '@mui/material';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { usePaymentRequest } from '../incentivio-payments.hooks';

const StripeWalletButton = ({ validate = () => true }) => {
  const theme = useTheme();
  const { paymentRequest, loading } = usePaymentRequest();

  const enabled = !!paymentRequest && !loading;

  if (!enabled) return false;

  return (
    <Box position="relative">
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              theme: theme.palette.type === 'light' ? 'dark' : 'light',
              height: '42px',
            },
          },
        }}
        onClick={
          /* istanbul ignore next */ async event => {
            try {
              event.preventDefault();
              await validate();
              paymentRequest.show();
            } catch (e) {
              return false;
            }
          }
        }
      />
    </Box>
  );
};

export default StripeWalletButton;
