import { useTranslation } from 'react-i18next';
import SpreedlyField from './spreedly-field.component';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

const StateField = () => {
  const { t } = useTranslation();
  const country = useWatch({ name: 'country' });

  const label = useMemo(
    () =>
      country === 'CAN'
        ? t('checkout.payment.spreedly.fields.province.label')
        : t('checkout.payment.spreedly.fields.state.label'),
    [country, t],
  );

  return (
    <SpreedlyField
      name="state"
      label={label}
      required
      autoComplete="billing address-level1"
    />
  );
};
export default StateField;
