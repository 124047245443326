import { Typography } from '@mui/material';
import styled from 'styled-components';

export const ThankYouWrapper = styled.div`
  text-align: center;
`;

export const ThankyouMessage = styled(Typography)`
  font-weight: 600;
`;
