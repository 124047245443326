import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

const SpreedlyField = ({ name, label, ...rest }) => {
  const {
    field: { ref, ...fieldRest },
    fieldState: { error },
  } = useController({ name });

  return (
    <TextField
      id={name}
      label={label}
      placeholder={label}
      size="small"
      error={!!error}
      helperText={error?.message}
      slotProps={{
        inputLabel: {
          shrink: true,
          style: { fontSize: `calc(0.875rem * 4/3)` },
        },
      }}
      inputRef={ref}
      {...fieldRest}
      {...rest}
    />
  );
};
export default SpreedlyField;
