import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import styled from 'styled-components';

export const OrderTypeToggleGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-wrap: wrap;
`;

export const ForceOrderTypeToggleGroup = styled(ToggleButtonGroup)`
  display: grid;
  width: 100%;
`;

const OrderTypeButton = styled(ToggleButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 100px !important;
  border: 0;
  text-transform: capitalize;
  font-weight: bold;
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export const OrderTypeToggleButton = styled(OrderTypeButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette?.text?.primary};
  height: 40px;
  flex-grow: 1;
  margin: 0 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .MuiToggleButton-label {
    line-height: normal;
  }
`;

export const ForceOrderTypeToggleButton = styled(OrderTypeButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-bottom: 1em;
`;
