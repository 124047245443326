import { Box, Typography } from '@mui/material';
import IncButton from 'components/inc-button/inc-button.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './payment-error-fallback.styles';

const PaymentErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flex="1"
      mt="1rem"
      data-testid="PaymentErrorFallback.Box"
    >
      <S.ErrorBox>
        <Typography>{t('payment.errorFallback.1')}</Typography>
        <Typography>{t('payment.errorFallback.2')}</Typography>
        {error?.message && (
          <Typography>
            <strong>
              {t('payment.errorFallback.error')}: {error.message}
            </strong>
          </Typography>
        )}
        <IncButton
          onClick={resetErrorBoundary}
          data-testid="PaymentErrorFallback.IncButton.Retry"
        >
          {t('payment.errorFallback.retryButton')}
        </IncButton>
      </S.ErrorBox>
    </Box>
  );
};

export default PaymentErrorFallback;
