import React from 'react';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { selectMapPin } from 'redux/config/config.selectors';
import { useLocationsMapIsGrayOut } from '../locations-map.hooks';
import * as S from './locations-map-marker.styles';

const LocationsMapMarker = ({ store, setSelectedStore }) => {
  const mapPin = useSelector(selectMapPin);
  const isGrayOut = useLocationsMapIsGrayOut(store);

  const handleClick = () => {
    setSelectedStore(store);
    setTimeout(() => {
      scroller.scrollTo(store.storeId, {
        smooth: true,
        containerId: 'locationsListContainer',
        offset: -5,
      });
    }, 10);
  };

  if (!!mapPin) {
    return (
      <S.PinImage
        src={mapPin}
        onClick={handleClick}
        data-testid={
          isGrayOut
            ? 'LocationsMapMarker.PinImage.GrayOut'
            : 'LocationsMapMarker.PinImage'
        }
        alt=""
        $isGrayOut={isGrayOut}
      />
    );
  }

  return (
    <>
      <S.Pin
        color="primary"
        fontSize="large"
        onClick={handleClick}
        data-testid={
          isGrayOut
            ? 'LocationsMapMarker.Pin.GrayOut'
            : 'LocationsMapMarker.Pin'
        }
        $isGrayOut={isGrayOut}
      />
      <S.Pulse $isGrayOut={isGrayOut} />
    </>
  );
};

export default LocationsMapMarker;
