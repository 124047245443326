import { Box, Typography } from '@mui/material';
import CardValueSelectorController from 'components/card-value-selector/card-value-selector.controller';
import Form from 'components/form/form.component';
import PaymentSelectorWrapper from 'components/payment/payment-selector/payment-selector-wrapper/payment-selector-wrapper.component';
import Payment from 'components/payment/payment.component';
import { useGiftCardPayment } from 'components/payment/payment.new.hooks';
import { makeUseLoading } from 'hooks/useLoader';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectClientName,
  selectGiftCardSelectableAmounts,
  selectMaxAmountGiftCard,
  selectMinAmountGiftCard,
} from 'redux/config/config.selectors';
import {
  addGiftCardValue,
  buyGiftCard,
  fetchPaymentInstrumentsStartAsync,
} from '../../../../redux/user/user.actions';
import {
  selectIsCardLoading,
  selectPaymentInstrumentsCards,
} from '../../../../redux/user/user.selectors';
import Spinner from '../../../spinner/spinner.component';
import GiftcardAddValueButtons from './giftcard-add-value-buttons/giftcard-add-value-buttons.component';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

export const usePurchaseGiftcardLoading = makeUseLoading();

const GiftCardAddValue = ({
  handleClose,
  paymentInstrumentId,
  submitLabel,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cards = useSelector(selectPaymentInstrumentsCards);
  const minAmount = useSelector(selectMinAmountGiftCard);
  const maxAmount = useSelector(selectMaxAmountGiftCard);
  const [disabled, setDisabled] = useState(true);
  const defaultPayment = cards.find(({ isDefault }) => isDefault);
  const [cardId, setCardId] = useState(
    defaultPayment
      ? defaultPayment.paymentInstrumentId
      : cards && cards.length
      ? cards[0].paymentInstrumentId
      : null,
  );
  const isLoading = useSelector(selectIsCardLoading);
  const paymentLoading = usePurchaseGiftcardLoading(state => state.loading);
  const loading = useMemo(
    () => isLoading || paymentLoading,
    [isLoading, paymentLoading],
  );

  useEffect(() => {
    setDisabled(!cardId);
  }, [cardId]);

  const presetValues = useSelector(selectGiftCardSelectableAmounts);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: presetValues[0],
    },
  });

  const onSubmit = ({ amount }) => {
    setDisabled(true);

    dispatch(
      paymentInstrumentId
        ? addGiftCardValue(amount * 1000, cardId, paymentInstrumentId)
        : buyGiftCard(amount * 1000, cardId),
    ).then(res => {
      setDisabled(false);

      if (res) {
        handleClose();
      }
    });
  };

  const handlePaymentSelection = newCardId => {
    setCardId(newCardId);
  };

  const amount = watch('amount');
  const clientName = useSelector(selectClientName);
  const walletLabel = useMemo(
    () => t('payment.giftCardWalletLabel', { clientName }),
    [clientName, t],
  );
  const afterWalletPay = () => {
    dispatch(fetchPaymentInstrumentsStartAsync());
    handleClose();
  };
  const paymentContext = useGiftCardPayment(
    amount * 1000,
    walletLabel,
    paymentInstrumentId,
    afterWalletPay,
  );

  const submit = e => {
    e.preventDefault();
    if (e.target?.id !== 'payment-form') {
      handleSubmit(onSubmit)();
    }
  };

  const validate = async () => {
    const result = await trigger();
    if (!result) throw new Error();
  };

  return (
    <Box data-testid="GiftCardAddValue.Box">
      {loading && <Spinner />}

      <Typography gutterBottom>
        {t('addPurchaseGiftCard.purchaseCard.minMax', { minAmount, maxAmount })}
      </Typography>
      <Payment value={paymentContext}>
        <Form onSubmit={submit}>
          <MandatoryFieldsInstruction />
          <CardValueSelectorController control={control} errors={errors} />
          <PaymentSelectorWrapper
            handleSelection={handlePaymentSelection}
            selection={cardId}
            cards={cards}
          />
          <GiftcardAddValueButtons
            handleClose={handleClose}
            submitDisabled={disabled}
            submitLabel={submitLabel}
            validate={validate}
            selection={cardId}
            cardIdentifier={paymentInstrumentId}
          />
        </Form>
      </Payment>
    </Box>
  );
};

export default GiftCardAddValue;
