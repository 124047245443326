import styled from 'styled-components';
import Link from '@mui/material/Link';

const ActionLink = styled(Link)`
  font-size: 1rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.text.primary};
`;

ActionLink.defaultProps = { component: 'button', type: 'button' };

export default ActionLink;
