import { useGate } from 'statsig-react';
import CloverCard from '../clover/clover-card/clover-card.component';
import StripeCard from '../incentivio/stripe-card/stripe-card.component';
import { PaymentGatewayType } from '../payment.constants';
import { usePaymentGatewayMatches } from '../payment.new.hooks';
import SpreedlyCard303942 from '../spreedly/spreedly-card/spreedly-card-303942.component';
import SpreedlyCard from '../spreedly/spreedly-card/spreedly-card.component';
import SquareCard from '../square/square-card/square-card.component';

const CardPayment = ({ ...rest }) => {
  const matchesPaymentGateway = usePaymentGatewayMatches();
  const { value: isSpreedlyFormRefactor, isLoading } = useGate(
    'spreedly-form-refactor',
  );

  if (matchesPaymentGateway([PaymentGatewayType.SPREEDLY])) {
    /* istanbul ignore next */
    if (isLoading) return null;
    return isSpreedlyFormRefactor ? (
      <SpreedlyCard303942 {...rest} button />
    ) : (
      <SpreedlyCard {...rest} />
    );
  }

  if (matchesPaymentGateway([PaymentGatewayType.SQUARE]))
    return <SquareCard {...rest} />;

  if (matchesPaymentGateway([PaymentGatewayType.CLOVER]))
    return <CloverCard {...rest} />;

  return <StripeCard {...rest} />;
};

export default CardPayment;
