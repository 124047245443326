/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from '@mui/material';
import { NewPaymentContext } from 'components/payment/payment.context';
import Spinner from 'components/spinner/spinner.component';
import React, { useContext, useEffect, useState } from 'react';
import SpreedlyContext from '../spreedly.context';
import * as S from './spreedly-card.styles';
import { useErrorHandler } from 'react-error-boundary';
import { useSpreedlyScriptHook } from './spreedly-card.hooks';
import SpreedlySaveCardCheckbox from './spreedly-save-card-checkbox/spreedly-save-card-checkbox.component';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/user/user.selectors';
import { useIsNewSpreedlyCardFlow } from 'components/payment/payment-selector/credit-card-panel/credit-card-panel-controller/credit-card-panel-controller.hooks';
import { useTranslation } from 'react-i18next';

const SpreedlyCard = ({ handleCardInfo }) => {
  const { t } = useTranslation();
  const { initOrderResponse } = useContext(NewPaymentContext);
  const html = initOrderResponse?.attributes?.hostedPaymentPageHtml;
  const [script, setScript] = useState(null);
  const [url, setUrl] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const { setCardInfo } = useContext(SpreedlyContext);
  const handleError = useErrorHandler();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isNewSpreedlyCardFlow = useIsNewSpreedlyCardFlow();
  useSpreedlyScriptHook({ url, script });

  const respMsg = async e => {
    if (
      e &&
      e.data &&
      typeof e.data === 'string' &&
      e.data.includes('tokenRecieved')
    ) {
      const dataArray = e.data.split('tokenRecieved--');
      const formData = JSON.parse(dataArray[1]);
      const { token, full_name, month, year } = formData;

      setShowSpinner(true);
      if (!!handleCardInfo) {
        await handleCardInfo({ token, full_name, month, year });
      } else {
        const savePaymentInstrument = document.getElementById(
          'savePaymentInstrument',
        )?.checked;
        setCardInfo({
          token,
          savePaymentInstrument,
          cardNickname: savePaymentInstrument ? full_name : undefined,
        });
      }
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    try {
      const x = html.replace(/(\r\n|\n|\r)/gm, '');
      const extractUrl = /<script src="([^"]+)" id="iframeSrc"/gm.exec(x);
      const extractscript =
        /<script id="spreedly-script">(.+)<\/script>/gm.exec(x);
      setScript(extractscript[1]);
      setUrl(extractUrl[1]);
    } catch (e) {
      handleError(e);
    }

    window.addEventListener('message', respMsg, false);

    return () => {
      window.removeEventListener('message', respMsg, false);
    };
  }, []);

  if (!script || !url || !html) return null;

  return (
    <Box position="relative">
      {showSpinner && <Spinner />}
      <S.Container
        dangerouslySetInnerHTML={{
          __html: html,
        }}
        data-testid="SpreedlyCard.Container"
      />

      {isLoggedIn && isNewSpreedlyCardFlow && (
        <SpreedlySaveCardCheckbox
          label={t('payment.saveOption')}
          id="savePaymentInstrument"
        />
      )}
    </Box>
  );
};

export default SpreedlyCard;
