import { IncButtonLight } from 'components/inc-button/inc-button.styles';
import styled from 'styled-components';
import Icon from '@mui/icons-material/Apple';

export const SignupButton = styled(IncButtonLight)`
  border-color: #000;
  .MuiButton-startIcon {
    margin-top: -3px;

    .MuiSvgIcon-root {
      font-size: 30px;
    }
  }

  && {
    margin: 0;
  }
`;

export const AppleIcon = styled(Icon)`
  width: 30px;
  height: 30px;
`;
