import 'redux/store';
import InputLabel from '@mui/material/InputLabel';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { selectIsLoading } from '../../redux/user/user.selectors';
import CustomInput from '../custom-input/custom-input.component';
import FormError from '../form-error/form-error.component';
import Form from '../form/form.component';
import InputPhone from '../input-phone/input-phone.component';
import Spinner from '../spinner/spinner.component';
import FirstName from 'components/form/form-fields/first-name.component';
import LastName from 'components/form/form-fields/last-name.component';
import TrimEmailField from 'components/form/form-fields/trim-email-field/trim-email-field.component';
import { setCustomerInfo } from 'redux/cart/cart.actions';
import TocAndPrivacy from 'components/toc-and-privacy/toc-and-privacy.component';
import { SubmitButton } from './guest-fields.styles';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';

const GuestFields = ({ onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const isCheckout = useRouteMatch(['/checkout']);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = data => {
    dispatch(setCustomerInfo(data));
    onSuccess();
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isLoading && !isCheckout && <Spinner fade={false} />}

          <MandatoryFieldsInstruction />

          <FirstName register={register} errors={errors} />

          <LastName register={register} errors={errors} />

          <CustomInput>
            <InputLabel htmlFor="email">{t('guest.label.email')}*</InputLabel>
            <TrimEmailField
              id="email"
              fullWidth
              placeholder={t('register.placeholder.email')}
              error={!!errors.email}
              inputProps={{ autoComplete: 'email', 'aria-required': true }}
              {...register('email', {
                required: t('register.errors.requiredField'),
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: t('forms.errors.email.invalid'),
                },
              })}
            />
            {errors.email && <FormError errorMessage={errors.email.message} />}
          </CustomInput>

          <InputPhone isGuest />

          <SubmitButton type="submit" fullWidth>
            {t('guest.label.continue')}
          </SubmitButton>
        </Form>
      </FormProvider>
      <TocAndPrivacy description={t('guest.termsAndConditions.description')} />
    </>
  );
};

export default GuestFields;
