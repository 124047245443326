import { Box } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import MissingAddressFields from './missing-address-fields.components/missing-address-fields.component';
import AptSuite from './apt-suite/apt-suite.component';
import AddressAlias from './address-alias/address-alias.component';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddressAutocompleteField from '../address-autocomplete-field/address-autocomplete-field.component';

const AddressFields = ({
  aliasEnabled,
  aliasName,
  aptSuiteName,
  addressName,
  address,
  errors,
  missingFieldsData,
}) => {
  const { watch, getValues, setValue, trigger } = useFormContext();
  const autoCompleteAddress = watch(addressName);

  useEffect(() => {
    if (!!autoCompleteAddress?.main_text && !getValues(aliasName)) {
      setValue(aliasName, autoCompleteAddress.main_text);
      trigger(aliasName);
    }
  }, [aliasName, autoCompleteAddress, getValues, setValue, trigger]);

  return (
    <Box>
      <AddressAutocompleteField
        name={addressName}
        defaultValue={address}
        autocompleteProps={{
          popupIcon: <LocationOnIcon />,
          ListboxProps: {
            'data-testid': 'AddressAutocomplete.CustomAutocomplete.Listbox',
          },
          'data-testid': 'AddressAutocomplete.CustomAutocomplete',
        }}
        autocompleteInputProps={{
          'aria-required': 'true',
        }}
      />

      <MissingAddressFields
        errors={errors}
        address={autoCompleteAddress}
        missingFieldsData={missingFieldsData}
      />

      <AptSuite defaultValue={address?.aptSuite} aptSuiteName={aptSuiteName} />

      {aliasEnabled && (
        <AddressAlias
          defaultValue={address?.addressAlias}
          aliasName={aliasName}
        />
      )}
    </Box>
  );
};

export default AddressFields;
