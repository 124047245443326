import { useCheckoutPaymentLoading } from 'pages/checkout/checkout.component';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SpreedlyContext from '../../spreedly.context';
import {
  spreedlyCvvName,
  spreedlyIframeFields,
  spreedlyNumberName,
} from './spreedly-form.component';
import SpreedlyFormContext from './spreedly-form.context';
import { NewPaymentContext } from 'components/payment/payment.context';
import { useTheme } from '@mui/material';
import i18n from 'i18n';

export const useSpreedlyForm = handleCardInfo => {
  const addPromise = useCheckoutPaymentLoading(state => state.addPromise);
  const setPaymentLoading = useCheckoutPaymentLoading(
    state => state.setLoading,
  );
  const { initOrderResponse } = useContext(NewPaymentContext);
  const envKey = initOrderResponse?.attributes?.SPREEDLY_ENV_KEY;
  const [errors, setErrors] = useState({});
  const { setCardInfo } = useContext(SpreedlyContext);
  const form = useFormContext();
  const inputStyle = `width: 100%; height: 100%; font-size: 16px; padding: 3px 0px;`;
  const theme = useTheme();

  useEffect(() => {
    let previousFieldName;

    window.Spreedly.init(envKey, {
      numberEl: spreedlyNumberName,
      cvvEl: spreedlyCvvName,
    });

    window.Spreedly.on('ready', () => {
      window.Spreedly.setStyle('number', inputStyle);
      window.Spreedly.setStyle('cvv', inputStyle);
      window.Spreedly.setRequiredAttribute('number');
      window.Spreedly.setRequiredAttribute('cvv');
    });

    window.Spreedly.on(
      'fieldEvent',
      (name, event, _activeElement, inputData) => {
        if (typeof inputData.validCvv !== 'undefined') {
          form.setValue('isValidCVV', inputData.validCvv);
        }
        if (event === 'blur') {
          previousFieldName = name;
          window.Spreedly.validate();
        }
      },
    );

    window.Spreedly.on('validation', validation => {
      setErrors(prev =>
        previousFieldName
          ? {
              ...prev,
              [previousFieldName]: setFieldValidation(
                previousFieldName,
                validation,
              ),
            }
          : {
              ...prev,
              number: setFieldValidation('number', validation),
              cvv: setFieldValidation('cvv', validation),
            },
      );
    });

    window.Spreedly.on(
      'errors',
      handleErrors(setPaymentLoading, setErrors, form),
    );

    window.Spreedly.on('paymentMethod', async (_token, paymentMethod) => {
      const { token, full_name, month, year } = paymentMethod;
      if (!!handleCardInfo) {
        await addPromise(handleCardInfo({ token, full_name, month, year }));
      } else {
        // I cannot, for the life of me, get form values in here. They are
        // always undefined. So, I'm just querying the DOM directly for the checkbox.
        const savePaymentInstrument = document.getElementById(
          'savePaymentInstrument',
        )?.checked;
        setCardInfo({
          token,
          savePaymentInstrument,
          cardNickname: savePaymentInstrument ? full_name : undefined,
        });
      }
    });

    return () => {
      window.Spreedly.removeHandlers();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (theme.palette.type === 'dark') {
      window.Spreedly.setStyle(
        'number',
        `${inputStyle};color: ${theme.palette.text.primary};`,
      );
      window.Spreedly.setStyle(
        'cvv',
        `${inputStyle};color: ${theme.palette.text.primary};`,
      );
    } else {
      window.Spreedly.setStyle('number', inputStyle);
      window.Spreedly.setStyle('cvv', inputStyle);
    }
  }, [inputStyle, theme.palette.text.primary, theme.palette.type]);

  return { errors };
};

const setFieldValidation = (name, validation) => {
  if (name === 'number') {
    return validation.validNumber
      ? null
      : i18n.t('checkout.payment.spreedly.errors.number');
  }
  if (name === 'cvv') {
    return validation.validCvv
      ? null
      : i18n.t('checkout.payment.spreedly.errors.cvv');
  }
};

export const useSpreedlyIframeField = name => {
  const { errors } = useContext(SpreedlyFormContext);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(errors[spreedlyIframeFields[name]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return { error };
};

export const handleErrors = (setPaymentLoading, setErrors, form) => errors => {
  setPaymentLoading(false);
  errors.forEach(error => {
    if (error.attribute === 'number' || error.attribute === 'cvv') {
      setErrors(prev => ({
        ...prev,
        [error.attribute]: error.message,
      }));
      window.Spreedly.transferFocus(error.attribute);
    } else {
      form.setError(error.attribute, {
        type: 'manual',
        message: error.message,
      });
    }
  });
};
