import { CreditCard } from '@mui/icons-material';
import { PaymentOptionType } from 'components/payment/payment.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentOption from './payment-option.component';

const CardPaymentOption = React.forwardRef(({ ...rest }, ref) => {
  const { t } = useTranslation();
  const title = t('paymentSelector.paymentOption.creditCard.title');

  return (
    <PaymentOption
      icon={<CreditCard />}
      title={title}
      type={PaymentOptionType.CARD}
      {...rest}
      ref={ref}
    />
  );
});
export default CardPaymentOption;
