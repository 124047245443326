import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export const Container = styled(Box)`
  position: relative;
  padding: 1em;
  flex-grow: 1;
`;

export const AllLocationsDeliveryError = styled(Typography)`
  margin: 10px 0;
`;
