import { Box } from '@mui/material';
import React from 'react';
import Theme from 'Theme';
import { useViewOfferInbox } from './view-offer-inbox.hooks';
import AccountInboxItem from 'pages/account-inbox/account-inbox-item/account-inbox-item.component';

const ViewOfferInbox = () => {
  const { offer } = useViewOfferInbox();
  const dummyFunc = () => null;

  return (
    <Theme>
      <Box data-testid="ViewOffer.Inbox" p={2}>
        <AccountInboxItem
          setModalOpen={dummyFunc}
          handleDelete={dummyFunc}
          handleClose={dummyFunc}
          message={offer}
          setIsContentDialogOpen={dummyFunc}
        />
      </Box>
    </Theme>
  );
};

export default ViewOfferInbox;
