import { Box } from '@mui/material';
import * as S from './future-order-banner.styles';
import { useTranslation } from 'react-i18next';
import { useNewOrderForm } from 'pages/store/new-order-form/useNewOrderForm.hooks';

const FutureOrderBanner = ({ storeId, isTodayOff }) => {
  const { t } = useTranslation();
  const { orderAvailabilityError } = useNewOrderForm(storeId);

  if (!isTodayOff || orderAvailabilityError) return null;

  return (
    <Box mb={1}>
      <S.Banner icon={false} data-testid="DayTimeSelector.Banner">
        {t('locations.card.futureOrderBanner.text')}
      </S.Banner>
    </Box>
  );
};

export default FutureOrderBanner;
