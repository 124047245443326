import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import CustomInput from 'components/custom-input/custom-input.component';
import MessagesDialog from 'components/messages-dialog/messages-dialog.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityBasedContent } from 'redux/user/user.actions';
import {
  selectActivityBasedContentOffers,
  selectAllActivityBasedContent,
} from 'redux/user/user.selectors';
import IncButton from 'components/inc-button/inc-button.component';
import TrimTextField from 'components/form/form-fields/trim-text-field/trim-text-field.component';
import { FormProvider, useForm } from 'react-hook-form';
import MandatoryFieldsInstruction from 'components/form/mandatory-fields-instruction/mandatory-fields-instruction.component';
import { ErrorMessage } from '@hookform/error-message';
import FormError from 'components/form-error/form-error.component';

const PromoCode = ({ open, handleClose, checkoutPromoCode, onRetrieve }) => {
  const { t } = useTranslation();
  const [offerOpen, setOfferOpen] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const allActivityBasedContent = useSelector(selectAllActivityBasedContent);
  const activityBasedContentOffers = useSelector(
    selectActivityBasedContentOffers,
  );

  const messages = checkoutPromoCode
    ? activityBasedContentOffers
    : allActivityBasedContent;

  const handleApplyPromo = async data => {
    const result = await dispatch(
      getActivityBasedContent(null, null, data.promoCode),
    );
    onRetrieve && onRetrieve(result);
    setOfferOpen(true);

    handleClose();
  };

  const name = 'promoCode';

  return (
    <>
      <CustomDialog
        title="Enter Promo Code"
        testId="PromoCode.CustomDialog"
        open={open}
        close={handleClose}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleApplyPromo)}>
            <MandatoryFieldsInstruction />
            <CustomInput>
              <InputLabel htmlFor="promoCode">
                {t('payment.promoCode.label')}*
              </InputLabel>
              <TrimTextField
                id="promoCode"
                inputProps={{
                  'aria-required': true,
                }}
                {...register(name, { required: t('errors.requiredField') })}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocalOfferIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <FormError errorMessage={message} />}
              />
            </CustomInput>
            <Box mt={3}>
              <IncButton
                data-testid="PromoCode.CustomButton"
                type="submit"
                fullWidth
              >
                {t('payment.promoCode.submitAction')}
              </IncButton>
            </Box>
          </form>
        </FormProvider>
      </CustomDialog>
      <MessagesDialog
        open={offerOpen}
        setOpen={setOfferOpen}
        messages={messages}
        testId="PromoCode.MessagesDialog"
        checkoutPromoCode={checkoutPromoCode}
      />
    </>
  );
};

export default PromoCode;
