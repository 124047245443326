import { Box } from '@mui/material';
import CustomDialog from 'components/custom-dialog/custom-dialog.component';
import React from 'react';
import CardPayment from '../card-payment/card-payment.component';
import { useTranslation } from 'react-i18next';

const CardPaymentModal = ({ title, handleClose, open, ...rest }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      close={handleClose}
      open={open}
      title={title ?? t('payment.cardModal.title')}
      testId="AddPayment.CustomDialog"
    >
      <Box minHeight="150px">
        <CardPayment {...rest} />
      </Box>
    </CustomDialog>
  );
};

export default CardPaymentModal;
