import { useEffect } from 'react';

export const useUncaughtResizeError = () => {
  useEffect(() => {
    const hideError = e => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
      }
    };

    window.addEventListener('error', hideError);
  }, []);
};
