import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectLocale } from 'redux/i18n/i18n.selectors';
import { useApplePay } from '../apple-pay.hooks';
import './apple-pay-button.css';

const SpreedlyToastApplePayButton = ({ validate = () => true }) => {
  const { enabled, loading, scriptError, createApplePaySession } =
    useApplePay();
  const locale = useSelector(selectLocale);
  const theme = useTheme();

  const className = useMemo(() => {
    const arr = ['apple-pay-button'];
    if (theme.palette.type === 'light') {
      arr.push('apple-pay-button-black');
    } else {
      arr.push('apple-pay-button-white');
    }
    return arr.join(' ');
  }, [theme.palette.type]);

  const onClick = async () => {
    try {
      await validate();
      const applePaySession = createApplePaySession();
      applePaySession.begin();
    } catch (e) {
      toast.error(e.message);
    }
  };

  if (loading || scriptError || !enabled) return null;

  return (
    <div>
      <button
        className={className}
        lang={locale}
        onClick={onClick}
        data-testid="SpreedlyToastApplePayButton"
      ></button>
    </div>
  );
};

export default SpreedlyToastApplePayButton;
