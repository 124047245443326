import styled from 'styled-components';
import Button from '@mui/material/Button';

export const BackButton = styled(Button)`
  padding: 0;
  margin-bottom: 0.5em;

  .MuiButton-label {
    text-transform: none;
  }

  .MuiSvgIcon-root {
    margin-right: 0.2em;
  }
`;
