import { FormControlLabel, Switch } from '@mui/material';
import { forwardRef } from 'react';

const CustomSwitch = forwardRef(({ label, dataTestId, ...rest }, ref) => {
  return (
    <FormControlLabel
      control={<Switch color="primary" inputRef={ref} {...rest} />}
      label={label}
      data-testid={dataTestId}
    />
  );
});

export default CustomSwitch;
