import { Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomInput from 'components/custom-input/custom-input.component';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const OrderOptionSelect = ({
  defaultValue = {},
  sortedOptions = [],
  label,
  name,
}) => {
  const { t } = useTranslation();
  const { control, register, getValues, setValue } = useFormContext();

  useEffect(() => {
    if (sortedOptions?.length > 0) {
      register(name);
      setValue(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOptions]);

  if (sortedOptions.length === 0) return null;
  else if (sortedOptions.length === 1)
    return (
      <Typography data-testid="OrderOptionSelect.Typography.SingleOption">
        {getValues(name)?.title}
      </Typography>
    );
  else
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, ref, name, value } }) => (
          <CustomInput>
            <InputLabel
              id={`${name}-label`}
              data-testid="OrderOptionSelect.InputLabel"
            >
              {label}
            </InputLabel>

            <Select
              name={`${name}-select`}
              fullWidth
              value={value}
              labelId={`${name}-label`}
              renderValue={value => value?.title}
              onChange={(event, child) => {
                onChange(event, child);
                setValue(name, event.target.value);
              }}
              onBlur={onBlur}
              SelectDisplayProps={{
                'data-testid': 'OrderOptionSelect.Select',
              }}
              MenuProps={{
                MenuListProps: {
                  'data-testid': 'OrderOptionSelect.Menu.OrderOptions',
                  'aria-label': t('orderOptionSelect.inputMenu'),
                },
              }}
              inputRef={ref}
            >
              {sortedOptions &&
                sortedOptions.map((option, index) => (
                  <MenuItem key={`${option.optionName}${index}`} value={option}>
                    {option.title}
                  </MenuItem>
                ))}
            </Select>
          </CustomInput>
        )}
      />
    );
};

export default OrderOptionSelect;
