import React, { useEffect, useState, useCallback, useContext } from 'react';
import * as S from './locations-card.styles';
import LocationsCardOverview from './locations-card-overview/location-card-overview.component';
import { CARD_STEP } from '../locations.constants';
import { useDispatch, useSelector } from 'react-redux';
import BackAction from 'components/back-action/back-action.component';
import { setOrderOption } from 'redux/cart/cart.actions';
import LocationsCardHeader from './locations-card-header/locations-card-header.component';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import StoreShortDescription from 'components/store/store-short-description/store-short-description.component';
import LocationsCardSelections from './locations-card-selections/locations-card-selections.component';
import { CardContent } from '@mui/material';
import {
  OrderFormContext,
  OrderTypeContext,
} from 'pages/store/new-order-form/order-form-context';
import { useOrderForm } from 'pages/store/new-order-form/useOrderFormContext.hooks';
import { useIsStoreNotWithinDeliveryDistance } from 'hooks/useIsStoreWithinDeliveryDistance';
import { selectors } from 'redux/app/app.slice';
import { CATERING } from 'util/constants';
import { useTranslation } from 'react-i18next';
import { isLocationHasOrderOptions } from './locations-card-selections/locations-card-selections.utils';
import { useIsViewOnlyLocationEnabled } from 'hooks/useIsViewOnlyLocationEnabled';

const LocationsFinderCard = ({
  store,
  cardStep = CARD_STEP.OVERVIEW,
  reorder,
  disabled,
  selectedStore,
  setSelectedStore,
  ...rest
}) => {
  const [step, setStep] = useState(cardStep);
  const dispatch = useDispatch();
  const { shortDescription } = useDisplayInfo(store.displayInfo);
  const viewOnlyLocationEnabled = useIsViewOnlyLocationEnabled(store);
  const orderType = useContext(OrderTypeContext);
  const orderFormContext = useOrderForm();
  const { t } = useTranslation();

  const isStoreNotWithinDeliveryDistance =
    useIsStoreNotWithinDeliveryDistance(store);

  const locationType = useSelector(selectors.selectLocationsLocationType);

  const outOfRangeDisableStyling =
    isStoreNotWithinDeliveryDistance && locationType !== CATERING && !reorder;

  const handleStep = useCallback(
    (requestedStep, orderType) => {
      if (
        requestedStep === CARD_STEP.ORDER_OPTIONS &&
        !isLocationHasOrderOptions(orderType, store)
      ) {
        dispatch(setOrderOption(null));
        orderFormContext.setOptions();
        setStep(CARD_STEP.TIME_DAY_SELECTION);
      } else {
        setStep(requestedStep);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, store],
  );

  useEffect(() => {
    if (
      step !== CARD_STEP.OVERVIEW &&
      selectedStore?.storeId !== store.storeId
    ) {
      setStep(CARD_STEP.OVERVIEW);
    }
  }, [selectedStore, step, store.storeId]);

  if (viewOnlyLocationEnabled)
    return (
      <S.LocationCard
        tabIndex="0"
        aria-label={t('locations.card.ariaLabel')}
        data-testid="LocationsFinderCard.LocationCard"
      >
        <S.Content data-testid="LocationsFinderCard.CardContent">
          <LocationsCardHeader store={store} />
          <StoreShortDescription shortDescription={shortDescription} />
        </S.Content>
        <LocationsCardOverview store={store} />
      </S.LocationCard>
    );

  if (disabled || outOfRangeDisableStyling) {
    return (
      <S.LocationCardDisabled
        name={store.storeId}
        tabIndex="0"
        aria-label={t('locations.card.ariaLabel')}
        data-testid="LocationsFinderCard.LocationCard"
      >
        <S.Content data-testid="LocationsFinderCard.CardContent">
          <LocationsCardHeader store={store} />
          <StoreShortDescription shortDescription={shortDescription} />
        </S.Content>
        <LocationsCardOverview store={store} handleStep={handleStep} disabled />

        {outOfRangeDisableStyling && (
          <S.OutOfRangeBadge>{t('deliveryDistance.text')}</S.OutOfRangeBadge>
        )}
      </S.LocationCardDisabled>
    );
  }

  return (
    <S.LocationCard
      name={store.storeId}
      className={selectedStore?.storeId === store.storeId ? 'active' : null}
      tabIndex="0"
      aria-label={t('locations.card.ariaLabel')}
      data-testid="LocationsFinderCard.LocationCard"
    >
      <S.Content data-testid="LocationsFinderCard.CardContent">
        <LocationsCardHeader store={store} />
        <StoreShortDescription shortDescription={shortDescription} />
        {step !== CARD_STEP.OVERVIEW && !reorder && (
          <BackAction
            handleBack={() => handleStep(CARD_STEP.OVERVIEW, orderType)}
          />
        )}
      </S.Content>

      {step === CARD_STEP.OVERVIEW ? (
        <LocationsCardOverview
          store={store}
          handleStep={handleStep}
          setSelectedStore={setSelectedStore}
        />
      ) : (
        <CardContent>
          <OrderFormContext.Provider value={orderFormContext}>
            <LocationsCardSelections
              store={store}
              step={step}
              handleStep={handleStep}
              {...rest}
            />
          </OrderFormContext.Provider>
        </CardContent>
      )}
    </S.LocationCard>
  );
};

export default React.memo(LocationsFinderCard);
