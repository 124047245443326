import { darken, lighten } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';
import CustomInput from 'components/custom-input/custom-input.component';

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)``;

export const CustomToggleButton = styled(ToggleButton)`
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0.3em 0.8em;

  :hover {
    background-color: ${({ theme }) =>
      lighten(theme.palette.primary.main, 0.1)};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &&.Mui-selected {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => darken(theme.palette.primary.main, 0.1)};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    :hover {
      background-color: ${({ theme }) =>
        darken(theme.palette.primary.main, 0.1)};
    }
  }
`;

export const StyledCustomInput = styled(CustomInput)`
  overflow-x: auto;
`;
