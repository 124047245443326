import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ARImage from 'components/ar-image/ar-image.component';
import React from 'react';
import * as S from './location-card-overview.styles';
import { useDisplayInfo } from 'hooks/useDisplayInfo';
import OverviewSubContent from './overview-sub-content/overview-sub-content.component';
import MinMax from './min-max/min-max.component';

const LocationsCardOverview = props => {
  const { store } = props;
  const { mediumImage, longDescription, title } = useDisplayInfo(
    store.displayInfo,
  );

  return (
    <Box position="relative">
      {mediumImage && (
        <ARImage
          locationName={title}
          src={mediumImage}
          testId="LocationsCardOverview.ARImage"
        />
      )}
      <CardContent>
        <S.OpenHours>
          {longDescription && <Typography>{longDescription}</Typography>}
          <MinMax store={store} />
        </S.OpenHours>

        <OverviewSubContent {...props} />
      </CardContent>
    </Box>
  );
};

export default LocationsCardOverview;
