import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useAddressFields = (
  name,
  address,
  { getValues, formState: { errors } },
  { aliasEnabled },
) => {
  const streetNumberName = useMemo(() => `${name}-streetNumber`, [name]);
  const postalCodeName = useMemo(() => `${name}-postalCode`, [name]);
  const countryName = useMemo(() => `${name}-country`, [name]);
  const cityName = useMemo(() => `${name}-city`, [name]);
  const stateName = useMemo(() => `${name}-state`, [name]);
  const aliasName = useMemo(() => `${name}-alias`, [name]);
  const aptSuiteName = useMemo(() => `${name}-aptSuite`, [name]);
  const addressName = useMemo(() => `${name}-address`, [name]);

  const { t } = useTranslation();

  const getAddress = useCallback(() => {
    const rawData = getValues();

    return {
      street1: rawData?.[streetNumberName],
      postalCode: rawData?.[postalCodeName],
      country: rawData?.[countryName],
      city: rawData?.[cityName],
      state: rawData?.[stateName],
      ...rawData?.[addressName],
      addressAlias: rawData?.[aliasName],
      aptSuite: rawData?.[aptSuiteName],
    };
  }, [
    addressName,
    aliasName,
    aptSuiteName,
    cityName,
    countryName,
    getValues,
    postalCodeName,
    stateName,
    streetNumberName,
  ]);

  const missingFieldsData = useMemo(
    () => [
      {
        field: 'street1',
        name: streetNumberName,
        label: t('address.streetNumber.label'),
        errorMessage: t('address.streetNumber.errors.required'),
        inputProps: {
          'data-testid': 'AddressFields.TextField.StreetNumber',
          autoComplete: 'street-address',
        },
      },
      {
        field: 'postalCode',
        name: postalCodeName,
        label: t('address.postalCode.label'),
        errorMessage: t('address.postalCode.errors.required'),
        inputProps: {
          'data-testid': 'AddressFields.TextField.PostalCode',
          autoComplete: 'postal-code',
        },
      },
      {
        field: 'city',
        name: cityName,
        label: t('address.city.label'),
        errorMessage: t('address.city.errors.required'),
        inputProps: {
          'data-testid': 'AddressFields.TextField.City',
          autoComplete: 'address-level2',
        },
      },
      {
        field: 'country',
        name: countryName,
        label: t('address.country.label'),
        errorMessage: t('address.country.errors.required'),
        inputProps: {
          'data-testid': 'AddressFields.TextField.Country',
          autoComplete: 'country-name',
        },
      },
      {
        field: 'state',
        name: stateName,
        label: t('address.state.label'),
        errorMessage: t('address.state.errors.required'),
        inputProps: {
          'data-testid': 'AddressFields.TextField.State',
          autoComplete: 'address-level1',
        },
      },
    ],
    [cityName, countryName, postalCodeName, stateName, streetNumberName, t],
  );

  return {
    addressInputProps: {
      aliasEnabled,
      aliasName,
      missingFieldsData,
      aptSuiteName,
      addressName,
      address,
      errors,
    },
    getAddress,
  };
};
