import { Grid } from '@mui/material';
import MonthSelect from './month-select/month-select.component';
import DaySelect from './day-select/day-select.component';
import CustomInput from 'components/custom-input/custom-input.component';
import { useSelector } from 'react-redux';
import { selectIsDateOfBirthMandatory } from 'redux/config/config.selectors';

const BirthMonthDaySelect = () => {
  const isDateOfBirthRequired = useSelector(selectIsDateOfBirthMandatory);

  return (
    <CustomInput>
      <Grid container>
        <Grid item xs={5}>
          <MonthSelect required={isDateOfBirthRequired} />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <DaySelect required={isDateOfBirthRequired} />
        </Grid>
      </Grid>
    </CustomInput>
  );
};

export default BirthMonthDaySelect;
