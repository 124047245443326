import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './locations-list.styles';
import LocationsFinderCard from '../locations-card/locations-card.component';
import {
  selectRequestedStores,
  selectDisabledStores,
  selectTotalLocationPages,
  selectLatestFetchedPage,
  selectIsLocationsLoading,
  selectMenuBrowsingStores,
} from '../../../redux/locations/locations.selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  fetchLocationsStartAsync,
  fetchCateringLocationsStartAsync,
} from 'redux/locations/locations.actions';
import { Box, CircularProgress } from '@mui/material';
import { useShowAllLocationsOutOfRangeError } from './locations-list.hooks';
import Form from 'components/form/form.component';
import { selectors } from 'redux/app/app.slice';
import { useTranslation } from 'react-i18next';
import SpinnerWrapper from 'components/spinner-wrapper/spinner-wrapper.componennt';

const LocationsFinderList = props => {
  const requestedStores = useSelector(selectRequestedStores);
  const disabledStores = useSelector(selectDisabledStores);
  const menuBrowsingStores = useSelector(selectMenuBrowsingStores);
  const totalLocationPages = useSelector(selectTotalLocationPages);
  const latestFetchedPage = useSelector(selectLatestFetchedPage);
  const locationType = useSelector(selectors.selectLocationsLocationType);
  const isLocationsLoading = useSelector(selectIsLocationsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasMore = totalLocationPages > latestFetchedPage + 1;
  const showAllLocationsOutOfRangeError = useShowAllLocationsOutOfRangeError();

  const fetchMoreLocations = () => {
    if (locationType === 'CATERING') {
      dispatch(fetchCateringLocationsStartAsync(latestFetchedPage + 1));
    } else {
      dispatch(fetchLocationsStartAsync(latestFetchedPage + 1));
    }
  };

  const containerAriaAttributes = useMemo(() => {
    if (isLocationsLoading) {
      return {
        'aria-describedby': 'locations-list-loader-id',
        'aria-busy': true,
      };
    }
    return {};
  }, [isLocationsLoading]);

  return (
    <S.Container
      data-testid="LocationsFinderList.Container"
      {...containerAriaAttributes}
    >
      <SpinnerWrapper
        loading={!hasMore && isLocationsLoading}
        data-testid="LocationsFinderList.Spinner"
      >
        <InfiniteScroll
          dataLength={
            requestedStores.length +
            disabledStores.length +
            menuBrowsingStores.length
          }
          next={fetchMoreLocations}
          scrollableTarget="locationsListContainer"
          hasMore={hasMore}
          loader={
            <Box
              data-testid="LocationsFinderList.InfiniteLoad"
              display="flex"
              justifyContent="center"
              p={2}
            >
              <CircularProgress
                id="locations-list-loader-id"
                aria-label="spinner"
              />
            </Box>
          }
        >
          <Form data-testid="LocationsListForm">
            {showAllLocationsOutOfRangeError && (
              <S.AllLocationsDeliveryError
                variant="body2"
                color="error"
                data-testid="LocationsFinderList.AllLocationsDeliveryError"
              >
                {t('deliveryDistance.allLocationsErrorText')}
              </S.AllLocationsDeliveryError>
            )}
            {requestedStores.map(store => (
              <LocationsFinderCard
                key={store.storeId}
                store={store}
                {...props}
              />
            ))}
            {disabledStores.map(store => (
              <LocationsFinderCard key={store.storeId} store={store} disabled />
            ))}
          </Form>
        </InfiniteScroll>
      </SpinnerWrapper>
    </S.Container>
  );
};

export default LocationsFinderList;
